import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';
import withRouter from '../common/WithRouter';
import Report from '../compontent/Report';
import Loading from '../common/Loading';
import { fetchReportDataByStatId } from '../services/stats.service';


class PublicStatRoute extends Component {

    constructor() {
        super();
        this.state = {
            reportData: {},
            serviceResultId: -1,
            isLoading: true
        };
    }

    componentDidMount() {
        const { id } = this.props.router.params;
        this.loadReportData(id);
    }

    loadReportData(id) {
        fetchReportDataByStatId(id).then((data) => {
            this.setState({ 
                reportData: data.data.data, 
                tableData: data.data.table,
                isLoading: false,
                serviceResultId: data.data.service_result_id
            })
        }).catch((err => {
            console.log(err);
        }))
    }

    render() {
        if (this.state.isLoading) {
            return (<div className='d-flex justify-content-center'>
                <Loading type="rings" />
            </div>)
        } else if (Object.keys(this.state.reportData).length === 0) {
            return <Navigate to="/not-found" />;
        } else {
            return (
                <Report reportData={this.state.reportData} tableData={this.state.tableData} serviceResultId={this.state.serviceResultId} isPrivateReport={false}></Report>
            )
        }
    }
}
export default withRouter(PublicStatRoute);