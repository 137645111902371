import React, { Component } from 'react'
import ScrapesTable from '../utils/ScrapesTable'
import TutorialHelper from '../utils/TutorialHelper';
import { BsFillArrowUpRightSquareFill } from 'react-icons/bs';
import { textFilter, numberFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { dateTimeFormatter, successfulFormatter, statsFormatter, costsFormatter, kwargsFormatter } from '../utils/ScraperTableUtils';

export default class ServiceHistory extends Component {

  actionsFormatter(cell, row, rowIndex, extraData) {
    if (cell && row["is_active"] === false) {
      return (
        <div className='text-center'>
          <div className='d-grid gap-2'>
            <a
              className="btn btn-outline-primary btn-sm m-1"
              target="_blank"
              href={"/report/" + row['scraper_result']['id']}
            >
              <span>Report</span>
            </a>
          </div>
          <div className='d-grid gap-2'>
            <button key={row["id"]} data-id={row["id"]} className="btn btn-outline-danger btn-sm mx-1 my-1" onClick={() => extraData(row['id'])} >
              Delete
            </button>
          </div>
        </div>
      );
    } else {
      return <span></span>
    }
  }

  render() {
    const columns = [
      {
        dataField: "service.name",
        text: "Name",
        sort: true,
        //filter: textFilter()
      },
      {
        dataField: "started_at",
        text: "Start",
        sort: true,
        formatter: dateTimeFormatter,
        //filter: dateFilter()
      },
      {
        dataField: "total",
        text: "Total",
        sort: true,
        formatter: statsFormatter,
        //filter: numberFilter()
      },
      {
        dataField: "total_cost",
        text: "Cost",
        sort: true,
        formatter: costsFormatter,
        //filter: numberFilter()
      },
      {
        dataField: "is_successful",
        text: "Successful",
        formatter: successfulFormatter,
        // filter: selectFilter({
        //   options: [{
        //     value: true,
        //     label: "Successful"
        //   }, {
        //     value: false,
        //     label: "Error"
        //   }]
        // })
      },
      {
        dataField: "result_message",
        text: "Result",
        //filter: textFilter()
      },
      {
        dataField: "args",
        text: "Args",
        formatter: kwargsFormatter
        //filter: textFilter()
      }
    ];


    return (
      <div>
        <ScrapesTable title={"Service results"}
          columns={columns}
          user={this.props.user}
          data={this.props.serviceResults}
          actions={true}
          tutorialId={'serviceHistory'}
          actionFormatter={this.actionsFormatter}
          actionCallback={this.props.handleServiceDelete} />
      </div >
    )
  }
}
