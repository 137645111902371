import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { convertToUtf8 } from "./ScraperTableUtils";
import TutorialHelper from "./TutorialHelper";

export default class ScrapesTable extends Component {
  constructor() {
    super();
    this.state = {
      isDropdownOpen: false,
      columns: [
        {
          dataField: "id",
          text: "ID",
        },
      ],
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  componentDidMount() {
    this.setColumns(this.props.columns);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.columns !== this.props.columns &&
      this.props.columns !== this.state.columns
    ) {
      this.setColumns(this.props.columns);
    }
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  }

  setColumns(columnDefs) {
    const newColumnsDefs = columnDefs.slice(0);
    if (this.props.actions) {
      newColumnsDefs.push({
        dataField: "id",
        text: "Actions",
        formatter: this.props.actionFormatter,
        formatExtraData: this.props.actionCallback,
      });
    }
    this.setState({
      columns: newColumnsDefs,
    });
  }

  render() {
    const { SearchBar } = Search;

    const paginationOptions = {
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "All",
          value: this.props.data.length,
        },
      ],
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      style: { backgroundColor: "#00BFFF" },
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.props.selectScrapeCallback(row.id, true);
        } else {
          this.props.selectScrapeCallback(row.id, false);
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        if (isSelect) {
          this.props.selectScrapeCallback(
            rows.map((o) => o.id),
            true
          );
        } else {
          this.props.selectScrapeCallback(
            rows.map((o) => o.id),
            false
          );
        }
      },
    };

    return (
      <div className="card card-body">
        {this.props.tutorialId !== undefined && (<div className='position-absolute top-0 end-0 tutorial-help-div'>
          <TutorialHelper tutorialId={this.props.tutorialId} user={this.props.user} />
        </div>)}
        <h5>{this.props.title}</h5>
        <ToolkitProvider
          keyField="id"
          data={convertToUtf8(this.props.data)}
          columns={this.state.columns}
          striped
          hover
          condensed
          search
        >
          {(props) => (
            <div>
              <div className="row ">
                <div className="col-md-6 col-sm-6  my-1">
                  <SearchBar {...props.searchProps} srText="" />
                </div>
                <div className="col-md-6 col-sm-6 my-1 d-flex justify-content-sm-end">
                  {(this.props.deleteAllCallback || this.props.deleteAllCallback) && 
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-primary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={this.toggleDropdown}
                      aria-haspopup="true"
                      aria-expanded={
                        this.state.isDropdownOpen ? "true" : "false"
                      }
                    >
                      Options
                    </button>
                    <div
                      className={`dropdown-menu ${
                        this.state.isDropdownOpen ? "show" : ""
                      }`}
                      aria-labelledby="dropdownMenuButton"
                    >
                      {this.props.downloadAllCallback && (
                        <button
                          className="btn btn-info dropdown-item"
                          onClick={this.props.downloadAllCallback}
                        >
                          Export selected
                        </button>
                      )}
                      {this.props.deleteAllCallback && (
                        <button
                          className="btn btn-danger dropdown-item"
                          onClick={this.props.deleteAllCallback}
                        >
                          Delete selected
                        </button>
                      )}
                    </div>
                  </div>
                }
                </div>
              </div>
              <hr />
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                selectRow={
                  this.props.selectScrapeCallback ? selectRow : undefined
                }
                pagination={paginationFactory(paginationOptions)}
                wrapperClasses="table-responsive"
                noDataIndication="It's empty here"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
