import axios from "axios";
import Cookies from "js-cookie";
import { errorMiddleware } from "./middleware";
import { BACKEND_ADDRESS } from "./const";

const axiosInstance = axios.create({
    baseURL: BACKEND_ADDRESS,
    withCredentials: true,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "csrftoken",
});

axiosInstance.interceptors.response.use(
    (response) => response,
    errorMiddleware
)

async function tokenConfig() {
    if (!Cookies.get("csrftoken")) {
        await fetchCSRF();
    }
    return {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    };
}

async function fetchCSRF() {
    let resp = await axios.get(BACKEND_ADDRESS + "user/csrf_cookie", {
        withCredentials: true,
    });
    return;
}

export async function fetchConfigs(scraper) {
    let config = await tokenConfig();
    return axiosInstance.get("settings/config/" + scraper, config);
}

export async function setConfig(scraper, key, value) {
    let config = await tokenConfig();
    return axiosInstance.post("settings/config/" + scraper, {
        'config_key': key,
        'config_value': value
    }, config);
}

// Frontend configs
export async function fetchFrontendPaymentConfig() {
    let config = await tokenConfig();
    return axiosInstance.get("settings/frontend-config/payment_items", config);
}

export async function fetchFrontendLandingConfig() {
    let config = await tokenConfig();
    return axiosInstance.get("settings/frontend-config/landing_config", config);
}





