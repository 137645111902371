import React, { Component } from "react";
import ScraperLogs from "../home/ScraperLogs";
import { is_admin } from "../../services/auth.service";

export default class ActiveService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideLogs: true,
    };
  }

  toggleHide = () => {
    this.setState((prevState) => ({
      hideLogs: !prevState.hideLogs,
    }));
  };

  render() {
    return (
      <div>
        <div
          id={"active-" + this.props.id}
          className="my-2 d-flex row justify-content-center"
        >
          <div className="col-11">
            <div className="row">
              <div className="col text-left d-flex align-items-end mb-1">
                <div className="">
                <strong>
                  {this.props.serviceResult
                    ? "Service: " +
                      this.props.serviceResult.service.name +
                      ": " +
                      this.props.serviceResult.id
                    : "Scrape: " +
                      this.props.scrapeResult.scraper.name +
                      ": " +
                      this.props.scrapeResult.id}
                </strong>
                </div>
              </div>

              <div className="col text-right">
                <div className="d-flex justify-content-end">
                {is_admin(this.props.user) && (
                  <button
                    className="btn btn-outline-danger btn-sm m-2"
                    onClick={() => {
                      this.props.serviceResult
                        ? this.props.handleStop(this.props.serviceResult.id)
                        : this.props.handleStop(this.props.scrapeResult.id);
                    }}
                  >
                    <span>
                      {this.props.serviceResult
                        ? "Stop service"
                        : "Stop Scrape"}
                    </span>
                  </button>
                )}
                <button
                  className="btn btn-outline-info btn-sm m-2"
                  onClick={this.toggleHide}
                >
                  <span>{this.state.hideLogs ? "Show logs" : "Hide logs"}</span>
                </button>
                </div>
              </div>
            </div>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: `${this.props.progress}%` }}
              >
                {this.props.progress}%
              </div>
            </div>
          </div>
        </div>
        <div
          id={"active-logs-" + this.props.id}
          className="my-2 d-flex row justify-content-center"
        >
          <div className="col-11 my-3 mx-5">
            <ScraperLogs hidden={this.state.hideLogs} logs={this.props.logs} />
          </div>
        </div>
      </div>
    );
  }
}
