import React, { Component } from "react";
import {getImageCellValue} from "../utils/ScraperTableUtils";

class ReportHeader extends Component {
  state = {
    title: undefined,
    subTitle: undefined,
    imageUrl: undefined,
    imgField: undefined,
    link: undefined,
    loading: true,
  };

  componentDidMount() {
    this.loadHeader(this.props.header, this.props.data, this.props.resultIndex);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.resultIndex != prevProps.resultIndex ||
      this.props.data != prevProps.data
    ) {
      this.loadHeader(
        this.props.header,
        this.props.data,
        this.props.resultIndex
      );
    }
  }

  loadHeader(header, data, resultIndex) {
    if (
      header != undefined &&
      data != undefined &&
      resultIndex != undefined &&
      data.length > resultIndex
    ) {
      const headerData = data[resultIndex];
      const title = headerData[header.title_field] || "Unknown";
      const subTitle = headerData[header.subtitle_field] || "";
      const imgField = getImageCellValue(headerData[header.image_field]) || "";
      const link = header.external_link + headerData[header.link_field] || "";

      this.setState({
        title: title,
        subTitle: subTitle,
        imgField: imgField,
        link: link,
        loading: false,
      });
    }
  }

  render() {
    if (this.state.loading) {
      <div>Loading...</div>;
    }
    return (
      <div className="m-2">
        <div className="d-flex justify-content-center">
          <a target="_blank" href={this.state.link}>
            <img className="img-fluid img-thumbnail" src={this.state.imgField} alt="avatar"/>
          </a>
        </div>
        <div className="d-flex justify-content-center">
          <a target="_blank" href={this.state.link}><h3>{this.state.title}</h3></a>
        </div>
        <div className="d-flex justify-content-center">
          {this.state.subTitle}
        </div>
      </div>
    );
  }
}

export default ReportHeader;
