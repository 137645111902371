import React, { Component } from "react";
import { FallingLines, Rings, Bars, Audio, ThreeCircles, LineWave, MutatingDots, Circles } from "react-loader-spinner";

class Loading extends Component {
  render() {
    switch (this.props.type) {
      case "lines":
        return (
          <div>
            <FallingLines
              color="#4fa94d"
              width="100"
              visible={true}
              wrapperClass="flex justify-content-center"
              ariaLabel="falling-lines-loading"
            />
          </div>
        );
      case "rings":
        return (
          <div>
            <Rings
              height="180"
              width="180"
              color="#00BFFF"
              radius="6"
              wrapperClass="flex justify-content-center"
              visible={true}
              ariaLabel="rings-loading"
            />
          </div>
        );
      case "bars":
        return (
          <div>
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperClass="flex justify-content-center"
              visible={true}
            />
          </div>
        );
      case "audio":
        return (
          <div>
            <Audio
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="audio-loading"
              wrapperClass="flex justify-content-center"
              visible={true}
            />
          </div>
        );
      case "circles":
        return (
          <div>
            <Circles
              height="40"
              width="40"
              color="#00BFFF"
              ariaLabel="circles-loading"
              wrapperClass="flex justify-content-center"
              visible={true}
            />
          </div>
        );
      case "circles-3":
        return (
          <div>
            <ThreeCircles
              height="100"
              width="100"
              color="#4fa94d"
              wrapperClass="flex justify-content-center"
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        );
      case "line-wave":
        return (
          <div>
            <LineWave
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="line-wave"
              wrapperClass="flex justify-content-center"
              visible={true}
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
            />
          </div>
        );
      case "dots":
        return (
          <div>
            <MutatingDots
              height="100"
              width="100"
              color="#00BFFF"
              secondaryColor="#00BFFF"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              wrapperClass="flex justify-content-center"
              visible={true}
            />
          </div>
        );
      default:
        return (
          <div>
            <FallingLines
              color="#4fa94d"
              width="100"
              visible={true}
              wrapperClass="flex justify-content-center"
              ariaLabel="falling-lines-loading"
            />
          </div>
        );
    }
  }
}

export default Loading;
