import axios from "axios";
import Cookies from "js-cookie";
import { errorMiddleware } from "./middleware";
import { BACKEND_ADDRESS } from "./const";

const axiosInstance = axios.create({
  baseURL: BACKEND_ADDRESS,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  errorMiddleware
);

async function tokenConfig() {
  if (!Cookies.get("csrftoken")) {
    await fetchCSRF();
  }
  return {
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
}

async function fetchCSRF() {
  let resp = await axios.get(BACKEND_ADDRESS + "user/csrf_cookie", {
    withCredentials: true,
  });
  return;
}

export async function feedback(email, name, subject, message, captchaValue) {
  let config = await tokenConfig();

  const data = { email, name, subject, message, captchaValue};
  return axiosInstance.post(BACKEND_ADDRESS + "user/feedback/", data, config);
}

export async function openCheckoutSession(paymentId){
    let config = await tokenConfig();
    const data = { "product_id": paymentId };
    return axiosInstance.post(BACKEND_ADDRESS + "user/checkout-session/", data, config);
}

export async function updateProfile(field, value) {
  let config = await tokenConfig();

  const data = { field, value };
  return axiosInstance.post(BACKEND_ADDRESS + "user/update-profile/", data, config);
}
