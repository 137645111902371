import React, { Component } from 'react'

export default class ProfileDelete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.modalRef = React.createRef();
    }

    handleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    render() {
        return (
            <div className='card p-3'>
                <div className='m-3'>
                    <p>Delete all your personal and scraped data from our servers</p>
                    <button className="btn btn-danger" onClick={this.handleModal}>
                        Delete Profile
                    </button>

                    {this.state.showModal && (
                        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                            <div className="modal-dialog" role="document" ref={this.modalRef}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Delete Profile</h5>
                                        <button type="button" className="close" onClick={this.handleModal}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        Please contact our support at info@datafox.app to delete your complete profile with all stored data.
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
