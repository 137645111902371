import axios from "axios";
import Cookies from "js-cookie";
import { errorMiddleware } from "./middleware";
import { BACKEND_ADDRESS } from "./const";

const axiosInstance = axios.create({
    baseURL: BACKEND_ADDRESS,
    withCredentials: true,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "csrftoken",
});
axiosInstance.interceptors.response.use(
    (response) => response,
    errorMiddleware
)

async function tokenConfig() {
    if (!Cookies.get("csrftoken")) {
        await fetchCSRF();
    }
    return {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    };
}

async function fetchCSRF() {
    let resp = await axios.get(BACKEND_ADDRESS + "user/csrf_cookie", {
        withCredentials: true,
    });
    return;
}

export async function fetchServices(scraper) {
    let config = await tokenConfig();
    return axiosInstance.get("service/list/" + scraper, config);
}

export async function fetchServiceParams(service) {
    let config = await tokenConfig();
    return axiosInstance.get("service/params/" + service, config);
}

export async function startNewService(serviceId, scrape_ids, params) {
    let config = await tokenConfig();
    return axiosInstance.post("service/run/" + serviceId, { 'scrape_ids': scrape_ids, 'args': params }, config);
}

export async function deleteService(serviceId) {
    let config = await tokenConfig();
    return axiosInstance.post("service/stop/" + serviceId, config);
}

export async function fetchActiveServices(name) {
    let config = await tokenConfig();
    return axiosInstance.get("service/active/" + name, config);
}

export async function fetchServiceResults(scraper) {
    let config = await tokenConfig();
    return axiosInstance.get("service/results/" + scraper, config);
}

export async function fetchPreviewServicePricing(serviceId, scrape_ids, params) {
    let config = await tokenConfig();
    return axiosInstance.post("service/price-preview/" + serviceId, { 'scrape_ids': scrape_ids, 'args': params }, config);
}

export async function fetchValidateServiceRun(serviceId, scrape_ids, params) {
    let config = await tokenConfig();
    return axiosInstance.post("service/validate-run/" + serviceId, { 'scrape_ids': scrape_ids, 'args': params }, config);
}

export async function deleteServiceResult(serviceResultId) {
    let config = await tokenConfig();
    return axiosInstance.post("service/delete/" + serviceResultId, { }, config);
}

export async function fetchFullServiceResult(serviceResultId) {
    let config = await tokenConfig();
    return axiosInstance.get("service/result-full/" + serviceResultId, config);
}