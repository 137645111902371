import React, { Component } from 'react'
import { toast } from "react-toastify";
import { feedback } from '../../services/user.service';
import ReCAPTCHA from 'react-google-recaptcha'
import { CAPTCHA_FRONTEND_KEY } from '../../services/const';

export default class Contact extends Component {

    constructor(props) {
        super(props);
        this.recaptcha = React.createRef();
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
        };

        console.log(CAPTCHA_FRONTEND_KEY)
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };


    handleSubmit = (e) => {
        e.preventDefault();
        
        const captchaValue = this.recaptcha.current.getValue();
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
          }

        const { name, email, subject, message } = this.state;
        
        feedback(email, name, subject, message, captchaValue).then((response) => {
            toast.success("Thank you, we will get back to you shortly 🙏")
        }).catch((err) => {
            console.error("Failed to send feedback", err);
        })

        this.setState({ name: '', email: '', subject: '', message: '' });
    };


    render() {
        const { name, email, subject, message } = this.state;

        return (
            <div data-aos="fade-up" data-aos-delay="500" className='container mb-5'>
                <h2 className='d-flex justify-content-center mb-5 display-3'>Do you have any questions?</h2>
                <div className='row gy-4 d-flex justify-content-between'>
                    <div className='col-lg-5'>
                        <div className='row gy-4'>
                            <div className='col-md-6'>
                                <div className='info-box rounded'>
                                    <p className='emoji-div'>📧</p>
                                    <h3>Email us</h3>
                                    <p>info@datafox.app</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <form onSubmit={this.handleSubmit} className="w-100">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Your name
                                </label>
                                <input
                                    className="form-control"
                                    id="name"
                                    type='text'
                                    value={name}
                                    onChange={this.handleChange}
                                    required
                                ></input>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Your email
                                </label>
                                <input
                                    className="form-control"
                                    id="email"
                                    type='email'
                                    value={email}
                                    onChange={this.handleChange}
                                    required
                                ></input>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Subject
                                </label>
                                <input
                                    className="form-control"
                                    id="subject"
                                    type='text'
                                    value={subject}
                                    onChange={this.handleChange}
                                    required
                                ></input>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="message" className="form-label">
                                    Message
                                </label>
                                <textarea
                                    className="form-control"
                                    id="message"
                                    rows="5"
                                    cols="50"
                                    value={message}
                                    onChange={this.handleChange}
                                    required
                                ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary mb-5">
                                Send message
                            </button>
                            <ReCAPTCHA ref={this.recaptcha} sitekey={CAPTCHA_FRONTEND_KEY} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
