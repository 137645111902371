import React, { Component } from 'react'
import { toast } from "react-toastify";
import Loading from '../../common/Loading';
import { fetchPreviewServicePricing } from '../../services/services.service';

export default class PriceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            priceData: {},
            acceptedTerms: false,
            sendCompleteEmail: false
        };
    }

    componentDidMount() {
        this.loadPrices(this.props.serviceId, this.props.scrape_ids, this.props.params);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.params !== this.props.params || prevProps.serviceId !== this.props.serviceId || prevProps.scrape_ids !== this.props.scrape_ids) {
            this.loadPrices(this.props.serviceId, this.props.scrape_ids, this.props.params);
        }
    }

    loadPrices(serviceId, scrape_ids, params) {
        fetchPreviewServicePricing(serviceId, scrape_ids, params).then((data) => {
            this.setState({ priceData: data.data.data, isLoading: false });
        })
            .catch((error) => {
                toast.error('Error fetching service price data:', error);
                this.setState({ isLoading: false });
                console.error('Error fetching service price data:', error);
            });
    }

    handleAcceptCheckbox = () => {
        this.setState((prevState) => ({
            acceptedTerms: !prevState.acceptedTerms,
        }));
    };

    handleSendCompleteEmail = () => {
        this.setState((prevState) => ({
            sendCompleteEmail: !prevState.sendCompleteEmail,
        }));
    };


    render() {
        if (this.state.isLoading) {
            return (<div className="modal-footer d-flex justify-content-center">
                <Loading type="dots"></Loading>
            </div>);
        }
        else {
            return (
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">Cost breakdown by scrape/service</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.props.handleClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.priceData.details_by_scrape && Object.entries(this.state.priceData.details_by_scrape).map(([id, value]) => (
                            <div className="my-3" key={id}>
                                <strong key={"title-" + id}>Scrape: {id}</strong>
                                <div key={"details-" + id}>
                                    {value.details.map((detail, index) => (
                                        <div key={index}><span>
                                            <small>{detail}</small>
                                        </span><br /></div>
                                    ))}
                                    <p key={"sub-total-" + id}>Cost: {value.total} token</p>
                                </div>
                            </div>
                        ))}
                        <hr />
                        <div className='d-flex justify-content-end'><strong>TOTAL: {this.state.priceData.total_cost} token</strong></div>
                    </div>
                    <div className='modal-body'>
                        <div className=''>
                            {this.props.warnings.map((warning) => <div className="alert alert-warning">{warning}</div>)}
                            <div className="alert alert-info">{"Only real amount will deducted from your balance which can be <= than total"}</div>
                        </div>
                        <div>
                        <label>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={this.state.sendCompleteEmail}
                                    onChange={this.handleSendCompleteEmail}
                                />
                                {' '}
                                <small>Send email on complete</small>
                            </label>    
                        </div>
                        <hr/>
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={this.state.acceptedTerms}
                                    onChange={this.handleAcceptCheckbox}
                                />
                                {' '}
                                <small>I understand</small>
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-success"
                            data-dismiss="modal"
                            disabled={!this.state.acceptedTerms}
                            onClick={() => this.props.handleSubmit(this.state.acceptedTerms, this.state.sendCompleteEmail)}
                        >
                            Confirm
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={this.props.handleClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )
        }
    }
}
