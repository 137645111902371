import React, { Component } from "react";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";
import Switch from "react-switch";
import { changePassword, login } from "../../services/auth.service";
import { updateProfile } from "../../services/user.service";

export default class PasswordChange extends Component {
  constructor() {
    super();
    this.state = {
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
      isLoading: false,
      userProfile: {
        allowed_promotions: false
      },
    };
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentDidMount() {
    this.setState({
      userProfile: this.props.user.user_profile,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        userProfile: this.props.user.user_profile,
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleTurnMarketing = (event) => {
    updateProfile("allowed_promotions", event)
      .then((res) => {
        this.setState({
          userProfile: res.data.data.user_profile,
        });
      })
      .catch((err) => {
        console.error("Failed to set user marketing", err);
      });
  };

  handleTurnTutorial = (event) => {
    updateProfile("tutorial_enabled", event)
      .then((res) => {
        this.setState({
          userProfile: res.data.data.user_profile,
        });
      })
      .catch((err) => {
        console.error("Failed to set user tutorial", err);
      });
  };


  handleChangePassword() {
    if (
      !this.state.oldPassword ||
      !this.state.newPassword1 ||
      !this.state.newPassword2
    ) {
      toast.error("Password is missing");
      return;
    }
    this.setState({ isLoading: true });
    changePassword(
      this.state.oldPassword,
      this.state.newPassword1,
      this.state.newPassword2
    )
      .then((res) => {
        login(this.props.user.username, this.state.newPassword1).then((res) => {
          toast.success("Password changed");
          this.setState({
            oldPassword: "",
            newPassword1: "",
            newPassword2: "",
            isLoading: false,
          });
        });
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          Object.entries(error.response.data.errors).map(([index, error]) =>
            Object.entries(error).map(([message, index]) => toast.error(index))
          );
        } else {
          toast.error("Unable to change password: unknown error");
          console.error("Unable to change password: unknown error", error);
        }
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { oldPassword, newPassword1, newPassword2 } = this.state;

    if (this.state.isLoading) {
      return (
        <div className="d-flex justify-content-center">
          <Loading type="dots"></Loading>
        </div>
      );
    } else {
      return (
        <div className="card p-3">
          <div className="row mx-5">
            <div className="col-lg-6 col-12 my-3">
              <h4>Change Password</h4>
              <input
                type="password"
                placeholder="Old Password"
                name="oldPassword"
                value={oldPassword}
                className="form-control my-1"
                onChange={this.handleChange}
              />
              <input
                type="password"
                placeholder="New Password"
                name="newPassword1"
                className="form-control my-1"
                value={newPassword1}
                onChange={this.handleChange}
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                name="newPassword2"
                className="form-control my-1"
                value={newPassword2}
                onChange={this.handleChange}
              />

              <button
                className="btn btn-warning"
                onClick={this.handleChangePassword}
              >
                Change Password
              </button>
            </div>
            <div className="col-lg-6 col-12 my-3">
              <h4>General settings</h4>
              <div>
                <div className="row">
                  <div className="col-10">
                    <div>Accept marketing emails</div>
                    <small
                      className="text-muted"
                      style={{ "fontSize": "0.6rem" }}
                    >
                      We use your email ONLY if something is very neccessary for you
                    </small>
                  </div>
                  <div className="col-2">
                    <div className="mb-3 d-flex justify-content-center pr-5">
                      <label
                        className="form-check-label mx-2"
                        htmlFor="marketingSwitch"
                      >
                        No
                      </label>
                      <Switch
                        className="mx-3"
                        id="marketingSwitch"
                        onColor="#86d3ff"
                        offColor="#ff7575"
                        onHandleColor="#2693e6"
                        offHandleColor="#e81717"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        checked={this.state.userProfile.allowed_promotions}
                        aria-label="swtich"
                        onChange={this.handleTurnMarketing}
                      />
                      <label
                        className="form-check-label mx-2"
                        htmlFor="marketingSwitch"
                      >
                        Allow
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                  <div className="col-10">
                    <div>Enable tutorial</div>
                    <small
                      className="text-muted"
                      style={{ "fontSize": "0.6rem" }}
                    >
                      Enable helping tutorials which describe functions
                    </small>
                  </div>
                  <div className="col-2">
                    <div className="mb-3 d-flex justify-content-center pr-5">
                      <label
                        className="form-check-label mx-2"
                        htmlFor="tutorialSwitch"
                      >
                        No
                      </label>
                      <Switch
                        className="mx-3"
                        id="tutorialSwitch"
                        onColor="#86d3ff"
                        offColor="#ff7575"
                        onHandleColor="#2693e6"
                        offHandleColor="#e81717"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        checked={this.state.userProfile.tutorial_enabled}
                        aria-label="swtich"
                        onChange={this.handleTurnTutorial}
                      />
                      <label
                        className="form-check-label mx-2"
                        htmlFor="tutorialSwitch"
                      >
                        Allow
                      </label>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
