import React, { Component } from "react";
import Loading from "../../common/Loading";


class ActiveScraper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scraperArgsOpen: false,
    };
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      collapseOpen: !prevState.collapseOpen,
    }));
  };

  progressBar() {
    if (this.props.activeScraper) {
      return (
        <div>
          <h4>Active scrape: {this.props.activeScraper.name}</h4>
          <p>Started at: {this.props.activeScraper.started_at}</p>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{ width: `${this.props.progress}%` }}
            >
              {this.props.progress}%
            </div>
          </div>
          <div id="detailsHolder">
            <button
              className="btn btn-link"
              onClick={this.toggleCollapse}
              aria-expanded={this.state.collapseOpen ? "true" : "false"}
              aria-controls="argsDetails"
            >
              Scraper args
            </button>
            <div
              id="argsDetails"
              className={`collapse ${this.state.collapseOpen ? "show" : ""}`}
              aria-labelledby="argsDetails"
              data-parent="#detailsHolder"
            >
              <p>{this.props.activeScraper.args}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h4>No scrape is running</h4>
        </div>
      );
    }
  }

  scrapeControllerContainer() {
    if (this.props.activeScraper) {
      return (
        <div className="d-flex flex-column justify-content-center">
          <button
            className="btn btn-danger btn-lg my-2"
            onClick={this.props.handleStopScrapeCallback} >
            <h5>Stop scrape</h5>
          </button>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column justify-content-center">
          <button
            className="btn btn-warning btn-lg my-2"
            onClick={this.props.handleNewScrapeModalOpenCallback} >
            <h5>Run new scrape</h5>
          </button>
          <button
            className="btn btn-warning btn-lg my-2"
            onClick={this.props.handleScheduleScrapeModalOpenCallback} >
            <h5>Schedule new scrape</h5>
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="card card-body">
        <div className="row">
          <div className="col-lg-8">
            {this.props.isLoading ? (
              <div className="card card-body">
                <Loading type="rings"></Loading>
              </div>
            ) : (
              this.progressBar()
            )}
          </div>
          <div className="col-lg-4">
            {this.props.isLoading ? (
              <div className="card card-body">
                <Loading type="rings"></Loading>
              </div>
            ) : (
              this.scrapeControllerContainer()
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ActiveScraper;
