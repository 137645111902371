import React, { Component } from 'react'
import { Gif } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'
import Loading from '../../common/Loading'

export default class NotFound extends Component {
  
  state = {
    gif: null
  }

  componentDidMount(){
    this.setNotFoundGif();
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

  setNotFoundGif(){
    const gf = new GiphyFetch('acUuNytwKXmLmo8juAh8L6AmU23GQGL7')
    gf.search('Error', {limit: 1, offset: this.getRandomInt(0, 25)}).then(fetchGif => {
      this.setState({
        gif: fetchGif.data[0]
      })
    })
  }

  getNotFoundGif(){
    if(this.state.gif !== null){
      return (<Gif gif={this.state.gif} width={500} height={500}/>);
    }
    else{
      return (<Loading type="dots"></Loading>);
    }
  }
  
  render() {
    
    return (
      <div className='m-5 d-flex flex-column align-items-center justify-content-center'>
        <h1 className='content-holder mb-3'>
          Page not found
        </h1>
        <iframe src="https://giphy.com/embed/S3thLnRWEpRwq6iDIO" width="480" height="432" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/metadigital-meta-web-design-digital-S3thLnRWEpRwq6iDIO"></a></p>
      </div>
    )
  }
}
