import React, { Component } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
    BsFillCheckCircleFill,
    BsFillPlayCircleFill,
    BsFillArrowUpRightSquareFill
} from "react-icons/bs";
import filterFactory, { textFilter, numberFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import TutorialHelper from '../utils/TutorialHelper';

export default class StatReports extends Component {

    dateTimeFormatter(cell, row) {
        const datetime = new Date(cell);
        return <span>{datetime.toLocaleString()}</span>;
    }

    isPublishedFormatter(cell, row) {
        if (cell) {
            return <button className="btn btn-info btn-sm" disabled><BsFillPlayCircleFill /></button>;
        } else {
            return <button className="btn btn-success btn-sm" disabled><BsFillCheckCircleFill /></button>;
        }
    }

    actionsFormatter(cell, row, rowIndex, extraData) {
        if (cell) {
            return (
                <span>
                    <a
                        className="btn btn-primary btn-sm mx-1"
                        target="_blank"
                        href={"/public/report/" + row['id']}
                    >
                        <BsFillArrowUpRightSquareFill />
                    </a>
                    <button key={row["id"]} data-id={row["id"]} className="btn btn-danger btn-sm mx-1 my-1" onClick={() => extraData(row['id'])} >
                        Delete
                    </button>
                </span>
            );
        } else {
            return <span></span>
        }
    }

    render() {

        const { SearchBar } = Search;

        const columns = [
            {
                dataField: "id",
                text: "ID",
                sort: true,
                filter: textFilter()
            },
            {
                dataField: "service_result.service.name",
                text: "Name",
                sort: true,
                filter: textFilter()
            },
            {
                dataField: "service_result.args",
                text: "Args",
                filter: textFilter()
            },
            {
                dataField: "created_at",
                text: "Published at",
                sort: true,
                formatter: this.dateTimeFormatter,
                filter: dateFilter()
            },
            {
                dataField: "published",
                text: "Published",
                formatter: this.isPublishedFormatter
            },
            {
                dataField: "id",
                text: "Actions",
                formatter: this.actionsFormatter,
                formatExtraData: this.props.deleteStatReportCallback
            },
        ];

        const paginationOptions = {
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: 'All', value: this.props.reports.length
            }]
        }

        return (
            <div className="card card-body">
                <div className='position-absolute top-0 end-0 tutorial-help-div'>
                    <TutorialHelper tutorialId="publishedReport" user={this.props.user} />
                </div>
                <h5>Published reports</h5>
                <ToolkitProvider
                    keyField="id"
                    data={this.props.reports}
                    columns={columns}
                    striped
                    hover
                    condensed
                    search
                >
                    {(props) => (
                        <div>
                            <div className="row ">
                                <div className="col-md-6 col-sm-6  my-1">
                                    <SearchBar {...props.searchProps} srText="" />
                                </div>
                            </div>
                            <hr />
                            <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory(paginationOptions)}
                                wrapperClasses="table-responsive"
                                noDataIndication="It's empty here"
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        )
    }
}
