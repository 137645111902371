import React, { Component } from 'react'
import {
  BsFillCheckCircleFill,
  BsFillDashCircleFill,
  BsFillPlayCircleFill,
  BsFillCloudArrowUpFill,
  BsFillXCircleFill
} from "react-icons/bs";
import ScrapesTable from '../utils/ScrapesTable';
import filterFactory, { textFilter, numberFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { dateTimeFormatter, successfulFormatter, statsFormatter } from '../utils/ScraperTableUtils';


export default class ScraperResults extends Component {

  createStatReport(cell, row, rowIndex, extraData) {
    if (cell) {
      return (
        <span>
          <button key={row["id"]} data-id={row["id"]} className="btn btn-info btn-sm mx-1 my-1" onClick={() => extraData(row['id'])} >
            <BsFillCloudArrowUpFill />
          </button>
        </span>
      );
    } else {
      return <span></span>
    }
  }

  render() {

    const columns = [
      {
        dataField: "id",
        text: "ID",
        sort: true,
        //filter: textFilter()
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
        //filter: textFilter()
      },
      {
        dataField: "started_at",
        text: "Start",
        sort: true,
        formatter: dateTimeFormatter,
        //filter: dateFilter()
      },
      {
        dataField: "total",
        text: "Total",
        sort: true,
        formatter: statsFormatter,
        //filter: numberFilter()
      },
      {
        dataField: "is_successful",
        text: "Successful",
        formatter: successfulFormatter,
        /*filter: selectFilter({
          options: [{
            value: true,
            label: "Successful"
          }, {
            value: false,
            label: "Error"
          }]
        })*/
      },
      {
        dataField: "result_message",
        text: "Result",
       //filter: textFilter()
      },
      {
        dataField: "args",
        text: "Args",
        //filter: textFilter()
      }
    ];


    return (
      <ScrapesTable title={"Your scrape data"}
        columns={columns}
        data={this.props.history}
        actions={true}
        actionFormatter={this.createStatReport}
        actionCallback={this.props.createStatReportCallback} />
    )
  }
}
