import axios from "axios";
import Cookies from "js-cookie";
import { errorMiddleware } from "./middleware";
import {
  BACKEND_ADDRESS,
  ADMIN_PERMISSION,
  PRO_PERMISSION,
  CLIENT_PERMISSION,
  GUEST_PERMISSION,
} from "./const";

import { GoogleAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { getAdditionalUserInfo } from "firebase/auth";
import { signInWithPopup } from "firebase/auth";

const axiosInstance = axios.create({
  baseURL: BACKEND_ADDRESS,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  errorMiddleware
);

const provider = new GoogleAuthProvider();

async function tokenConfig() {
  if (!Cookies.get("csrftoken")) {
    await fetchCSRF();
  }
  return {
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
}

async function fetchCSRF() {
  let resp = await axios.get(BACKEND_ADDRESS + "user/csrf_cookie", {
    withCredentials: true,
  });
  return;
}

export async function fetchIsAuthenticated() {
  let config = await tokenConfig();
  let tempInstance = axios.create({
    baseURL: BACKEND_ADDRESS,
    withCredentials: true,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "csrftoken",
  });
  try {
    let resp = await tempInstance.get("user/authenticated", config);
    if (resp) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export async function fetchUserData() {
  let config = await tokenConfig();
  return axiosInstance.get(BACKEND_ADDRESS + "user/user", config);
}

export async function login(username, password) {
  let config = await tokenConfig();
  const body = JSON.stringify({ username, password });
  return axiosInstance.post(BACKEND_ADDRESS + "user/login", body, config);
}

export async function logout() {
  let config = await tokenConfig();
  localStorage.removeItem("token");
  return axiosInstance.post(BACKEND_ADDRESS + "user/logout", {}, config);
}

export async function changePassword(
  old_password,
  new_password1,
  new_password2
) {
  let config = await tokenConfig();

  const formData = new FormData();
  formData.append("old_password", old_password);
  formData.append("new_password1", new_password1);
  formData.append("new_password2", new_password2);

  config["headers"]["Content-Type"] = "multipart/form-data";
  return axiosInstance.post(
    BACKEND_ADDRESS + "user/change-password/",
    formData,
    config
  );
}

export async function register(username, email, password) {
  let config = await tokenConfig();

  const formData = new FormData();
  formData.append("username", username);
  formData.append("email", email);
  formData.append("password", password);

  config["headers"]["Content-Type"] = "multipart/form-data";
  return axiosInstance.post(
    BACKEND_ADDRESS + "user/register/",
    formData,
    config
  );
}

export async function activate(token) {
  let config = await tokenConfig();

  const formData = new FormData();
  formData.append("token", token);
  config["headers"]["Content-Type"] = "multipart/form-data";
  return axiosInstance.post(
    BACKEND_ADDRESS + "user/activate/",
    formData,
    config
  );
}

export async function resend(email) {
  let config = await tokenConfig();

  const formData = new FormData();
  formData.append("email", email);
  config["headers"]["Content-Type"] = "multipart/form-data";
  return axiosInstance.post(BACKEND_ADDRESS + "user/resend/", formData, config);
}

function has_permission(user, permission) {
  if (!user || !user.user_profile) {
    return false;
  }
  return permission.includes(user.user_profile.role);
}

export function is_admin(user) {
  return has_permission(user, ADMIN_PERMISSION);
}

export function is_pro(user) {
  return has_permission(user, PRO_PERMISSION);
}

export function is_client(user) {
  return has_permission(user, CLIENT_PERMISSION);
}

export function is_guest(user) {
  return has_permission(user, GUEST_PERMISSION);
}

export async function authenticateWithGoogle() {
  const auth = getAuth();
  const config = await tokenConfig();

  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const user = result.user;
    const userExtra = getAdditionalUserInfo(result);

    const formData = new FormData();

    formData.append("uid", user.uid);
    formData.append("email", user.email);
    formData.append("family_name", userExtra.profile.family_name);
    formData.append("given_name", userExtra.profile.given_name);
    formData.append("is_new_user", userExtra.isNewUser);
    formData.append("access_token", user.accessToken);

    config["headers"]["Content-Type"] = "multipart/form-data";
    return axiosInstance.post(
      BACKEND_ADDRESS + "user/google-auth/",
      formData,
      config
    );
  } catch (error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);

    throw new Error(errorMessage);
  }
}
