import React, { Component } from "react";

class ScraperLogs extends Component {
  constructor(props) {
    super(props);
    this.logContainerRef = React.createRef();
  }

  componentDidUpdate() {
    // Scroll to the bottom of the container when new logs are added
    if (this.logContainerRef.current) {
      this.logContainerRef.current.scrollTop =
        this.logContainerRef.current.scrollHeight;
    }
  }

  render() {
    if(this.props.hidden){
      return (<div></div>);
    }
    return (
      <div>
        <div className="card card-body">
          <div>
            <h5>Logs</h5>
          </div>
          {this.props.logs && (
            <div
              ref={this.logContainerRef}
              style={{ height: "300px", overflow: "auto" }}
            >
              {this.props.logs.map((log) => (
                <p>{`${log}`}</p>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ScraperLogs;
