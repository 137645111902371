import React, { Component } from "react";
import Loading from "../../common/Loading";
import { renderFormElement } from "../utils/GenerativeForm";


class NewScrape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: '',
      formValues: {},
    };
  }

  componentDidMount() {
    const groups = [...new Set(this.props.params.map(item => item.group))];
    if(groups && this.state.selectedTab === ''){
      this.handleTabClick(groups[0]);
    }
    this.setDefaultValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params !== this.props.params) {
      const groups = [...new Set(this.props.params.map(item => item.group))];
      if(groups && this.state.selectedTab === ''){
        this.handleTabClick(groups[0]);
      }
      this.setDefaultValues();
    }
  }

  setDefaultValues() {
    const newFormValues = {};

    this.props.params.filter((item) => item.group === this.state.selectedTab).forEach((obj) => {
      newFormValues[obj.name] = obj.default;
    });
    this.setState({
      formValues: newFormValues,
    });
  }

  handleInputChange = (e) => {
    const { id, type, value, checked  } = e.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [id]: type == "checkbox"? checked : value,
      },
    }));
  };

  handleSliderChange = (e) => {
    const { id, value } = e.target;
    document.getElementById(`${id}-value`).innerText = e.target.value;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [id]: value,
      },
    }));
    
  };

  handleTabClick(newGroup){
    this.setState({
      selectedTab: newGroup
    });
    this.setDefaultValues();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.startNewScrapeCallback({
      ...this.state.formValues,
      ["group"]: this.state.selectedTab
    });
  };

  render() {
    return (
      <div>
        <div className="modal-header">
          <h5 className="modal-title">Start a new scrape</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.handleNewScrapeModalCloseCallback}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <ul className="nav nav-tabs">
          {Array.from(new Set(this.props.params.map(item => item.group))).map((group) => {
              return (
                <a className={
                "nav-link " + (this.state.selectedTab === group ? "active" : "")
              }
              onClick={() => this.handleTabClick(group)}>
              {group}</a>)
          })}
        </ul>
        
        <form>
          <div className="form-group my-3">
          <input
            type="text"
            className="form-control"
            id="group"
            name="group"
            disabled
            hidden
            value={this.state.selectedTab}
          />
          </div>
            {this.props.params.filter((item) => item.group === this.state.selectedTab).map((field) => renderFormElement(field, this.handleInputChange, this.handleSliderChange))}
        </form>
          
        </div>
        {this.props.isLoading ? (
          <div className="modal-footer d-flex justify-content-center">
            <Loading type="dots"></Loading>
          </div>
        ) : (
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={this.handleSubmit}
            >
              Start
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.props.handleNewScrapeModalCloseCallback}
            >
              Close
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default NewScrape;
