import React, { Component } from "react";

class UserInfo extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className="card p-3">
        <h4>User info</h4>
        <form>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              className="form-control"
              value={user.email}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Registration Date:</label>
            <input
              type="text"
              className="form-control"
              value={user.date_joined}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Balance:</label>
            <input
              type="text"
              className="form-control"
              value={user.user_profile.balance}
              readOnly
            />
          </div>
        </form>
      </div>
    );
  }
}

export default UserInfo;
