import React, { Component } from "react";
import ScrapesTable from "./utils/ScrapesTable";
import ScraperDetails from "./home/ScraperDetails";
import ConfirmModal from "./utils/ConfirmModal";
import { toast } from "react-toastify";
import { getFileDownloadLink } from "../common/utils";
import { BsDownload, BsFillXCircleFill } from "react-icons/bs";
import {
  textFilter,
  numberFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import {
  dateTimeFormatter,
  successfulFormatter,
  statsFormatter,
  runTimeFormatter,
  kwargsFormatter
} from "./utils/ScraperTableUtils";
import {
  loadScraperHistory,
  fetchScrapeDetails,
  downloadScrapeResults,
  removeScrapeResult,
} from "../services/crawler.service";

export default class Data extends Component {
  state = {
    scraper: this.props.selectedScraper,
    isLoading: true,
    detailsLoading: false,
    data: [],
    selectedScrapeId: null,
    selectedScrapes: [],
    details: null,
    showModalName: "",
  };

  constructor(props) {
    super(props);
    this.selectScrapeCallback = this.selectScrapeCallback.bind(this);
    this.downloadAllCallback = this.downloadAllCallback.bind(this);
    this.deleteAllCallback = this.deleteAllCallback.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
  }

  componentDidMount() {
    this.setState({
      scraper: this.props.selectedScraper,
      isLoading: true,
      detailsLoading: false,
      data: [],
      selectedScrapeId: null,
      selectedScrapes: [],
      details: null,
    });
    this.loadData(this.props.selectedScraper);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedScraper !== this.props.selectedScraper &&
      this.props.selectedScraper !== this.state.scraper
    ) {
      this.setState({
        scraper: this.props.selectedScraper,
        isLoading: true,
        detailsLoading: false,
        data: [],
        selectedScrapeId: null,
        selectedScrapes: [],
        details: null,
      });
      this.loadData(this.props.selectedScraper);
    }
  }

  loadData(scraper) {
    loadScraperHistory(scraper)
      .then((res) => {
        this.setState({
          data: res.data,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error("Failed to load user data: ", err);
        this.setState({
          data: [],
          isLoading: false,
        });
      });
  }

  updateDetails(scrapeId) {
    if (scrapeId == null) {
      this.setState({
        details: null,
        detailsLoading: false,
      });
    } else {
      fetchScrapeDetails(scrapeId)
        .then((res) => {
          this.setState({
            details: res.data,
            detailsLoading: false,
          });

          window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional, smooth scrolling animation
          });
        })
        .catch((err) => {
          console.error("Failed to get scrape details: ", err);
          this.setState({
            detailsLoading: false,
          });
        });
    }
  }

  selectScrapeCallback(scrapeId, select) {
    const scrapes = Array.isArray(scrapeId) ? scrapeId : [scrapeId];
    if (select) {
      this.setState({
        selectedScrapeId: scrapeId instanceof Array ? null : scrapeId,
        //detailsLoading: scrapeId instanceof Array ? false : true,
        selectedScrapes: [
          ...new Set([...this.state.selectedScrapes, ...scrapes]),
        ],
      });
      if (!(scrapeId instanceof Array)) {
        //this.updateDetails(scrapeId);
      }
    } else {
      this.setState({
        selectedScrapeId: null,
        selectedScrapes: this.state.selectedScrapes.filter(
          (item) => !scrapes.includes(item)
        ),
      });
      //this.updateDetails(null);
    }
  }

  downloadAllCallback() {
    if (this.state.selectedScrapes.length === 0) {
      toast.error("Please select at least 1 row");
      return;
    }
    downloadScrapeResults(this.state.scraper, {
      ids: this.state.selectedScrapes.toString(),
    })
      .then((response) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute(
          "download",
          this.state.scraper + "-export-" + new Date() + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error("Failed to download scrapes", err);
        toast.error("Failed to download scrapes, please try again later");
      });
  }

  deleteAllCallback() {
    this.setState({ isLoading: true, showModalName: "" });
    const deletePromises = this.state.selectedScrapes.map((scrapeId) =>
      removeScrapeResult(scrapeId)
        .then((res) => {
          if (res.data["response"] !== "ok") {
            toast.error(
              "Failed to delete scrape result: " + res.data["response"]
            );
            console.error(
              "Failed to delete scrape result " + res.data["response"]
            );
          }
        })
        .catch((err) => {
          console.error("Failed to delete scrape result: ", err);
        })
    );

    Promise.all(deletePromises)
      .then(() => {
        toast.success("Scrape results successfully deleted");
        this.loadData(this.state.scraper);
        this.handleCancel();
        this.setState({
          selectedScrapes: []
        })
      })
      .catch((error) => {
        console.error("Failed to delete scrape results:", error);
      });
  }

  handleShowModal(modalName, extraKey = undefined, extraValue = undefined) {
    this.setState({ showModalName: modalName });
    if (extraKey) {
      this.setState({ [extraKey]: extraValue });
    }
  }

  handleCancel() {
    this.setState({ showModalName: "" });
  }

  actionFormatter(cell, row, rowIndex, extraData) {
    if (cell && row["is_active"] != true) {
      return (
        <div className="text-center">
          <div className="d-grid gap-2">
            <a
              className="btn btn-outline-primary btn-sm m-1"
              target="_blank"
              href={"/report/"  + row["id"]}
            >
              <span>Report</span>
            </a>
          </div>
          <div className="d-grid gap-2">
            <button
              className="btn btn-outline-warning btn-sm m-1"
              target="_blank"
              onClick={() => this.updateDetails(row["id"], true)}
            >
              <span>Details</span>
            </button>
          </div>
          <div className="d-grid gap-2">
            <a
              className="btn btn-outline-success btn-sm m-1"
              target="_blank"
              href={getFileDownloadLink(row["scraper"], row["id"])}
            >
              <span>Download</span>
            </a>
          </div>
          <div className="d-grid gap-2">
            <button
              key={row["id"]}
              data-id={row["id"]}
              className="btn btn-outline-danger btn-sm mx-1 my-1"
              onClick={() => extraData(row["id"])}
            >
              Delete
            </button>
          </div>
        </div>
      );
    } else {
      return <span></span>;
    }
  }

  removeScrapeResultCallback(scrapeId) {
    this.setState({ isLoading: true });
    removeScrapeResult(scrapeId)
      .then((res) => {
        if (res.data["response"] === "ok") {
          toast.success("Scrape result successfuly deleted");
          this.loadData(this.state.scraper);
          this.handleCancel();
        } else {
          toast.error(
            "Failed to delete scrape result: " + res.data["response"]
          );
          console.error(
            "Failed to delete scrape result: " + res.data["response"]
          );
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.error("Failed to delete scrape result: " + err);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const columns = [
      {
        dataField: "scrape_function",
        text: "Scrape",
        sort: true,
        //filter: textFilter()
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
        //filter: textFilter()
      },
      {
        dataField: "started_at",
        text: "Start",
        sort: true,
        formatter: dateTimeFormatter,
        //filter: dateFilter()
      },
      {
        dataField: "exec_time",
        text: "Exec Runtime",
        sort: true,
        formatter: runTimeFormatter,
        //filter: numberFilter()
      },
      {
        dataField: "total",
        text: "Total",
        sort: true,
        formatter: statsFormatter,
        //filter: numberFilter()
      },
      {
        dataField: "is_successful",
        text: "Successful",
        formatter: successfulFormatter,
        // filter: selectFilter({
        //     options: [{
        //         value: true,
        //         label: "Successful"
        //     }, {
        //         value: false,
        //         label: "Error"
        //     }]
        // })
      },
      {
        dataField: "result_message",
        text: "Result",
        //filter: textFilter()
      },
      {
        dataField: "args",
        text: "Args",
        formatter: kwargsFormatter
        //filter: textFilter()
      },
    ];

    return (
      <div>
        <div className="my-3">
          <ScraperDetails
            user={this.props.user}
            selectedScrapeId={this.state.selectedScrapeId}
            scrapeData={this.state.data.filter(
              (item) => item.id === this.state.selectedScrapeId
            )}
            details={this.state.details}
            updateDetails={this.updateDetails}
            isLoading={this.state.detailsLoading}
          />
        </div>
        <ScrapesTable
          title={"Your scrape data"}
          actions={true}
          columns={columns}
          data={this.state.data}
          actionFormatter={this.actionFormatter}
          actionCallback={(scrapeId) =>
            this.handleShowModal("DeleteItem", "selectForDelete", scrapeId)
          }
          downloadAllCallback={this.downloadAllCallback}
          deleteAllCallback={() => this.handleShowModal("VerifyDeleteAll")}
          updateDetails={this.updateDetails}
          selectScrapeCallback={this.selectScrapeCallback}
          user={this.props.user}
          tutorialId={'scrapeHistory'}
        />

        {this.state.showModalName === "VerifyDeleteAll" && (
          <div
            className="modal show"
            id="VerifyDeleteAllModal"
            role="dialog"
            tabIndex="-1"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <ConfirmModal
                  title="Are you sure to delete all selected row?"
                  description={
                    this.state.selectedScrapes.length +
                    " scrape data will be deleted"
                  }
                  okCallback={this.deleteAllCallback}
                  cancelCallback={this.handleCancel}
                />
              </div>
            </div>
          </div>
        )}

        {this.state.showModalName === "DeleteItem" && (
          <div
            className="modal show"
            id="DeleteItemModal"
            role="dialog"
            tabIndex="-1"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <ConfirmModal
                  title="Are you sure to delete?"
                  description="1 scrape data will be deleted and can't be restored"
                  okCallback={() =>
                    this.removeScrapeResultCallback(this.state.selectForDelete)
                  }
                  cancelCallback={this.handleCancel}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
