import React, { Component } from "react";
import Loading from "../../common/Loading";
import { renderFormElement } from "../utils/GenerativeForm";

class ScheduleScrape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {},
    };
  }

  componentDidMount() {
    this.setDefaultValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params !== this.props.params) {
      this.setDefaultValues();
    }
  }

  fiveMinutesFromNowString() {
    const currentDate = new Date(new Date().getTime() + 5 * 60000);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    //const current = .toLocaleString(undefined, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    return formattedDate;
  }

  scheduleParams() {
    return (
      <div>
        <div key="scraper" className="form-group mb-3">
          <label htmlFor="scraper">
            <strong>Scraper name</strong>
          </label>
          <input
            type="input"
            className="form-control"
            id="scraper"
            onChange={this.handleInputChange}
            value={this.props.scraper}
            disabled
          />
          <small className="form-text text-muted">Name of the scraper</small>
        </div>
        <div key="interval" className="form-group my-3">
          <label htmlFor="interval">
            <strong>Interval</strong>
          </label>
          <select
            className="form-control"
            id="interval"
            defaultValue="hours"
            onChange={this.handleInputChange}
          >
            <option key="seconds" value="seconds">
              Seconds
            </option>
            <option key="minutes" value="minutes">
              Minutes
            </option>
            <option key="hours" value="hours">
              Hours
            </option>
            <option key="days" value="days">
              Days
            </option>
          </select>
          <input
            type="number"
            className="form-control mt-2"
            id="interval_value"
            onChange={this.handleInputChange}
            defaultValue={10}
          />
          <small className="form-text text-muted">
            Schedule interval
            <br />
            Please make sure that the scrape will not overlap with any other
            schedule, because it may fail
          </small>
        </div>
        <div key="start_time" className="form-group my-3">
          <label htmlFor="start_time">
            <strong>Start time </strong> (
            {Intl.DateTimeFormat().resolvedOptions().timeZone})
          </label>
          <input
            type="datetime-local"
            className="form-control mt-2"
            id="start_time"
            defaultValue={this.fiveMinutesFromNowString()}
            onChange={this.handleInputChange}
          ></input>
          <small className="form-text text-muted">
            First run of the scrape. Set the value at least few minutes from now
          </small>
        </div>
      </div>
    );
  }

  setDefaultValues() {
    const newFormValues = {};

    this.props.params.forEach((obj) => {
      newFormValues[obj.name] = obj.default;
    });
    newFormValues["interval"] = "hours";
    newFormValues["interval_value"] = 10;
    newFormValues["start_time"] = this.fiveMinutesFromNowString();
    newFormValues["timezone"] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.setState({
      formValues: newFormValues,
    });
  }

  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [id]: value,
      },
    }));
  };

  handleSliderChange = (e) => {
    const { id, value } = e.target;
    document.getElementById(`${id}-value`).innerText = e.target.value;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [id]: value,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.scheduleScrapeCallback(this.state.formValues);
  };

  render() {
    return (
      <div className="">
        <div className="modal-header">
          <h5 className="modal-title">Schedule a new scrape</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.handleScheduleScrapeModalCloseCallback}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <h5>Schedule params</h5>
            {this.scheduleParams()}
            <h5>Scrape run params</h5>
            {this.props.params.map((field) => renderFormElement(field, this.handleInputChange, this.handleSliderChange))}
          </form>
        </div>
        <div className="modal-footer">
          {this.props.isLoading ? (
            <div className="modal-footer d-flex justify-content-center">
              <Loading type="dots"></Loading>
            </div>
          ) : (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={this.handleSubmit}
              >
                Schedule
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={this.props.handleScheduleScrapeModalCloseCallback}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ScheduleScrape;
