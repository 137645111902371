console.log(process.env.BACKEND_URL)
export const BASE_ADDRESS = (process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000') + "/";
export const BACKEND_ADDRESS = BASE_ADDRESS + "api/";
export const WS_ADDRESS = (process.env.REACT_APP_BACKEND_WS || "ws://localhost:8000") + "/ws/scraper/";
export const SERVICE_WS_ADDRESS =  (process.env.REACT_APP_BACKEND_WS || "ws://localhost:8000") + "/ws/service/";

export const ADMIN_PERMISSION = ['admin'];
export const PRO_PERMISSION = ['admin', 'pro'];
export const CLIENT_PERMISSION = ['admin', 'pro', 'client'];
export const GUEST_PERMISSION = ['admin', 'pro', 'client', 'guest'];

export const CAPTCHA_FRONTEND_KEY = (process.env.REACT_APP_FRONTEND_CAPTCHA_KEY || "123");
export const CAPTCHA_AUTH_KEY = (process.env.REACT_APP_CAPTCHA_AUTH_KEY|| "thiswontworkinprod_sorry_bro");