import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';
import Report from './Report';
import Loading from '../common/Loading';
import withRouter from '../common/WithRouter';
import { fetchReportDataByScrapeId } from '../services/stats.service';

class PrivateReport extends Component {

    constructor() {
        super();
        this.state = {
            reportData: {},
            tableData: {},
            serviceResultId: -1,
            isLoading: true
        };
    }

    componentDidMount() {
        const { scrapeId } = this.props.router.params;
        this.loadReportData(scrapeId);
    }

    loadReportData(scrapeId) {
        fetchReportDataByScrapeId(scrapeId).then((data) => {
            this.setState({
                reportData: data.data.data,
                tableData: data.data.table,
                serviceResultId: data.data.service_result_id,
                isLoading: false
            })
        }).catch((err => {
            console.error("Failed to load report data", err);
        }))
    }

    render() {
        if (this.state.isLoading) {
            return (<div className='d-flex justify-content-center'>
                <Loading type="rings" />
            </div>)
        } else if (Object.keys(this.state.reportData).length === 0) {
            return <Navigate to="/not-found" />;
        } else {
            return (
                <Report reportData={this.state.reportData} tableData={this.state.tableData} serviceResultId={this.state.serviceResultId} isPrivateReport={true}></Report>
            )
        }
    }
}

export default withRouter(PrivateReport);