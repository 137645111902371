import axios from "axios";
import Cookies from "js-cookie";
import { errorMiddleware } from "./middleware";
import { BACKEND_ADDRESS } from "./const";

const axiosInstance = axios.create({
  baseURL: BACKEND_ADDRESS,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  errorMiddleware
)

async function tokenConfig() {
  if (!Cookies.get("csrftoken")) {
    await fetchCSRF();
  }
  return {
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
}

async function fetchCSRF() {
  let resp = await axios.get(BACKEND_ADDRESS + "user/csrf_cookie", {
    withCredentials: true,
  });
  return;
}

export async function fetchScrapers() {
  let tempInstance = axios.create({
    baseURL: BACKEND_ADDRESS,
    withCredentials: true,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "csrftoken",
  });

  let config = await tokenConfig();
  return tempInstance.get("scrapers", config);
}

export async function startNewScrape(scraper, params) {
  let config = await tokenConfig();
  return axiosInstance.post("scraper-run/" + scraper, params, config);
}

export async function stopNewScrape(scrape_id, force=false) {
  let config = await tokenConfig();
  return axiosInstance.post("scraper-stop/" + scrape_id + "?force=" + force, config);
}

export async function fetchScraperRunParams(scraper) {
  let config = await tokenConfig();
  return axiosInstance.get("scraper-params/" + scraper, config);
}

export async function loadScraperHistory(scraper) {
  let config = await tokenConfig();
  return axiosInstance.get("scraper-results/" + scraper, config);
}

export async function removeScrapeResult(scrapeId) {
  let config = await tokenConfig();
  return axiosInstance.post("scraper-result-remove/" + scrapeId, config);
}


export async function fetchScrapeDetails(detailsId) {
  let config = await tokenConfig();
  return axiosInstance.get("scraper-details/" + detailsId, config);
}

export async function fetchActive(scraper) {
  let config = await tokenConfig();
  return axiosInstance.get("scraper-active/" + scraper, config);
}

export async function fetchSchedules(scraper, params) {
  let config = await tokenConfig();
  return axiosInstance.get("schedules/" + scraper, params, config);
}

export async function scheduleNewScrape(scraper, params) {
  let config = await tokenConfig();
  return axiosInstance.post("schedule-add/" + scraper, params, config);
}

export async function removeScheduleScrape(scraper, params) {
  let config = await tokenConfig();
  return axiosInstance.post("schedule-remove/" + scraper, params, config);
}

export async function downloadScrapeResults(scraper, ids){
  let config = await tokenConfig();
  config["headers"]["Content-Type"] = "application/vnd.ms-excel";
  config["responseType"] = "blob";
  config["params"] = ids;
  return axiosInstance.get("scraper-download-results/" + scraper, config);
}