import React, { Component } from 'react'

export default class Pricing extends Component {
    render() {
        return (
            <div data-aos="fade-up" data-aos-delay="300" className='container'>
                <div className='mb-5'>
                    <h2 className='d-flex justify-content-center display-3'>Pricing</h2>
                    <p ><div className='d-flex justify-content-center'>Our pricing model is completly pay-as-you-go! </div> <div className='d-flex justify-content-center'>After registration you can try every service for free, and if you decide to purchase 💎-s, you can use all of them without any limits.</div></p>
                </div>
                <div className='row gy-4 d-flex justify-content-center'>
                    <div data-aos="zoom-in" data-aos-delay="400" className='col-lg-3 col-md-6 mx-2 h-100'>
                        <div className='card price-card card-hover'>
                            <div className='card-header '>
                                <h3>Free</h3>
                                <div className='price'>
                                    <sup>$</sup>
                                    0
                                </div>
                            </div>
                            <div className='card-body'>
                                <ul>
                                    <li>All service available</li>
                                    <li>Services have limits</li>
                                    <li className='na'>AI features</li>
                                    <li className='na'>Primary support</li>
                                </ul>
                            </div>
                            <div className='d-flex justify-content-center my-3'>
                                <a href='/dashboard' className='btn btn-outline-primary rounded-pill'>Let's go</a>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-delay="400" className='col-lg-3 col-md-6 mx-2 h-100'>
                    
                        <div className='card card-hover price-card'>
                        <span className='featured d-flex justify-content-center'>Featured</span>
                            <div className='card-header '>
                                <h3>Standard</h3>
                                <div className='price'>
                                    Pay as you go 💎💎💎
                                </div>
                            </div>
                            <div className='card-body'>
                                <ul>
                                <li>All service available</li>
                                    <li>No limitations</li>
                                    <li>No subscription</li>
                                    <li>AI features</li>
                                    <li >Primary support</li>
                                </ul>
                            </div>
                            <div className='d-flex justify-content-center my-3'>
                                <a href='/dashboard' className='btn btn-outline-primary rounded-pill'>Let's go</a>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-delay="400" className='col-lg-3 col-md-6 mx-2 h-100'>
                        <div className='card price-card card-hover'>
                            <div className='card-header '>
                                <h3>Premium</h3>
                                <div className='price'>
                                    Custom
                                </div>
                            </div>
                            <div className='card-body'>
                                <ul>
                                    <li>Custom pricing</li>
                                    <li>No limit</li>
                                    <li>Extra data analysis</li>
                                    <li>For very high usage</li>
                                </ul>
                            </div>
                            <div className='d-flex justify-content-center my-3'>
                                <a href='#contact' className='btn btn-outline-primary rounded-pill'>Contact us</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}
