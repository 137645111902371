import React, { Component } from "react";
import AlertCreate from "./alert/AlertCreate";
import AlertList from "./alert/AlertList";
import { toast } from "react-toastify";

import {
  fetchAlerts,
  removeAlert,
  createAlert,
} from "../services/alert.service";

class Alert extends Component {
  state = {
    alerts: [],
    alertsLoading: false,
  };

  constructor(props) {
    super(props);
    this.removeAlertCallback = this.removeAlertCallback.bind(this);
    this.createAlertCallback = this.createAlertCallback.bind(this);
  }

  componentDidMount() {
    this.updateAlerts(this.props.selectedScraper);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedScraper !== this.props.selectedScraper &&
      this.props.selectedScraper !== this.state.scraper
    ) {
      this.updateAlerts(this.props.selectedScraper);
    }
  }

  updateAlerts(scraper) {
    this.setState({
      alertsLoading: true,
      alerts: [],
      scraper: this.props.selectedScraper,
    });

    fetchAlerts(scraper)
      .then((res) => {
        this.setState({
          alertsLoading: false,
          alerts: res.data,
          scraper: this.props.selectedScraper,
        });
      })
      .catch((err) => {
        console.error("Failed to fetch alerts", err);
      });
  }

  removeAlertCallback(alertId) {
    removeAlert(this.state.scraper, alertId)
      .then((res) => {
        toast.success("Alert deleted: " + alertId);
        this.updateAlerts(this.state.scraper);
      })
      .catch((err) => {
        console.error("Failed to delete alert: ", err);
      });
  }

  createAlertCallback(params) {
    this.setState({ alertsLoading: true });
    createAlert(this.state.scrape, params)
      .then((res) => {
        toast.success("New alert created");
        this.updateAlerts(this.state.scraper);
      })
      .catch((err) => {
        console.error("Faield to create: ", err);
        this.setState({ alertsLoading: false });
      });
  }

  render() {
    return (
      <div className="mx-3">
        <div className="row my-3">
          <div className="col-lg-6">
            <AlertList
              isLoading={this.state.alertsLoading}
              alerts={this.state.alerts}
              removeCallback={this.removeAlertCallback}
            />
          </div>
          <div className="col-lg-6">
            <AlertCreate
              scrape={this.state.scraper}
              createAlertCallback={this.createAlertCallback}
              isLoading={this.state.alertsLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Alert;
