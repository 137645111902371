import React, { Component } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
    BsFillCheckCircleFill,
    BsFillPlayCircleFill,
    BsFillArrowUpRightSquareFill
} from "react-icons/bs";
import filterFactory, { textFilter, numberFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { convertToUtf8 } from '../compontent/utils/ScraperTableUtils';


export default class GeneralTable extends Component {
    
  render() {
    const { SearchBar } = Search;

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        style: { backgroundColor: "#00BFFF" },
        onSelect: (row, isSelect, rowIndex, e) => {
            this.props.selectCallback(row, isSelect, rowIndex, e);
        },
        onSelectAll: (isSelect, rows, e) => {
            this.props.selectCallback(rows, isSelect, -1, e);
        }
      };

    const dontSelectRow = {
        
    }

    const paginationOptions = {
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: 'All', value: this.props.data.length
        }]
    }

    return (
        <ToolkitProvider
            keyField={this.props.id}
            data={convertToUtf8(this.props.data)}
            columns={this.props.columns}
            striped
            hover
            condensed
            search
        >
        {(props) => (
          <div>
            {this.props.search && (<div><div className="row">
              <div className="col-md-6 col-sm-6 m-2">
                <SearchBar {...props.searchProps} srText="" />
              </div>
            </div>
            <hr /></div>)}
            <BootstrapTable
              {...props.baseProps}
              selectRow={this.props.selectCallback? selectRow: undefined}
              filter={ filterFactory() }
              pagination={paginationFactory(paginationOptions)}
              wrapperClasses="table-responsive"
              noDataIndication="It's empty here"
            />
          </div>
        )}
      </ToolkitProvider>
    )
  }
}
