import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ScatterChart,
  Scatter,
  PieChart,
  Pie,
  AreaChart,
  Area,
  Brush,
} from "recharts";
import React, { useRef, useEffect, useState } from "react";
import { BASE_ADDRESS } from "../../services/const";
import {
  BsFileEarmarkSpreadsheet,
  BsArchiveFill,
  BsFiletypeCsv,
  BsFiletypeDoc,
  BsFiletypeJson,
  BsFiletypeTxt,
  BsFiletypeXlsx,
  BsFileEarmarkArrowDownFill,
  BsFileArrowDown,
} from "react-icons/bs";
import PriceModal from "../services/PriceModal";
import GeneralTable from "../../common/GeneralTable";
import { formatTableColumns } from "./ReportTableUtils";

const BASE_COLOR = "#0096c7";
const SECOND_COLOR = "#48cae4";
const THIRD_COLOR = "#ade8f4";
const HIGHLIGHT_COLOR = "#ffb703";
const HIGHLIGHT_COLOR_2 = "#fb8500";
const COLOR_PALETTE = [
  "#03045e",
  "#023e8a",
  "#0077b6",
  "#0096c7",
  "#00b4d8",
  "#48cae4",
  "#90e0ef",
  "#ade8f4",
  "#caf0f8",
];
/*const BASE_COLOR = "#faa307";
const SECOND_COLOR = "#e85d04";
const THIRD_COLOR = "#d00000";
const HIGHLIGHT_COLOR = "#ffba08";
const COLOR_PALETTE = [
  "#ffba08",
  "#faa307",
  "#f48c06",
  "#e85d04",
  "#dc2f02",
  "#d00000",
  "#9d0208",
  "#6a040f",
  "#370617"
]*/

const BaseComponent = ({ i, component, componentData, isMobile }) => {
  const componentMap = {
    StatCard,
    TopVideosBarChart,
    SimpleBarChart,
    SimpleRadarChart,
    SimplePieChart,
    DocumentsContainer,
    ListContainer,
    StackedAreaChart,
    SimpleBushBarChart,
    BasicUserHeader,
    TableContainer,
  };

  const chartRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    observer.observe(chartRef.current);

    // Clean up the observer
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={chartRef} className="">
      {isVisible && (
        <React.Fragment key={component.id}>
          {componentMap[component.component_type](
            i,
            component,
            componentData,
            isMobile
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export function StatCard(i, component, componentData, isMobile) {
  if (componentData) {
    const preFix = component.pre_fix !== undefined ? component.pre_fix : "";
    const postFix = component.post_fix !== undefined ? component.post_fix : "";
    const displayValue =
      component.to_fixed !== undefined && !isNaN(Number(componentData))
        ? Number(componentData).toFixed(component.to_fixed)
        : componentData;
    const postPercentDisplay =
      component.post_percent !== undefined
        ? " (" + component.post_percent.toFixed(2) + " %)"
        : "";
    return (
      <div className="card mx-1 px-0 animate__animated animate__bounceIn animate__faster">
        <div className="card-header">
          <strong>{component.title}</strong>
        </div>
        <div className="card-body">
          {preFix}
          {displayValue}
          {postFix}
          {postPercentDisplay}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export function TopVideosBarChart(i, component, componentData, isMobile) {
  const handleClick = (data, index) => {
    if (data[component.on_click_open_target_property] !== undefined) {
      window.open(data[component.on_click_open_target_property], "_blank");
    }
  };

  const convertToInteger = (value) => {
    if (value == true) {
      return 1;
    } else if (value == false) {
      return 0;
    } else if (!isNaN(value)) {
      return parseInt(value, 10);
    }
    return value;
  };

  let modifiedData = componentData;
  if (component.bar_3_key) {
    modifiedData = componentData.map((item) => ({
      ...item,
      [component.bar_3_key]: convertToInteger(item[component.bar_3_key]),
    }));
  }

  return (
    <div>
      <h5>{component.title}</h5>
      <ResponsiveContainer width="90%" height={360}>
        <BarChart width={400} height={230} data={modifiedData}>
          <XAxis dataKey={component.x_key} hide={true} />
          <CartesianGrid strokeDasharray="3 3" />
          {!isMobile && <YAxis width={120} />}
          <Tooltip />
          <Legend />
          {component.bar_1_key && (
            <Bar
              name={component.bar_1_name}
              dataKey={component.bar_1_key}
              animationBegin={1000}
              animationDuration={1500}
              stackId="a"
              onClick={handleClick}
              fill={BASE_COLOR}
            />
          )}
          {component.bar_2_key && (
            <Bar
              name={component.bar_2_name}
              dataKey={component.bar_2_key}
              animationBegin={1500}
              animationDuration={1500}
              stackId="a"
              onClick={handleClick}
              fill={SECOND_COLOR}
            />
          )}
          {component.bar_3_key && (
            <Bar
              name={component.bar_3_name}
              dataKey={component.bar_3_key}
              stackId="a"
              animationBegin={2000}
              animationDuration={1500}
              onClick={handleClick}
              fill={THIRD_COLOR}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export function SimpleBarChart(i, component, componentData, isMobile) {
  return (
    <div>
      <h5>{component.title}</h5>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={componentData}>
          <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
          <XAxis dataKey={component.x_key} unit={component.x_unit} />
          {!isMobile && <YAxis />}
          <Tooltip
            wrapperStyle={{ width: 100, backgroundColor: "#ccc" }}
            formatter={function (total) {
              return `${total}`;
            }}
          />
          <Bar
            dataKey={component.y_key}
            fill={BASE_COLOR}
            animationBegin={1000}
            animationDuration={1500}
            activeLabel={{ fill: "red", fontSize: 16 }}
            unit={component.y_unit}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export function SimpleBushBarChart(i, component, componentData, isMobile) {
  return (
    <div>
      <h5>{component.title}</h5>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={componentData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={component.x_key} unit={component.x_unit} />
          {!isMobile && <YAxis />}
          <Tooltip />
          <Bar
            dataKey={component.y_key}
            fill={BASE_COLOR}
            animationBegin={1000}
            animationDuration={1500}
            unit={component.y_unit}
          />
          <Brush
            dataKey={component.x_key}
            height={30}
            stroke={COLOR_PALETTE[2]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export function SimpleRadarChart(i, component, componentData, isMobile) {
  if (isMobile) {
    return null;
  }
  return (
    <div>
      <h5>{component.title}</h5>
      <ResponsiveContainer width="90%" height={400}>
        <RadarChart
          cx={300}
          cy={250}
          outerRadius={150}
          width={600}
          height={500}
          data={componentData}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey={component.x_key} />
          <PolarRadiusAxis />
          <Radar
            dataKey={component.y_key}
            stroke="#8884d8"
            fill={SECOND_COLOR}
            animationBegin={1000}
            animationDuration={1500}
            fillOpacity={0.6}
          />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}

export function SimplePieChart(i, component, componentData, isMobile) {
  if (componentData) {
    return (
      <div className="col-md-6 col-12">
        <h5>{component.title}</h5>
        <div className="">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                dataKey="value"
                padding={{
                  top: 10,
                  right: 100,
                  left: 100,
                  bottom: 0,
                }}
                data={componentData}
                outerRadius={80}
                fill="#8884d8"
                animationBegin={1000}
                animationDuration={1500}
                labelLine={false}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  index,
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = 5 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  if (value > 10) {
                    return (
                      <text
                        x={x}
                        y={y}
                        fill="#8884d8"
                        fontSize={12}
                        textAnchor={x > cx ? "start" : "end"}
                        dominantBaseline="central"
                      >
                        {componentData[index].name} ({value})
                      </text>
                    );
                  }
                }}
              >
                {componentData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLOR_PALETTE[(index * 2 + 1) % COLOR_PALETTE.length]}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value, name) => `${value}`} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  } else {
    return "";
  }
}

export function DocumentsContainer(i, component, componentData, isMobile) {
  const getFileIcon = (fileType) => {
    if (fileType.endsWith(".csv")) {
      return <BsFiletypeCsv size={50} />;
    } else if (fileType.endsWith(".xlsx")) {
      return <BsFiletypeXlsx size={50} />;
    } else if (fileType.endsWith(".zip")) {
      return <BsArchiveFill size={50} />;
    } else if (fileType.endsWith(".txt")) {
      return <BsFiletypeTxt size={50} />;
    } else if (fileType.endsWith(".json")) {
      return <BsFiletypeJson size={50} />;
    } else {
      return <BsFileArrowDown size={50} />;
    }
  };

  if (componentData) {
    return (
      <div className="row">
        {Object.keys(componentData).map((name, index) => (
          <div key={index} className="col-md-2 col-sm-4 col-6 my-3">
            <div className="card h-100">
              <div className="card-header">
                <strong>{name}</strong>
              </div>
              <a
                href={
                  (componentData[name].startsWith("https://")
                    ? ""
                    : BASE_ADDRESS) +
                  componentData[name].replace("/shared/data/", "media/")
                }
              >
                <div className="card-body d-flex justify-content-center">
                  {getFileIcon(componentData[name])}
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return "";
  }
}

export function ListContainer(i, component, componentData, isMobile) {
  if (componentData) {
    const filteredData = componentData.slice(0, component.rows).map((data) => {
      const row = {};
      component.column_datas.forEach((property, index) => {
        const propName = component.column_datas[index];
        if (propName in data) {
          row[propName] = data[propName];
        }
      });
      return row;
    });

    return (
      <div>
        <h5>{component.title}</h5>
        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              {component.column_headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                {component.column_datas.map((property, i) => (
                  <td key={i}>{row[property]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return "";
  }
}

export function StackedAreaChart(i, component, componentData, isMobile) {
  if (componentData) {
    return (
      <div>
        <h5>{component.title}</h5>
        <ResponsiveContainer width="90%" height={400}>
          <AreaChart
            width={400}
            height={230}
            data={componentData}
            margin={{
              top: 10,
              right: 50,
              left: 50,
              bottom: 0,
            }}
          >
            <XAxis dataKey={component.x_key} hide={true} />
            {!isMobile && <YAxis />}
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {component.area_1_key && (
              <Area
                type="monotone"
                name={component.area_1_name}
                dataKey={component.area_1_key}
                stackId="1"
                stroke={COLOR_PALETTE[2]}
                animationBegin={1000}
                animationDuration={1500}
                fill={BASE_COLOR}
              />
            )}
            {component.area_2_key && (
              <Area
                type="monotone"
                name={component.area_2_name}
                dataKey={component.area_2_key}
                stackId="1"
                stroke={COLOR_PALETTE[5]}
                animationBegin={1500}
                animationDuration={1500}
                fill={SECOND_COLOR}
              />
            )}
            {component.area_3_key && (
              <Area
                type="monotone"
                name={component.area_3_name}
                dataKey={component.area_3_key}
                stackId="1"
                stroke={COLOR_PALETTE[7]}
                animationBegin={2000}
                animationDuration={1500}
                fill={THIRD_COLOR}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  } else {
    return "";
  }
}

export function BasicUserHeader(i, component, componentData, isMobile) {
  if (componentData) {
    return <div>Profile header</div>;
  } else {
    return "";
  }
}

export function TableContainer(i, component, componentData, isMobile) {
  if (componentData) {
    return (
      <div>
        <GeneralTable
          id={component.table_id_field}
          data={componentData.data}
          search={true}
          columns={formatTableColumns(componentData.columns, componentData.types, componentData.titles, componentData.extra, isMobile)}
        />
      </div>
    );
  } else {
    return "";
  }
}

export default BaseComponent;
