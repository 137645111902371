import React, { Component } from 'react'
import ScrapesTable from '../utils/ScrapesTable';
import filterFactory, { textFilter, numberFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { dateTimeFormatter, statsFormatter, successfulFormatter } from '../utils/ScraperTableUtils';

export default class ScrapeSelector extends Component {
    
    constructor() {
        super();
        this.state = {
            selectedScrapes: []
        };

        this.selectScrapeCallback = this.selectScrapeCallback.bind(this);
    }


    selectScrapeCallback(scrapeId, select) {
        const scrapes = Array.isArray(scrapeId) ? scrapeId : [scrapeId];
        if (select) {
          this.setState({
            selectedScrapes: [
              ...new Set([...this.state.selectedScrapes, ...scrapes]),
            ],
          });
        } else {
          this.setState({
            selectedScrapes: this.state.selectedScrapes.filter(
              (item) => !scrapes.includes(item)
            ),
          });
        }
      }

    render() {
        const columns = [
            {
                dataField: "id",
                text: "ID",
                sort: true,
                filter: textFilter()
            },
            {
                dataField: "name",
                text: "Name",
                sort: true,
                filter: textFilter()
            },
            {
                dataField: "started_at",
                text: "Start",
                sort: true,
                formatter: dateTimeFormatter,
                filter: dateFilter()
            },
            {
                dataField: "total",
                text: "Total",
                sort: true,
                formatter: statsFormatter,
                filter: numberFilter()
            },
            {
                dataField: "is_successful",
                text: "Successful",
                formatter: successfulFormatter,
                filter: selectFilter({
                    options: [{
                        value: true,
                        label: "Successful"
                    }, {
                        value: false,
                        label: "Error"
                    }]
                })
            },
            {
                dataField: "result_message",
                text: "Result",
                filter: textFilter()
            },
            {
                dataField: "args",
                text: "Args",
                filter: textFilter()
            }
        ];

        return (
            <div className="container">
                <div className="row d-flex my-1">
                    <div className='col'><h5>Service: {this.props.selectedService.name}</h5></div>
                    <div className='col d-flex justify-content-end'>
                        <button className="btn btn-warning mx-2" onClick={() => this.props.handleBack(null)}>Back</button>
                        <button className="btn btn-success" onClick={() => this.props.handleNext(this.state.selectedScrapes)}>Next</button>
                    </div>
                </div>
                <div className="row">
                    <ScrapesTable title="Select scrapes" columns={columns} data={this.props.scrapesData} selectScrapeCallback={this.selectScrapeCallback}/>
                </div>
            </div>
        )
    }
}
