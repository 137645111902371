import React, { Component } from 'react';
import axios from 'axios';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import { register } from '../../services/auth.service.js';


class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            error: '',
            isLoading: false,
            successful: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        const { username, email, password, confirmPassword } = this.state;

        if (!username || !email || !password || !confirmPassword) {
            this.setState({ error: 'All fields are required' });
            return;
        }

        if (password !== confirmPassword) {
            this.setState({ error: 'Passwords do not match' });
            return;
        }

        this.setState({ isLoading: true });
        register(username, email, password).then((res) => {
            this.setState({
                isLoading: false,
                successful: true
            });
        }).catch((err) => {
            this.setState({
                isLoading: false
            });
            console.error('Registration failed', err.response.data);
            this.setState({ error: 'Registration failed. Please try again.' });
        });
    }

    render() {
        const { username, email, password, confirmPassword, error } = this.state;
        if (this.state.isLoading) {
            return (
                <div className="d-flex flex-column align-items-center justify-content-center my-5">
                    <Loading type="rings"></Loading>
                </div>
            );
        }
        else if (this.state.successful) {
            return (
                <div className="container m-5">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    Registration successful, please check your emails to log in
                                </div>
                                <div className='card-footer'>
                                    <a href="resend" className="btn btn-link text-dark btn-outline-warning">
                                        Resend email
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
        }
        else {
            return (
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h2 className="card-title text-center">Registration</h2>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="username">Username</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="username"
                                                name="username"
                                                value={username}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary btn-block my-3">
                                            Register
                                        </button>
                                        <a href="resend" className="btn btn-secondary mx-2">
                                            Resend email
                                        </a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Registration;
