import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import Loading from "../../common/Loading";
import { getFileDownloadLink } from "../../common/utils";
import { baseColumnFormatter, baseColumnFilter } from "../utils/ScraperTableUtils";
import { withMobileState } from "../utils/ComponentHOCs";
import { convertToUtf8 } from "../utils/ScraperTableUtils";
import TutorialHelper from "../utils/TutorialHelper";

class ScraperDetails extends Component {

  formatColumns(columns, data) {
    let formatted = [];
    columns = baseColumnFilter(columns);

    columns.forEach((element) => {

      if (!data.every(obj => !obj.hasOwnProperty(element) || obj[element] === undefined || obj[element] === "")) {
        formatted.push({
          dataField: element,
          text: element,
          formatter: baseColumnFormatter,
        });
      }
    });
    return formatted;
  }

  formatData(data) {
    return data;
  }

  render() {
    const expandRow = {
      renderer: (row) => (
        <div>
          {Object.keys(row).map((key) => (
            <p key={key}>{`${key}: ${row[key]}`}</p>
          ))}
        </div>
      ),
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b>-</b>;
        }
        return <b>+</b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <b>-</b>;
        }
        return <b>...</b>;
      },
    };

    if (this.props.isLoading) {
      return (
        <div className="card card-body">
          <Loading type="rings"></Loading>
        </div>
      );
    } else if (this.props.details) {
      return (
        <div className="card card-body">
          <div className={this.props.isMobile ? "" : "d-flex justify-content-between"}>
            <div className={this.props.isMobile ? "row" : ""}>
              <h5 className="my-2">
                {this.props.scrapeData.length && this.props.scrapeData.length
                  ? this.props.scrapeData[0].name
                  : ""}
              </h5>
            </div>
            <div className='position-absolute top-0 end-0 tutorial-help-div'>
              <TutorialHelper tutorialId="scrapeDetails" user={this.props.user} />
            </div>
            <div className={this.props.isMobile ? "row" : ""}>
              <a
                className="btn btn-outline-primary m-1"
                target="_blank"
                rel="noreferrer"
                href={"/report/" + (this.props.scrapeData.length && this.props.scrapeData.length > 0 ? this.props.scrapeData[0].id : "")}
              >
                Report
              </a>
              <a
                className="btn btn-outline-info m-1"
                href={getFileDownloadLink(
                  this.props.scrapeData.length && this.props.scrapeData.length > 0 ? this.props.scrapeData[0].scraper : "",
                  this.props.scrapeData.length && this.props.scrapeData.length > 0 ? this.props.scrapeData[0].id : ""
                )}
              >
                Export
              </a>
              <button className="btn btn-outline-warning m-1" onClick={() => this.props.updateDetails(null)}>Close</button>
            </div>
          </div>
          <hr />
          <BootstrapTable
            keyField="id"
            data={convertToUtf8(this.formatData(this.props.details["data"]))}
            columns={this.formatColumns(this.props.details["columns"], this.props.details["data"])}
            pagination={paginationFactory()}
            striped
            hover
            condensed
            noDataIndication="It's empty here"
            wrapperClasses="table-responsive"
          //expandRow={expandRow}
          />
        </div>
      );
    } else {
      return <div className="card card-body">Scrape details are empty</div>;
    }
  }
}

export default withMobileState(ScraperDetails);
