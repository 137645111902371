import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { BsGoogle } from 'react-icons/bs';

import { authenticateWithGoogle } from '../../services/auth.service';

export class Login extends Component {
  state = {
    username: '',
    password: ''
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleGooggleLogin(){
    this.props.googleAuth();
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Navigate to="/dashboard" />;
    }
    const { username, password } = this.state;
    return (
      <div className="col-md-4 m-auto content my-5">
        <section className='box-section'>
          <div className="card card-body auth-div box">
            <h2 className="text-center">Login</h2>
            <form onSubmit={this.onSubmit}>
              <div className="form-group my-2">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  onChange={this.onChange}
                  value={username}
                />
              </div>

              <div className="form-group my-2">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={this.onChange}
                  value={password}
                />
              </div>

              <div className="form-group mt-2">
                <button type="submit" className="btn btn-primary">
                  Login
                </button>
                <a href="register" className="btn btn-secondary mx-2">
                  Registration
                </a>
              </div>
              <div className="form-group mt-2">
                <button type="button" className="btn btn-info" onClick={()=> this.handleGooggleLogin()}> <BsGoogle className='mr-2'/> Login with Google </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}


export default Login;