import React, { Component } from 'react'
import { FcBullish } from 'react-icons/fc';
import TutorialHelper from '../utils/TutorialHelper';
import 'animate.css';


export default class ServiceSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentAnimationIndex: 0,
            selectedBadges: [],
        };
    }

    handleAnimationEnd = () => {
        // Animation for the current element has ended.
        // Increment the animation index to start the next animation.
        this.setState((prevState) => ({
            currentAnimationIndex: prevState.currentAnimationIndex + 1,
        }));
    }

    handleBadgeClick = (tag) => {
        this.setState((prevState) => {
            const selectedBadges = [...prevState.selectedBadges];

            if (selectedBadges.includes(tag)) {
                selectedBadges.splice(selectedBadges.indexOf(tag), 1);
            } else {
                selectedBadges.push(tag);
            }

            return {
                selectedBadges,
            };
        });
    }

    render() {
        const { currentAnimationIndex, selectedBadges } = this.state;
        const { servicesData } = this.props;

        const filteredServicesData = selectedBadges.length > 0
            ? servicesData.filter(item =>
                item.tags.some(tag => selectedBadges.includes(tag.name))
            )
            : servicesData;

        return (
            <div className="container">
                <div className='position-absolute top-0 end-0 tutorial-help-div'>
                    <TutorialHelper tutorialId={"serviceSelectorHelp"} user={this.props.user}/>
                </div>
                <div className="row">
                    {filteredServicesData.map((item, index) => (
                        <div key={index} className="col-lg-4 col-md-6 mb-4 card-hover card-deck">
                            <div className="card h-100 animate__animated animate__bounceIn animate__faster"
                                onAnimationEnd={this.handleAnimationEnd}>
                                {/* <img src={item.image} className="card-img-top" alt={item.name} /> */}
                                <div className="card-body">
                                    <div className='row mb-3'>
                                        <div className='col-10'>
                                            {item.tags.map((tag, index) => (
                                                <a
                                                    href="#"
                                                    key={index}
                                                    className={`badge mx-1 card-hover rounded-pill ${tag.tag_color} ${selectedBadges.includes(tag.name) ? 'badge-selected' : ''}`}
                                                    onClick={() => this.handleBadgeClick(tag.name)}
                                                >
                                                    {tag.name}
                                                </a>
                                            ))}
                                        </div>
                                        <div className='col-2 d-flex justify-content-end'>
                                            {item.is_trending && <FcBullish />}
                                        </div>
                                    </div>
                                    <div style={{ cursor: 'pointer' }} onClick={() => this.props.selectService(item)}>
                                        <h5 className="card-title">{item.name}</h5>
                                        <img className="img-fluid" src={`${item.picture_link}`}></img>
                                        <p className="card-text">{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
