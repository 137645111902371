import React, { Component } from 'react'
import AOS from 'aos';
import Contact from './landing/Contact';
import Faq from './landing/Faq';
import Pricing from './landing/Pricing';
import Features from './landing/Features';
import Demos from './landing/Demos';
import { fetchFrontendLandingConfig } from '../services/settings.service';

export default class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreatorSelected: true,
      frontendConfig: {}
    }

    this.handleIsCreatorSelectedChange = this.handleIsCreatorSelectedChange.bind(this);
  }

  componentDidMount() {
    AOS.init();
    this.loadLandingConfig();
  }

  handleIsCreatorSelectedChange = (isCreatorSelected) => {
    this.setState({isCreatorSelected: isCreatorSelected });
  };

  handleFeaturesSelect = (isCreatorSelected) => {
    this.handleIsCreatorSelectedChange(isCreatorSelected);
    window.location.replace("/#features");
  }

  loadLandingConfig(){
    fetchFrontendLandingConfig().then((res) => {
      console.log(res.data.data)
      this.setState({
        frontendConfig: res.data.data
      })
    }).catch((err) => {
      console.error("Failed to fetch landing config", err);
    })
  }

  render() {
    return (
      <div className='landing-background px-1'>
        <section id='title' className='hero d-flex align-items-center vh-100 video-background'>
          <video autoPlay muted loop id="background-video">
            <source src="images/background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className='container video-content'>
            <div className='row'>
              <div className='col-lg-6 d-flex flex-column justify-content-center'>
                <h1 data-aos="fade-up" data-aos-delay="200" className='display-3'>Grow your business on TikTok</h1>
                <h5 data-aos="fade-up" data-aos-delay="400" className='display-5 mb-5'>
                  Analyse content, audience, trends and much more
                </h5>
                <div data-aos="fade-up" data-aos-delay="600">
                  <div className='text-center text-lg-start'>
                    <button onClick={() => this.handleFeaturesSelect(true)} className='btn btn-secondary card-hover mx-2 my-2'>For creators</button>
                    <button onClick={() => this.handleFeaturesSelect(false)} className='btn btn-secondary card-hover mx-2 my-2'>For advertisers</button>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-out" data-aos-delay="200" className='col-lg-6 hero-img mb-5'>
                <img src='images/welcome.jpg' className='img-fluid'></img>
              </div>
            </div>
          </div>
        </section>

        <main id="main">
          <section id="features" className='my-3'>
            <Features isCreatorSelected={this.state.isCreatorSelected} handleIsCreatorSelectedChange={this.handleIsCreatorSelectedChange} config={"features" in this.state.frontendConfig ? this.state.frontendConfig["features"] : null}/>
          </section>
          <br/><br/><br/> 

          <section id="demos" className='my-3'>
            <Demos config={"demos" in this.state.frontendConfig ? this.state.frontendConfig["demos"] : null}/>
          </section>
          <br/><br/><br/> 

          <section id="price" className='my-3'>
            <Pricing/>
          </section>
          <br/><br/><br/> 

          <section id="faq" className='my-5'>
            <Faq/>
          </section>

          <br/><br/><br/> 
          <section id="contact" className='my-5'>
            <Contact/>
          </section>
        </main>
      </div>
    )
  }
}
