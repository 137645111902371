import React, { Component } from 'react'
import PriceModal from './PriceModal';
import { renderFormElement } from '../utils/GenerativeForm';
import { fetchValidateServiceRun } from '../../services/services.service';
import { toast } from 'react-toastify';
import Switch from "react-switch";
import { withMobileState } from '../utils/ComponentHOCs';
import TutorialHelper from '../utils/TutorialHelper';

class ServiceConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFreeTier: false,
            showPreviewModal: false,
            formValid: false,
            errors: [],
            warnings: [],
            formValues: {},
            fields: []
        };

        this.handleSubmitModal = this.handleSubmitModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleListAdd = this.handleListAdd.bind(this);
        this.handleListDelete = this.handleListDelete.bind(this);
        console.log(this.props.selectedService)
    }

    componentDidMount() {
        const fields = this.setFieldsByTier(this.props.params);
        this.setDefaultValues(fields);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.params !== this.props.params || prevState.showFreeTier !== this.state.showFreeTier) {
            const fields = this.setFieldsByTier(this.props.params);
            this.setDefaultValues(fields);
        }
    }

    setFieldsByTier(fields){
        this.setState({
            fields: []
        })
        let filteredFields = []

        // paid Tier see everything
        if(!this.state.showFreeTier){
            filteredFields = JSON.parse(JSON.stringify(fields));
        } else {
            // show completly free services
            filteredFields = JSON.parse(JSON.stringify(fields)).filter(obj => obj.service_param_price === null);
            filteredFields = filteredFields.filter(obj => obj.paid_tier !== true);
            filteredFields = filteredFields.map(obj => {
                if(obj.service_param_price_unit !== null){
                    obj.max_value = Math.min(obj.service_param_price_unit["unit"] - 1, obj.max_value === undefined? 99999999 : obj.max_value);
                }
                if(obj.max_free_tier_value !== null){
                    obj.max_value = obj.max_free_tier_value;
                }
                return obj;
            });
        }
        return filteredFields;

    }

    setDefaultValues(fields) {
        const newFormValues = {};

        fields.forEach((obj) => {
            newFormValues[obj.name] = obj.default;
        });
        
        newFormValues["freeTier"] = this.state.showFreeTier;
        this.setState({
            formValues: newFormValues,
            fields: fields,
            errors: [],
            warnings: []
        });

        console.log(fields);
    }

    handleInputChange = (e) => {
        const { id, type, value, checked } = e.target;
        this.setState((prevState) => ({
            formValues: {
                ...prevState.formValues,
                [id]: type == "checkbox" ? checked : value,
            },
        }));
    };

    handleSliderChange = (e) => {
        const { id, value } = e.target;
        //document.getElementById(`${id}-value`).innerText = e.target.value;
        this.setState((prevState) => ({
            formValues: {
                ...prevState.formValues,
                [id]: value,
            },
        }));
    };

    handleListAdd = (name) => {
        const newValue = document.getElementById(`${name}`).value;

        this.setState((prevState) => ({
            formValues: {
                ...prevState.formValues,
                [name]: prevState.formValues[name].length === 0 ? newValue : prevState.formValues[name] + "," + newValue,
            },
        }));
        document.getElementById(`${name}`).value = "";
    }

    handleListDelete = (name, value) => {
        const postComma = this.state.formValues[name].includes(value + ",");
        const preComma = this.state.formValues[name].includes("," + value);
        const toReplace = postComma ? (value + ",") : (preComma ? ("," + value) : value);
        this.setState((prevState) => ({
            formValues: {
                ...prevState.formValues,
                [name]: prevState.formValues[name].replace(toReplace, ""),
            },
        }));
    }

    handleShowModal = () => {
        this.loadServiceValidation();
    };

    handleCloseModal = () => {
        this.setState({ showPreviewModal: false });
    };

    handleSubmitModal = (acceptedTerms, sendCompleteEmail) => {
        this.setState({ showPreviewModal: false });
        this.state.formValues["accept_terms"] = acceptedTerms;
        this.state.formValues["complete_email"] = sendCompleteEmail;
        this.props.handleNext(this.state.formValues);
    }

    handleFreeTierSwitch = () => {
        this.setState((prevState) => ({
            showFreeTier: !prevState.showFreeTier
        }));
      };

    loadServiceValidation() {
        fetchValidateServiceRun(this.props.selectedService.id, this.props.scrapes, this.state.formValues).then((res) => {
            if (res.data.data) {
                if (res.data.data.valid) {
                    this.setState({ showPreviewModal: true, errors: res.data.data.errors, warnings: res.data.data.warnings });
                } else {
                    this.setState({
                        errors: res.data.data.errors,
                        warnings: res.data.data.warnings,
                    });
                }
            } else {
                toast.error("Unable to validate service run, please try again later");
            }
        }).catch((err) => {
            console.log("Failed to load service validation", err);
        });
    }

    renderFormAlert(alertText, error = true) {
        return (<div className={"alert " + (error ? "alert-danger" : "alert-warning")}>{alertText}</div>);
    }

    render() {
        return (
            <div className="container">
                <div className='position-absolute top-0 end-0 tutorial-help-div'>
                        <TutorialHelper tutorialId={"serviceConfig-" + this.props.selectedService.id} user={this.props.user} />
                    </div>
                <div className="row d-flex my-1">
                    <div className='col'><h5>Service: {this.props.selectedService.name}</h5></div>
                    <div className="col d-flex justify-content-center pr-5">
                    <label className="form-check-label mx-2" htmlFor="freeTierSwitch">
                        With tokens 💎
                    </label>
                    <Switch
                        className="mx-3"
                        id="freeTierSwitch"
                        onColor="#86d3ff"
                        offColor="#ff7575"
                        onHandleColor="#2693e6"
                        offHandleColor="#e81717"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        checked={this.state.showFreeTier}
                        aria-label="swtich"
                        onChange={this.handleFreeTierSwitch}
                    />
                    <label className="form-check-label mx-2" htmlFor="freeTierSwitch">
                        Free tier
                    </label>
                    </div>
                    <div className={'col d-flex ' + (this.props.isMobile? "justify-content-between": "justify-content-end")}>
                        <button className="btn btn-outline-warning m-2" onClick={() => this.props.handleBack(null)}>Back</button>
                        <button className="btn btn-outline-success m-2" onClick={() => this.handleShowModal()}>Preview</button>
                    </div>
                </div>
                <div className='row'>
                    <div className='card'>
                        <div className='my-2'>
                            {this.state.errors.map((error) => this.renderFormAlert(error))}
                            {this.state.warnings.map((warning) => this.renderFormAlert(warning, false))}
                        </div>
                        <div>
                            {this.state.fields.map((field) => renderFormElement(field,  this.state.formValues, this.handleInputChange, this.handleSliderChange, this.handleListAdd, this.handleListDelete))}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                    {this.state.showPreviewModal && (
                        <div
                            className="modal show container"
                            id="PreviewPriceModal"
                            role="dialog"
                            tabIndex="-1"
                            style={{ display: "block" }}
                        >
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <PriceModal
                                        serviceId={this.props.selectedService.id}
                                        scrape_ids={this.props.scrapes}
                                        params={this.state.formValues}
                                        warnings={this.state.warnings}
                                        handleSubmit={this.handleSubmitModal}
                                        handleClose={this.handleCloseModal}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}


export default withMobileState(ServiceConfig);