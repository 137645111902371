import React, { Component } from "react";
import Loading from "../common/Loading";
import GeneralTable from "../common/GeneralTable";
import { fetchReportTabData } from "../services/stats.service";
import { fetchFullServiceResult } from "../services/services.service";
import {
  StatCard,
  TopVideosBarChart,
  SimpleBarChart,
  SimpleRadarChart,
  SimplePieChart,
  DocumentsContainer,
  ListContainer,
  StackedAreaChart,
  SimpleBushBarChart,
  TableContainer
} from "./report/ReportComponents";
import {
  BsArrowLeft,
  BsArrowRight,
  BsArrowDownShort,
  BsArrowUpShort,
} from "react-icons/bs";
import BaseComponent from "./report/ReportComponents";
import { convertToUtf8 } from "./utils/ScraperTableUtils";
import ReportHeader from "./report/ReportHeader";
import { baseColumnFormatter } from "./utils/ScraperTableUtils";
import Switch from "react-switch";

class Report extends Component {
  constructor() {
    super();
    this.state = {
      service: "",
      serviceResultId: -1,
      serviceResult: {},
      tabLoading: true,
      title: "",
      tabs: [],
      activateTab: {},
      tabBlocksData: [],
      currentResultIndex: 0,
      isPrivateReport: true,
      showTable: false,
      isMobile: window.innerWidth <= 768,
      wideView: false
    };

    this.componentMap = {
      StatCard,
      TopVideosBarChart,
      SimpleBarChart,
      SimpleRadarChart,
      SimplePieChart,
      DocumentsContainer,
      ListContainer,
      StackedAreaChart,
      SimpleBushBarChart,
      TableContainer
    };

    this.handleResultIndexJump = this.handleResultIndexJump.bind(this);
  }

  componentDidMount() {
    this.setState({
      title: this.props.reportData.title,
      tabs: this.props.reportData.tabs,
      tableColumns: this.props.tableData.columns,
      tableData: convertToUtf8(this.props.tableData.data),
      activateTab: this.props.reportData.tabs[0],
      service: this.props.reportData.service,
      serviceResultId: this.props.serviceResultId,
      currentResultIndex: 0,
      isPrivateReport: this.props.isPrivateReport,
      isMobile: window.innerWidth <= 768,
    });
    this.loadServiceResult(this.props.serviceResultId);
    this.loadTabData(
      this.props.reportData.service,
      this.props.serviceResultId,
      this.props.reportData.tabs[0].id,
      this.state.currentResultIndex
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.reportData &&
      prevProps.reportData !== this.props.reportData &&
      this.props.reportData.serviceResultId !== this.state.serviceResultId
    ) {
      this.setState({
        service: this.props.reportData.service,
        serviceResultId: this.props.serviceResultId,
        title: this.props.reportData.title,
        tabs: this.props.reportData.tabs,
        activateTab: this.props.reportData.tabs[0],
      });
      this.loadTabData(
        this.props.reportData.service,
        this.props.serviceResultId,
        this.props.reportData.tabs[0].id,
        this.state.currentResultIndex
      );
    }
    if (prevProps.serviceResultId !== this.props.serviceResultId) {
      this.loadServiceResult(this.props.serviceResultId);
    }
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  toggleWide = () => {
    this.setState((prevState) => ({
      wideView: !prevState.wideView,
    }));
  };

  

  formatColumns(columns, data) {
    let formatted = [];
    columns.forEach((element) => {
      if (element !== "id" && element !== "secUid" && !element.toLowerCase().includes("unnamed") && !data.every(obj => !obj.hasOwnProperty(element) || obj[element] === undefined || obj[element] === "")) {
        formatted.push({
          dataField: element,
          text: element,
          formatter: baseColumnFormatter,
        });
      }
    });
    return formatted;
  }

  handleTabChange(newTab) {
    this.setState({
      activateTab: newTab,
    });
    this.loadTabData(
      this.state.service,
      this.state.serviceResultId,
      newTab.id,
      this.state.currentResultIndex
    );
  }

  handleResultIndexChange(isNext = true) {
    const newIndex = this.state.currentResultIndex + (isNext ? 1 : -1);
    if (newIndex >= 0 && newIndex < this.state.tableData.length) {
      this.setState({
        currentResultIndex: newIndex,
      });
      this.loadTabData(
        this.state.service,
        this.state.serviceResultId,
        this.state.activateTab.id,
        newIndex
      );
    }
  }

  handleResultIndexJump(row, isSelect, rowIndex, e){
    const newIndex = this.state.tableData.findIndex(obj => obj.id === row.id);
    console.log("Jumping: " + newIndex);
    
    this.setState({
      currentResultIndex: newIndex,
    });
    this.loadTabData(
      this.state.service,
      this.state.serviceResultId,
      this.state.activateTab.id,
      newIndex
    );
  }

  loadServiceResult(serviceResultId) {
    fetchFullServiceResult(serviceResultId)
      .then((res) => {
        this.setState({
          serviceResult: res.data,
        });
      })
      .catch((err) => {
        console.error("Failed to load service result", err);
      });
  }

  loadTabData(serviceName, serviceResultId, activeTab, currentResultIndex) {
    this.setState({ tabLoading: true });
    fetchReportTabData(
      serviceName,
      serviceResultId,
      activeTab,
      currentResultIndex
    )
      .then((data) => {
        console.log(data.data.data);
        this.setState({ tabLoading: false });
        this.setState({ tabBlocksData: data.data.data });
      })
      .catch((err) => {
        this.setState({ tabLoading: false });
        console.error("Failed to load tab", err);
      });
  }

  getComponentView(i, component, componentData, blockData) {
    if (component && component.title && componentData) {
      if (this.componentMap[component.component_type] === undefined) {
        console.error(
          "Component view is missing for " + component.component_type
        );
        return "";
      } else if (
        componentData != null &&
        (componentData.length === undefined || componentData.length > 0)
      ) {
        const colCount = blockData.inline
          ? Math.floor(12 / blockData.components.length)
          : 12;
        return (
          <div className={`my-3 ${"col-12 col-lg-" + colCount}`}>
            <BaseComponent
              i={i}
              component={component}
              componentData={componentData}
              isMobile={this.state.isMobile}
            />
          </div>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getReportBlockView(i, blockData) {
    return (
      <div key={blockData.id}>
        <h5>{blockData.name}</h5>
        <hr />
        <div
          className={`row my-3 d-flex ${
            blockData.inline ? "justify-content-center" : ""
          }`}
        >
          {blockData.components.map((component) =>
            this.getComponentView(
              i,
              component,
              blockData.components_data[component.id],
              blockData
            )
          )}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.tabs.length === 0) {
      return (
        <div className="d-flex justify-content-center">
          <Loading type="rings" />
        </div>
      );
    } else {
      return (
        <div className={(this.state.wideView? "": "container") + " my-2"}>
          <div className="row">
            <div className="col text-left">
              <div>
              <h5>{this.state.title}</h5>
              <small>As of: {this.state.serviceResult.started_at !== undefined ? new Date(this.state.serviceResult.started_at).toLocaleString() : undefined}</small>
              </div>
            </div>
            <div className="col d-flex justify-content-end">
              <div className="d-flex flex-column">
                <small>Wide view</small>
                <Switch
                  onChange={this.toggleWide}
                  checked={this.state.wideView}
                  className="react-switch"
                />
              </div>
            </div>
          </div>
          <div className="card card-body my-3">
            <div>
              <button onClick={this.toggleExpand} className="btn btn-link">
                {!this.state.expanded && (
                  <span>
                    Expand table <BsArrowDownShort />
                  </span>
                )}
                {this.state.expanded && (
                  <span>
                    Close table <BsArrowUpShort />
                  </span>
                )}
              </button>
            </div>

            {this.state.expanded && (
              <GeneralTable
                id={"uniqueId"}
                data={this.state.tableData}
                columns={this.formatColumns(this.state.tableColumns, this.state.tableData)}
                selectCallback={(row, isSelect, rowIndex, e) => this.handleResultIndexJump(row, isSelect, rowIndex, e)}
              />
            )}
            <div>
              {this.state.tableData.length > 1 && 
              (<div className={"d-flex m-2 " + (this.state.currentResultIndex > 0 ? "justify-content-between": "justify-content-end")}>
                {this.state.currentResultIndex > 0 && (<button
                  onClick={() => this.handleResultIndexChange(false)}
                  className="btn btn-info card-hover"
                >
                  <BsArrowLeft /> Previous
                </button>)}
                {this.state.currentResultIndex < this.state.tableData.length -1 && (
                <button
                  onClick={() => this.handleResultIndexChange(true)}
                  className="btn btn-primary card-hover"
                >
                  Next <BsArrowRight />
                </button>
                )}
              </div>
              )}
            </div>

            <div>
              <ReportHeader
                header={this.props.reportData.header}
                resultIndex={this.state.currentResultIndex}
                data={this.state.tableData}
              />
            </div>

            <div></div>
            <ul className="my-3 nav nav-tabs nav-dark">
              {this.state.tabs.map((tab) => {
                return (
                  <li className={`nav-item`}>
                    <button
                      className={`btn btn-info nav-link  ${
                        this.state.activateTab.name === tab.name ? "active" : ""
                      }`}
                      onClick={() => this.handleTabChange(tab)}
                    >
                      {tab.name}
                    </button>
                  </li>
                );
              })}
            </ul>

            

            {this.state.tabLoading ? (
              <div className="d-flex justify-content-center">
                <Loading type="rings" />
              </div>
            ) : (
              <div>
                {" "}
                {this.state.tabBlocksData.map((blockData, i) =>
                  this.getReportBlockView(i, blockData)
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
export default Report;
