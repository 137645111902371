import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
    BsFillXCircleFill
  } from "react-icons/bs";

import Loading from "../../common/Loading";


class AlertList extends Component {

  queryFormatter(col, row){
    return <span>{row['query_field']} - {row['query']}</span>
  }

  dateTimeFormatter(cell, row) {
    const datetime = new Date(cell);
    return <span>{datetime.toLocaleString()}</span>;
  }

  actionsFormatter(cell, row, rowIndex, extraData) {
    if (cell) {
      return (
        <span>
          <button key={row["id"]} data-id={row["id"]} className="btn btn-danger btn-sm mx-1 my-1"  onClick={() => extraData(row['id'])} >
            <BsFillXCircleFill/>
          </button>
        </span>
      );
    } else {
      return <span></span>
    }
  }

  render() {
    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
      },
      {
        dataField: "type",
        text: "Type",
        sort: true,
      },
      {
        dataField: "email_recipient",
        text: "Recipient",
      },
      {
        dataField: "query_field",
        text: "Query",
        formatter: this.queryFormatter
      },
      {
        dataField: "last_trigger",
        text: "Last trigger",
        sort: true,
      },
      {
        dataField: "trigger_count",
        text: "Count",
        sort: true,
      },
      {
        dataField: "email_message",
        text: "Message",
      },
      {
        dataField: "id",
        text: "Actions",
        formatter: this.actionsFormatter,
        formatExtraData: this.props.removeCallback
      },
    ];
    if (this.props.isLoading) {
      return (
        <div className="card card-body">
          <Loading type="rings"></Loading>
        </div>
      );
    } else {
      return (
        <div className="card card-body">
          <h5>Alert List</h5>
          <BootstrapTable
            keyField="id"
            data={this.props.alerts}
            columns={columns}
            pagination={paginationFactory()}
            striped
            hover
            condensed
            noDataIndication="It's empty here"
            wrapperClasses="table-responsive"
          />
        </div>
      );
    }
  }
}

export default AlertList;
