import React, { Component } from 'react';
import { toast } from "react-toastify";
import { fetchConfigs, setConfig } from '../services/settings.service';

class Settings extends Component {

    constructor() {
        super();
        this.state = {
            scraper: null,
            isLoading: true,
            configData: {},
        };
    }

    componentDidMount() {
        this.setState({
            scraper: this.props.selectedScraper,
        });
        this.loadConfigs(this.props.selectedScraper);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.selectedScraper !== this.props.selectedScraper &&
            this.props.selectedScraper !== this.state.scraper
        ) {
            this.setState({
                scraper: this.props.selectedScraper,
            });
            this.loadConfigs(this.props.selectedScraper);
        }
    }

    loadConfigs(scraper) {
        fetchConfigs(scraper)
            .then((data) => {
                this.setState({ configData: data.data.data, isLoading: false });
            })
            .catch((error) => {
                console.error('Error fetching configuration data', error);
                this.setState({ isLoading: false });
            });
    }

    handleInputChange = (key, event) => {
        const { value } = event.target;
        const { configData } = this.state;

        this.setState({
            configData: {
                ...configData,
                [key]: value,
            },
        });
    }

    handleUpdateClick = (key) => {
        const { configData } = this.state;

        setConfig(this.state.scraper, key, configData[key])
            .then((response) => {
                toast.success(`Successfully updated ${key} to ${configData[key]}`);
            })
            .catch((error) => {
                toast.error(`Error updating ${key}:`, error);
            });
    };

    render() {
        const { configData } = this.state;

        return (
            <div className="row my-3">
                <div className="col-lg-6">
                    <div className='card card-body'>
                        <h2>Configuration Editor</h2>
                        <div className=''>
                            {Object.keys(configData).map((key) => (
                                <div className="my-3" key={key}>
                                    <label className='mx-3 strong'>{key}</label>
                                    <div className='d-flex'>
                                        <input
                                            type="text"
                                            className="form-control mx-3"
                                            value={configData[key]}
                                            onChange={(event) => this.handleInputChange(key, event)}
                                        />
                                        <button className="btn btn-info" onClick={() => this.handleUpdateClick(key)}>Update</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;