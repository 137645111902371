import React, { Component } from 'react'

export default class TutorialHelper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showForm: 0, // 0 no, 1 yes
        };
        console.log(props.user);
    }

    toggleForm = () => {
        if (this.state.showForm === 1) {
            this.setState({ showForm: 0 });
        } else {
            this.setState({ showForm: 1 });
        }
    };

    disableTutorial = () => {
        this.setState({ showForm: 0 });
    }

    getTutorialContent(tutorialId) {
        if (tutorialId === 'serviceHelp') {
            return (<div>Service Help Tutorial Content</div>);
        } else if (tutorialId === 'progressHelp') {
            return (<div>Progress Help Tutorial Content</div>);
        } else if (tutorialId === 'serviceSelectorHelp') {
            return (<div><h5>Service selector</h5><p>Choose which service you would like to use. Each service can download (scrape) different data. </p></div>);
        } else if(tutorialId == 'serviceProgressNotRunning') {
            return (<div><h5>Service progress</h5><p>No service running currently. You can start one in the above service selector</p></div>);
        } else if (tutorialId == 'serviceProgressRunning') {
            return (<div><h5>Service progress</h5><p>A service is currently running. You can see the progress here and also open the logs.</p><p>This usually takes some time, you can close the page if you want, and the service will continue.</p><p>If the service gets stuck and you dont see any progress for long time, please contact support.</p></div>);
        } else if (tutorialId == 'serviceHistory') {
            return (<div><h5>Service history</h5><p>Your previous service results. If you want to see more details, go to the data tab.</p></div>);
        } else if (tutorialId == 'serviceConfig-comments_scrape') {
            return (<div><h5>Comments scrape</h5><p>This service downloads the comments under a video and creates an excel and analytics for you.</p><p>The 2 required field are the video url, which you can copy if you click share in TikTok, and the number of comments you want to scrape</p><p>If you want to use for free, select free tier. If you need to scrape more data click your username in the header and purchase some tokens</p><p>Hint: if you click preview it will tell you the max amount of token you will need</p></div>);
        } else if(tutorialId == 'serviceConfig-follower_scrape') {
            return (<div><h5>Follower scrape</h5><p>You can download anybody's follower's here, just enter his TikTok username for example datafox_dev.</p><p>In the result excel there will be bunch of data, so for example if you'r interested in the Instagram followers check the box</p><p>If you want to use for free, select free tier. If you need to scrape more data click your username in the header and purchase some tokens</p><p>Hint: if you click preview it will tell you the max amount of token you will need</p></div>);
        } else if(tutorialId == 'serviceConfig-videos_scrape') {
            return (<div><h5>Videos scrape</h5><p>You can export videos from any user by entering the TikTok username and the amount of video you want</p><p>The output will contain lot of infromations, such as statstics, musics, hashtags, tags, filters, video settings, ads and many more</p><p>If you want to use for free, select free tier. If you need to scrape more data click your username in the header and purchase some tokens</p><p>Hint: if you click preview it will tell you the max amount of token you will need</p></div>);
        } else if(tutorialId == 'serviceConfig-user_scrape') {
            return (<div><h5>User scrape</h5><p>This service joins multiple separate services like follower, video and comments scrape</p><p>If you want a comprehensive report about somebody fill out the form. If you dont need something, for example comments - leave it blank</p><p>The generation might take some time, please feel free to close the window and make sure you enable email notification</p><p>If you want to use for free, select free tier. If you need to scrape more data click your username in the header and purchase some tokens</p><p>Hint: if you click preview it will tell you the max amount of token you will need</p></div>);
        } else if(tutorialId == 'serviceConfig-hashtag_search_service') {
            return (<div><h5>Advanced search service</h5><p>This service search for users who has at least 1 video with one of the requested hashtags. This service does provide the functionality to filter by region and time period, but please note that the scraped users might not be 100% relevant</p><p></p></div>);
        } else if(tutorialId == 'serviceConfig-basic_search_service') {
            return (<div><h5>Basic search service</h5><p>This is a simple search service, where you can just enter any keyword to get relevant influencers for that search term. It does not let you filter by region, but the search results are more relevant</p><p></p></div>);
        } else if(tutorialId == 'scrapeHistory') {
            return (<div><h5>Scrape history</h5><p>See all previous scrape results here</p><p><strong>Report:</strong> Open report on new tab <br/><strong>Details:</strong> Open summary of the scrape where you can download results<br/><strong>Download:</strong> Downloads short summary csv <br/><strong>Delete:</strong> it's permanent, can't be undone</p><p>Note: You can download the summary excels of multiple scrapes together</p></div>);
        } else if(tutorialId == 'scrapeDetails') {
            return (<div><h5>Scrape details</h5><p>Summary of a scrape You can download scraped results here, <strong>prefer to download EXCEL</strong>because its cleaned data</p></div>);
        } else if(tutorialId == 'publishedReport') {
            return (<div><h5>Published reports</h5><p>Share scraped data with anyone. The generated links are public, and anyone with the link can see the report, and download every resource for that particar scrape. It's only read permission, people with the link will not be able to delete or modify anything with the scrape result</p></div>);
        } else {
            return (<div>No tutorial available here</div>);
        }
    }

    render() {
        const { tutorialId, user } = this.props;

        if (user && user.user_profile?.tutorial_enabled) {
            return (
                <div className='d-flex justify-content-end m-1'>
                    {this.state.showForm !== 1 && (
                        <button
                            className="btn btn-primary rounded-circle"
                            onClick={this.toggleForm}>
                            ?
                        </button>
                    )}
                    {this.state.showForm !== 0 && (
                        <div className='card p-1 small w-50'>
                            <div className='p-3'>
                                {this.getTutorialContent(tutorialId)}
                            </div>
                            <div className='card-footer'>
                                <div className='mb-1'>
                                    <button className="btn btn-success btn-sm m-1" onClick={this.toggleForm}>Close</button>
                                </div>
                                <small>You can turn helps off in the user settings</small>
                                
                            </div>
                            
                        </div>
                    )}
                </div>
            );
        } else {
            return <div></div>
        }
    }
}
