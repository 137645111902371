import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Loading from "./Loading";

class PrivateRoute extends Component {
  render() {
    if (this.props.isLoading) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center m-t">
          <Loading type="rings"></Loading>
        </div>
      );
    } else if (!this.props.isAuthenticated) {
      return <Navigate to="/login" />;
    } else {
      return this.props.children;
    }
  }
}

export default PrivateRoute;
