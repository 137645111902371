import "./App.css";
import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

import PrivateRoute from "./common/PrivateRoute";
import Header from "./compontent/layouts/Header";
import Footer from "./compontent/layouts/Footer";
import Login from "./compontent/accounts/Login";
import Index from "./compontent/Index";
import User from "./compontent/User";
import NotFound from "./compontent/layouts/NotFound";
import Landing from "./compontent/Landing";
import Registration from "./compontent/accounts/Registration";
import RegistrationReSend from "./compontent/accounts/RegistrationReSend";
import Activate from "./compontent/accounts/Activate";
import PrivateReport from "./compontent/PrivateReport";
import Privacy from "./compontent/layouts/Privacy";

import { fetchScrapers } from "./services/crawler.service";
import {
  fetchIsAuthenticated,
  fetchUserData,
  login,
  logout,
  authenticateWithGoogle
} from "./services/auth.service";
import PublicStatRoute from "./publish/PublicStatRoute";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrapers: [],
      isAuthenticated: false,
      isLoading: true,
      user: null,
      selectedScraper: "Loading",
    };

    this.selectScraperCallback = this.selectScraperCallback.bind(this);
    this.loginCallback = this.loginCallback.bind(this);
    this.logoutCallback = this.logoutCallback.bind(this);
    this.updateUserCallback = this.updateUserCallback.bind(this);
    this.googleAuthCallback = this.googleAuthCallback.bind(this);
  }

  componentDidMount() {
    this.checkUserInfo();
  }

  async checkUserInfo() {
    let isAuthenticated = await fetchIsAuthenticated();
    if (isAuthenticated) {
      Promise.all([fetchUserData(), fetchScrapers()])
        .then((responses) => {
          const [userData, scraperData] = responses;
          this.setState({
            isAuthenticated: true,
            user: userData.data,
            scrapers: scraperData.data.map((obj) => obj.name),
            selectedScraper: scraperData.data[0].name,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isAuthenticated: false,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isAuthenticated: false,
        isLoading: false,
      });
    }
  }

  selectScraperCallback(scraper) {
    this.setState({
      selectedScraper: scraper,
    });
  }

  updateUserCallback() {
    fetchUserData()
      .then((userData) => {
        this.setState({
          user: userData.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loginCallback(username, password) {
    login(username, password)
      .then((res) => {
        if (!res.data["error"]) {
          toast.success("Welcome!");
          this.checkUserInfo().then((resp) => {
            window.location.replace("/dashboard");
          });
        } else {
          toast.error("Login failed: " + res.data["error"]);
          console.error("Login failed: " + res.data["error"]);
        }
      })
      .catch((err) => {
        toast.error("Failed to log in: " + err);
        console.error("Failed to log in: " + err);
      });
  }

  googleAuthCallback() {
    authenticateWithGoogle()
      .then((res) => {
        if (!res.data["error"]) {
          toast.success("Welcome!");
          this.checkUserInfo().then((resp) => {
            window.location.replace("/dashboard");
          });
        } else {
          toast.error("Login failed: " + res.data["error"]);
          console.error("Login failed: " + res.data["error"]);
        }
      })
      .catch((err) => {
        toast.error("Failed to log in: " + err);
        console.error("Failed to log in: " + err);
      });
  }

  logoutCallback() {
    logout()
      .then((res) => {
        toast.info("User logged out");
        this.checkUserInfo();
      })
      .catch((err) => {
        toast.error("Logout failed: " + err);
        console.error("Failed to log in: " + err);
      });
  }

  render() {
    return (
      <Router>
        <Fragment>
          <Header
            user={this.state.user}
            isAuthenticated={this.state.isAuthenticated}
            scrapers={this.state.scrapers}
            selectedScraper={this.state.selectedScraper}
            selectScraperCallback={this.selectScraperCallback}
            logout={this.logoutCallback}
          />
          <ToastContainer theme="dark" pauseOnFocusLoss={false}/>
          <div className="main-container">
            <Routes>
              <Route
                exact
                path="/login"
                element={<Login login={this.loginCallback} googleAuth={this.googleAuthCallback}/>}
              ></Route>
              <Route exact path="/register" element={<Registration />}></Route>
              <Route
                exact
                path="/resend"
                element={<RegistrationReSend />}
              ></Route>
              <Route exact path="/activate" element={<Activate />}></Route>

              <Route
                exact
                path="/report/:scrapeId"
                element={
                  <PrivateRoute
                    isLoading={this.state.isLoading}
                    isAuthenticated={this.state.isAuthenticated}
                  >
                    <PrivateReport user={this.state.user} />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                exact
                path="/dashboard"
                element={
                  <PrivateRoute
                    isLoading={this.state.isLoading}
                    isAuthenticated={this.state.isAuthenticated}
                  >
                    <Index
                      selectedScraper={this.state.selectedScraper}
                      user={this.state.user}
                      updateUserCallback={this.updateUserCallback}
                    />
                  </PrivateRoute>
                }
              ></Route>
              <Route exact path="/" element={<Landing />}></Route>

              <Route
                exact
                path="/user"
                element={
                  <PrivateRoute
                    isLoading={this.state.isLoading}
                    isAuthenticated={this.state.isAuthenticated}
                  >
                    <User
                      user={this.state.user}
                      updateUserCallback={this.updateUserCallback}
                    />
                  </PrivateRoute>
                }
              ></Route>

              {/* <Route
                exact
                path="/"
                element={<Landing />}
              ></Route> */}

              <Route
                exact
                path="/public/report/:id"
                element={<PublicStatRoute />}
              ></Route>

              <Route
                exact
                path="/privacy"
                element={<Privacy />}
              ></Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </Fragment>
      </Router>
    );
  }
}
export default App;
