import React, { Component } from "react";
import Switch from "react-switch";
import Zoom from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreatorSelected: true,
      creatorFeatures: [],
      advertiserFeatures: [],
    };
  }

  handleOptionChange = () => {
    this.props.handleIsCreatorSelectedChange(!this.state.isCreatorSelected);
  };

  componentDidMount() {
    this.setData(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setData(this.props);
    }
  }

  setData(props) {
    console.log(props.config === null ? [] : props.config["creatorFeatures"]);
    this.setState({
      isCreatorSelected: props.isCreatorSelected,
      creatorFeatures:
        props.config === null ? [] : props.config["creatorFeatures"],
      advertiserFeatures:
        props.config === null ? [] : props.config["advertiserFeatures"],
    });
  }

  render() {
    const { isCreatorSelected, creatorFeatures, advertiserFeatures } =
      this.state;
    const selectedFeatures = isCreatorSelected
      ? creatorFeatures
      : advertiserFeatures;

    return (
      <div data-aos="fade-up" className="container mt-4">
        <div className="row">
          <div className="col ">
            <h2 className="text-center display-3">Features</h2>
            <div className="mb-3 d-flex justify-content-center pr-5">
              <label className="form-check-label mx-2" htmlFor="creatorSwitch">
                Advertiser Features
              </label>
              <Switch
                className="mx-3"
                id="creatorSwitch"
                onColor="#86d3ff"
                offColor="#ff7575"
                onHandleColor="#2693e6"
                offHandleColor="#e81717"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                checked={isCreatorSelected}
                aria-label="swtich"
                onChange={this.handleOptionChange}
              />
              <label className="form-check-label mx-2" htmlFor="creatorSwitch">
                Creator Features
              </label>
            </div>
          </div>
        </div>

        {selectedFeatures.map((feature, index) => (
          <div className="row my-5" key={index}>
            <div
              data-aos="fade-up"
              data-aos-delay={300}
              className={`mt-5 col-md-5 ${index % 2 === 0 ? "order-md-1" : ""}`}
            >
              <div>
                <h1>{feature.title}</h1>
                <h4>{feature.sub_title}</h4>
                <p>{feature.description}</p>
                <ul>
                  {feature.points.map((point, i) => (
                    <li
                      data-aos="fade-up"
                      className="text-sm"
                      data-aos-delay={300 + i * 50}
                      key={i}
                    >
                      {point}
                    </li>
                  ))}
                </ul>
                {feature.demos.map((demo, demoIndex) => (
                  <a
                    key={demoIndex}
                    href={demo}
                    target="_blank"
                    className={
                      "btn rounded-pill mx-2 my-2 " +
                      (this.state.isCreatorSelected
                        ? "btn-primary"
                        : "btn-danger")
                    }
                  >
                    Demo {demoIndex}
                  </a>
                ))}
              </div>
            </div>
            <div
              className={`col-md-5 mt-5 ${index % 2 !== 0 ? "order-md-1" : ""}`}
            >
              <div
                id={`carouselExampleControls${index}`}
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {feature.images.map((image, imgIndex) => (
                    <div
                      className={`carousel-item ${
                        imgIndex === 0 ? "active" : ""
                      }`}
                      key={imgIndex}
                    >
                      <div
                        data-aos="zoom-out"
                        data-aos-delay={300}
                        className="d-flex justify-content-center align-items-center h-100"
                      >
                        <Zoom>
                          <img className="w-100" src={image} />{" "}
                        </Zoom>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev text-white"
                  type="button"
                  data-bs-target={`#carouselExampleControls${index}`}
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden text-white">Previous</span>
                </button>
                <button
                  className="carousel-control-next text-white"
                  type="button"
                  data-bs-target={`#carouselExampleControls${index}`}
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Features;
