import React, { Component } from "react";
import { toast } from "react-toastify";
import { Gif } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { fetchFrontendPaymentConfig } from "../../services/settings.service";
import { openCheckoutSession } from "../../services/user.service";

class Payments extends Component {
  state = {
    isLoading: true,
    payments: [],
  };

  constructor(props) {
    super(props);
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  componentDidMount() {
    console.log(this.props.user)

    fetchFrontendPaymentConfig()
      .then((res) => {
        console.log(res.data.data);
        this.setState({
          isLoading: false,
          payments: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error("Failed to fetch payment configs", err);
      });

    const CustomToast = ({ message, imgSrc }) => (
      <div className="text-center">
        <p>{message}</p>
        <div className="d-flex justify-content-center">
          <Gif gif={imgSrc} width={100} height={100} />
        </div>
      </div>
    );

    const query = new URLSearchParams(window.location.search);
    const gf = new GiphyFetch("acUuNytwKXmLmo8juAh8L6AmU23GQGL7");
    const callbackFunction = this.props.updateUserCallback();
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    if (query.get("success")) {
      gf.search("happy", { limit: 1, offset: this.getRandomInt(0, 25) }).then(
        (fetchGif) => {
          toast.success(
            <CustomToast
              message="Payment successful, you will see the new tokens shortly"
              imgSrc={fetchGif.data[0]}
            />
          );
          setTimeout(function () {
            callbackFunction();
          }, 5000);
        }
      );
    }

    if (query.get("canceled")) {
      toast.warning("Payment cancelled");
    }
  }

  openStripePayment(paymentId) {
    openCheckoutSession(paymentId)
      .then((res) => {
        window.open(res.data.response);
      })
      .catch((err) => {
        console.error("Failed to open checkout session", err);
      });
  }

  render() {
    const { isLoading, payments } = this.state;

    return (
      <div className="card p-3">
        <h4>Buy tokens</h4>
        <div className="row d-flex justify-content-center mt-5">
          {payments.map((payment, index) => (
            <div
              className="card product col-lg-3 col-12 pt-3 m-3 card-hover"
              key={index}
            >
              <div className="text-center">
                <img
                  className="img-fluid w-25"
                  src={payment.img_src}
                  alt="Product cover"
                />
                <div className="description">
                  <h3>{payment.title}</h3>
                  <h5 className="payment-price mb-2">{payment.price}</h5>
                </div>
                <div className="">
                  <div className="row">
                    <button
                      className="btn btn-info btn-block"
                      onClick={() => this.openStripePayment(payment.product_id)}
                    >
                      Purchase
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <hr className="mt-3"/>
          <div>If you have any custom request please contact use on <a href="mailto:info@datafox.app">info@datafox.app</a></div>
        </div>
      </div>
    );
  }
}

export default Payments;
