import React, { Component } from 'react'
import Zoom from 'react-medium-image-zoom'

import 'react-medium-image-zoom/dist/styles.css'


export default class Demos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            demos: []
        };
    }

    handleClick = (link) => {
        window.open(link, '_blank');
    };

    componentDidMount() {
        this.setData(this.props);
      }
    
      componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
          this.setData(this.props);
        }
      }
    
      setData(props){
        this.setState({
          demos: props.config === null ? [] : props.config
        })
      }
    

    render() {
        return (
            <div data-aos="fade-up" data-aos-delay="500" className='container'>
                <h2 className='d-flex justify-content-center mb-5 display-3'>Check our demos</h2>
                <div className='row gy-4 d-flex justify-content-between'>
                    {this.state.demos.map((demo, index) => (
                        <div key={index} className='col-md-4 col-sm-6'>
                            <div data-aos="flip-up" data-aos-delay={200 + index * 200} className="card">
                            <img
                                    src={demo.imagePath}
                                    alt={demo.shortName}
                                    className='card-img-top img-fluid'
                                    
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{demo.shortName}</h5>
                                    <button className="btn btn-primary" onClick={() => this.handleClick(demo.link)}>Go to Demo</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
