export function renderFormElement(field, formData, handleInputChange, handleSliderChange, handleListAdd, handleListDelete) {
    const {
        name,
        default: defaultValue,
        input,
        type,
        options,
        min_value,
        max_value,
        hint,
    } = field;

    const value = formData[name];
    if (input === "INPUT") {
        return (
            <div key={name} className={(type === "checkbox" ? "form-check" : "form-group") + ' my-3'}>
                <label htmlFor={name} className={type === "checkbox" ? "form-check-label" : ""}>
                    <strong>{name}</strong>
                </label>
                <input
                    type={type}
                    className={type === "checkbox" ? "form-check-input" : "form-control"}
                    id={name}
                    onChange={handleInputChange}
                    value={value}
                    defaultValue={defaultValue}
                    checked={type == "checkbox" && defaultValue == "True" ? 'checked': undefined}
                />
                <div>
                    <small className="form-text text-muted">{hint}</small>
                    <small id='price' className="form-text">
                        {field.service_param_price ? " (" + field.service_param_price.token_per_unit + " token per " + field.service_param_price.unit + " item in " + field.service_param_price.source_file_for_total + ")": field.service_param_price_unit ? " (" + field.service_param_price_unit.token_per_unit + " token per " + field.service_param_price_unit.unit + ")": ""}
                    </small>
                </div>
            </div>
        );
    }

    if (input === "SELECT") {
        const optionList = options.split(",");
        return (
            <div key={name} className="form-group my-3">
                <label htmlFor={name}>
                    <strong>{name}</strong>
                </label>
                <select
                    className="form-control"
                    id={name}
                    value={value}
                    onChange={handleInputChange}
                    defaultValue={defaultValue}
                >
                    {optionList.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <small className="form-text text-muted">{hint}</small>
                <small id='price' className="form-text">
                        {field.service_param_price ? " (" + field.service_param_price.token_per_unit + " token per " + field.service_param_price.unit + " item in " + field.service_param_price.source_file_for_total + ")": field.service_param_price_unit ? " (" + field.service_param_price_unit.token_per_unit + " token per " + field.service_param_price_unit.unit + ")": ""}
                    </small>
            </div>
        );
    }

    if (input === "SLIDER") {
        return (
            <div key={name} className="form-group my-3">
                <label htmlFor={name}>
                    <strong>{name}</strong>
                </label>
                <br />
                <input
                    type="range"
                    className="form-range"
                    id={name}
                    value={value}
                    defaultValue={defaultValue}
                    min={min_value}
                    max={max_value}
                    onChange={handleSliderChange}
                />
                <input
                    type={type}
                    className="form-control"
                    id={name}
                    onChange={handleInputChange}
                    value={value}
                    min={min_value}
                    max={max_value}
                    defaultValue={defaultValue}
                />
                <span id={`${name}-value`} className="slider-value mx-3 my-3">
                    
                </span>
                <br />
                <small className="form-text text-muted">{hint}</small>
                <small id='price' className="form-text">
                        {field.service_param_price ? " (" + field.service_param_price.token_per_unit + " token per " + field.service_param_price.unit + " item in " + field.service_param_price.source_file_for_total + ")": field.service_param_price_unit ? " (" + field.service_param_price_unit.token_per_unit + " token per " + field.service_param_price_unit.unit + ")": ""}
                    </small>
            </div>
        );
    }

    if (input === "LIST") {
        return (
            <div key={name} className={"form-group"}>
                <label htmlFor={name}>
                    <strong>{name}</strong>
                </label>
                <div class="input-group">
                    
                <input
                    type={type}
                    className={"form-control"}
                    id={name}
                    defaultValue={defaultValue}
                />
                {(!value || !max_value || value.split(",").length < max_value) && (
                    <button className="btn btn-info" onClick={() => handleListAdd(name)}>Add</button>
                )}
                </div>
                <div className="m-2 input-group-append">
                {value && value.split(",").map((item, index) => (
                    <span key={index} className="m-1">
                        <span className="p-2 border rounded">
                            <span>{item}</span>
                            <button className="" onClick={()=> handleListDelete(name, item)}>x</button>
                        </span>
                    </span>
                    ))}
                </div>
                    
                <div>
                    <small className="form-text text-muted">{hint}</small>
                    <small id='price' className="form-text">
                        {field.service_param_price ? " (" + field.service_param_price.token_per_unit + " token per " + field.service_param_price.unit + " item in " + field.service_param_price.source_file_for_total + ")": field.service_param_price_unit ? " (" + field.service_param_price_unit.token_per_unit + " token per " + field.service_param_price_unit.unit + ")": ""}
                    </small>
                </div>
            </div>
        );
    }

    return null; // Handle unsupported input type or invalid input
}