import React, { Component } from "react";
import Loading from "../../common/Loading";

class AlertCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {},
    };
  }

  componentDidMount() {
    this.setDefaultValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.scrape != this.props.scrape) {
      this.setDefaultValues();
    }
  }

  setDefaultValues() {
    this.setState({
      formValues: {
        scraper: this.props.scrape,
        type: "finish",
        email_recipient: "",
        email_message: "New alert from DataFox scraper: <strong>{{ name }}</strong>",
        query_field: "",
        query: "",
      },
    });
  }

  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [id]: value,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      this.props.createAlertCallback(this.state.formValues);
    }
    else{
        console.log("Not valid")
    }
  };

  render() {
    if (this.props.isLoading) {
      return (
        <div className="card card-body">
          <Loading type="rings"></Loading>
        </div>
      );
    } else {
      return (
        <div className="card card-body">
          <h5>Create alert</h5>
          <form onSubmit={this.handleSubmit}>
            <div key="type" className="form-group my-3">
              <label htmlFor="type">
                <strong>Alert Type</strong>
              </label>
              <select
                className="form-control"
                id="type"
                defaultValue="finish"
                onChange={this.handleInputChange}
              >
                <option key="finish" value="finish">
                  On Finish
                </option>
                <option key="error" value="error">
                  On Finish with error
                </option>
                <option key="filter" value="filter">
                  On Finish with filter
                </option>
              </select>
              <small className="form-text text-muted">
                Choose the type of the alert trigger
              </small>
            </div>
            <div key="email_recipient" className="form-group mb-3">
              <label htmlFor="email_recipient">
                <strong>Recipient</strong>
              </label>
              <input
                type="email"
                className="form-control"
                id="email_recipient"
                required
                onChange={this.handleInputChange}
              />
            </div>
            <div key="email_message" className="form-group mb-3">
              <label htmlFor="email_message">
                <strong>Message</strong>
              </label>
              <textarea
                type="input"
                className="form-control"
                id="email_message"
                rows="4"
                onChange={this.handleInputChange}
                defaultValue={"New alert from DataFox scraper: <strong>{{ name }}</strong>"}
              />
              <small className="form-text text-muted">
                {"Customized email_message for the alert. You can include the scrape result like: {result}"}
              </small>
            </div>
            <div key="query_field" className="form-group mb-3">
              <label htmlFor="query_field">
                <strong>Field + query</strong>
              </label>
              <div className="input-group">
                <input
                  type="input"
                  className="form-control mx-2"
                  id="query_field"
                  placeholder="Field name to apply the query"
                  onChange={this.handleInputChange}
                />
                <input
                  type="input"
                  className="form-control mx-2"
                  id="query"
                  placeholder="Query regexp"
                  onChange={this.handleInputChange}
                />
              </div>
              <small className="form-text text-muted">
                Fill only if you select the type with filter
              </small>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-success"
              >Create</button>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default AlertCreate;
