import React, { Component } from 'react'

export default class Faq extends Component {


    constructor(props) {
        super(props);
        this.state = {
            faqList: [
                { id: 1, question: 'How can I use the service?', answer: 'Simply click login in the header. Log in if you already have account or register a new one. You only need an email address that all. 🤯' },
                { id: 2, question: 'Do I need to credit card?', answer: 'NO! You can use the services completly free, without any hidden fee or credit card. You only need to pay if you want to put some credits to your account 😌' },
                { id: 3, question: 'What can I use for free?', answer: 'You can try every service completly free of charge. You only need to purchase some tokens if you want to do more than what the free tier allows 😎' },
                { id: 4, question: 'How can I get free credit?', answer: 'If your a creator please feel free to contact us for some credit, we are open to all types of cooperation 😛' },
                { id: 5, question: 'I have a problem with login, what can I do?', answer: 'Please contact us on email and we will act ASAP 👀: info@datafox.app' },
                { id: 6, question: 'I have a problem with a service, what can I do?', answer: 'Please contact us on email and we will act ASAP 👀: info@datafox.app' },

                // Add more questions and answers here as needed
            ],
            activeId: null, // Track which div is active (expanded)
        };
    }

    toggleCollapse = (id) => {
        this.setState({ activeId: id === this.state.activeId ? null : id });
    };


    render() {

        const { faqList, activeId } = this.state;

        return (
            <div data-aos="fade-up" className='container'>
                <h2 className='d-flex justify-content-center mb-5 display-3'>FAQ<span className='ml-5'>🤷‍♂️</span></h2>
                <div className='row gy-4 d-flex justify-content-between'>
                    <div className='col-lg-6'>
                        {faqList.map((faq) => (
                            <div key={faq.id}>
                                <div
                                    data-aos="fade-up" data-aos-delay={faq.id * 100}
                                    className="card mb-2"
                                    onClick={() => this.toggleCollapse(faq.id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="card-header ">{faq.question}</div>
                                    {activeId === faq.id && (
                                        <div className='animate__animated animate__bounceIn'>
                                            <div className="card-body">
                                                {faq.answer}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        )
    }
}
