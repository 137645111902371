import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { BsFillPersonFill, BsCurrencyDollar } from "react-icons/bs";


export class Header extends Component {

  state = {
    collapsed: true
  }

  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  render() {
    const authNavBar = (
      <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
        <select value={this.props.selectedScraper}
          onChange={e => this.props.selectScraperCallback(e.target.value)}
          className='form-inline form-select'>
          {
            this.props.scrapers.map((scraper) =>
              <option key={scraper}>{scraper}</option>)
          }
        </select>
      </ul>
    )

    const guestNavBar = (
      <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
      </ul>
    )

    const authLinks = (
      <ul className="navbar-nav ms-auto mt-2 mt-lg-0">

        <li className="nav-item d-flex align-items-center justify-content-center mx-1">
        <span className="navbar-text mr-3">
            <strong><Link to="/dashboard" className="">Dashboard</Link></strong>
          </span>
          <span className='mx-2'> | </span>
          <span className="navbar-text mr-3">
            <strong><Link to="/user" className="">🦊{this.props.user ? ` ${this.props.user.username}` : ''}</Link></strong>
          </span>
          <span className='mx-2'> | </span>
          <span>
            {(this.props.user ? Math.max(this.props.user.user_profile.balance, 0) : '-') + " "} 💎
            {(this.props.user && this.props.user.user_profile.reserved_balance ? "( -" + this.props.user.user_profile.reserved_balance + ")" : '') + " "}
          </span>
          <span className='mx-2'> | </span>

          <button onClick={this.props.logout} className="btn btn-link btn-outline-warning">
            Logout
          </button>
        </li>
      </ul>
    );

    const guestLinks = (
      <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
        <li className="nav-item">
          <a href="login" className="btn btn-link btn-outline-warning">
            Login
          </a>
        </li>
      </ul>
    );

    return (
      <nav className="navbar sticky-top navbar-expand-lg bg-body-tertiary px-3 price-card">
        <div className=''>
        <span className='beta d-flex justify-content-center'>Beta</span>
        {this.props.isAuthenticated ? <a className="navbar-brand" href="/dashboard">DataFox </a> : <a className="navbar-brand" href="/">DataFox</a>}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={this.state.collapsed ? 'true' : 'false'}
          aria-label="Toggle navigation"
          onClick={this.toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${!this.state.collapsed ? 'show' : ''}`} id="navbarSupportedContent">
          {this.props.isAuthenticated ? authNavBar : guestNavBar}
          {this.props.isAuthenticated ? authLinks : guestLinks}
        </div>
      </nav>
    )
  }
}


export default Header;