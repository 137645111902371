import React, { Component } from "react";

class Privacy extends Component {
  render() {
    return (
      <main class="container my-5 p-8">
        <h2 id="privacy-policy">Privacy Policy</h2>
        <p>
          Datafox.app (&ldquo;DataFox&rdquo; &ldquo;we&rdquo; or
          &ldquo;us&rdquo;) is committed to protecting your privacy. This
          Privacy Policy explains the methods and reasons we collect, use,
          disclose, transfer, and store your information. If you have any
          questions about the contents of this policy, don’t hesitate to contact
          us.
        </p>
        <p>
          If you do not consent to the collection and use of information from or
          about you in accordance with this Privacy Policy, then you are not
          permitted to use Datafox or any services provided on{" "}
          <a href="https://datafox.app">https://datafox.app</a>.
        </p>
        <h3 id="applicable-law">Applicable Law</h3>
        <p>
        Datafox is headquartered in the Hungary, Budapest. By viewing any content or otherwise using the services
          offered by Datafox, you consent to the transfer of information to our servers which commonly, but not exclusivly
          are located in Hungary and United States of America.
        </p>
        <h3 id="information-we-collect">Information We Collect</h3>
        <p>
          Information you Submit: We store information you provide on this site
          via forms, surveys, or any other interactive content. This information
          commonly includes, but is not limited to, name, mailing address, email
          address, and uploaded files.
        </p>
        <p>
          <strong>Log Files</strong>: We collect information when you use
          services provided on our site. This information may include your IP
          address, device and software characteristics (such as type and
          operating system), page views, referral URLs, device identifiers or
          other unique identifiers such as advertising identifiers (e.g.,
          &ldquo;ad-ID&rdquo; or &ldquo;IDFA&rdquo;), and carrier information.
          Log files are primarily used for the purpose of enhancing the user
          experience.
        </p>
        <p>
          <strong>Cookies</strong>: We use cookies and related technologies to
          keep track of user preferences and activity. Cookies are small text
          files created by a web server, delivered through a web browser, and
          stored on your computer. Most Internet browsers automatically accept
          cookies. You can instruct your browser, by changing its settings, to
          stop accepting cookies or to prompt you before accepting a cookie from
          the websites you visit.
        </p>
        <h3 id="third-party-services">Third Party Services</h3>
        <p>
          This site contains links to other websites not owned by Datafox. In
          general, the third-party services used by us will only collect, use
          and disclose your information to the extent necessary to allow them to
          perform their intended services. Please be aware that we are not
          responsible for the privacy policies of third-party services. Below is
          a list of third-party services used on this site.
        </p>
        <h3 id="children-and-coppa">Children and COPPA</h3>
        <p>
          Datafox is committed to complying with the Children’s Online Privacy
          Protection Act (COPPA). We do not use our services to intentionally
          solicit data from or market to children under the age of 13. We
          encourage parents and guardians to report any suspicions that a child
          has provided us with information without their consent. We will take
          reasonable action to remove this information.
        </p>
        <h3 id="your-choices">Your Choices</h3>
        <p>
          <strong>Opt-Out of Third Party Advertisement Cookies</strong>: You may
          opt out of a third-party vendor&rsquo;s use of cookies for interest
          based advertising by visiting{" "}
          <a href="https://aboutads.info">https://aboutads.info</a>.
        </p>
        <p>
          <strong>Do Not Track Browser settings</strong>: If you enable Do Not
          Track settings in the browser you are using, Datafox will not use
          information about websites you visit, other than our site. Learn more
          about Do Not Track by visiting{" "}
          <a href="https://allaboutdnt.com">https://allaboutdnt.com</a>.
        </p>
        <p>
          <strong>Email Communication Opt-Out</strong>: If you receive
          promotional emails from Datafox, you can unsubscribe by clicking the
          bottom Unsubscribe or Opt-Out link at the bottom of every email or
          go to user settings and turn off this option.
        </p>
        <h3 id="contact-us">Contact Us</h3>
        <p>
          At Datafox, we believe our talented customer service staff will be
          able to resolve any issues you may have using the our services. If you
          would like additional information about this privacy policy, please
          visit contact us: {" "}
          <a href="mailto:info@datafox.app">info@datafox.app</a>.
        </p>
      </main>
    );
  }
}

export default Privacy;
