import { baseColumnFormatter, dateTimeMSFormatter, imgLinkFormatter, argsFormatter, boolFormatter } from "../utils/ScraperTableUtils";
import { textFilter, numberFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';

export function formatTableColumns(columns, dataTypes, titles, extras, isMobile) {
    let formatted = [];

    for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        const dataType = dataTypes[i];
        const title = titles[i];
        const extra = extras[i];
        
        let formatter = baseColumnFormatter;
        let extraData = {};
        let sort = true;
        let filter = undefined;//textFilter();
        if(dataType === "hide"){
            continue;
        }
        if(dataType === "link"){
            formatter = imgLinkFormatter;
            filter = undefined;
            extraData = {"linkHref": extra, "imgCell": columns[i+1]}
            sort = false;
            i += 1;
        } else if(dataType == "datetime"){
            filter = undefined;//dateFilter();
            formatter = dateTimeMSFormatter;
        } else if(dataType == "list"){
            sort = false;
            formatter = argsFormatter
        } else if(dataType == "bool"){
            filter = undefined; //selectFilter();
            formatter = boolFormatter
        } else if(dataType == "bigint"){
            filter = undefined;//numberFilter();
        } else if(dataType == "int"){
            filter = undefined;//numberFilter();
        }
        formatted.push({
            dataField: column,
            text: title,
            sort: sort,
            filter: isMobile? undefined: filter,
            formatter: formatter,
            formatExtraData: extraData
          });
        
    }

    return formatted;
  }
