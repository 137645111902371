import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter, numberFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';

import {
  BsFillCheckCircleFill,
  BsFillDashCircleFill,
  BsFillPlayCircleFill,
  BsDownload,
  BsFillXCircleFill
} from "react-icons/bs";
import { getFileDownloadLink } from "../../common/utils";
import Loading from "../../common/Loading";

class ScraperHistory extends Component {
  statsFormatter(cell, row) {
    return (
      <span>
        {row["total_success"]} / {row["total"]}
      </span>
    );
  }

  runTimeFormatter(cell, row) {
    return <span>{(cell / 60).toFixed(1)} min</span>;
  }

  successfulFormatter(cell, row) {
    if (row["is_active"]) {
      return <button className="btn btn-info btn-sm" disabled><BsFillPlayCircleFill /></button>;
    } else if (cell) {
      return <button className="btn btn-success btn-sm" disabled><BsFillCheckCircleFill /></button>;
    } else {
      return <button className="btn btn-danger btn-sm" disabled><BsFillDashCircleFill /></button>;
    }
  }

  dateTimeFormatter(cell, row) {
    const datetime = new Date(cell);
    return <span>{datetime.toLocaleString()}</span>;
  }


  resultDownloadFormatter(cell, row, rowIndex, extraData) {
    if (cell) {
      return (
        <span>
          <a
            className="btn btn-primary btn-sm mx-1"
            href={getFileDownloadLink(row["scraper"] ,row['id'])}
          >
            <BsDownload />;
          </a>
          <button key={row["id"]} data-id={row["id"]} className="btn btn-danger btn-sm mx-1 my-1"  onClick={() => extraData(row['id'])} >
            <BsFillXCircleFill/>
          </button>
        </span>
      );
    } else {
      return <span></span>
    }
  }

  render() {
    const { SearchBar } = Search;

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        filter: textFilter()
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
        filter: textFilter()
      },
      {
        dataField: "started_at",
        text: "Start",
        sort: true,
        formatter: this.dateTimeFormatter,
        filter: dateFilter()
      },
      {
        dataField: "exec_time",
        text: "Exec Runtime",
        sort: true,
        formatter: this.runTimeFormatter,
        filter: numberFilter()
      },
      {
        dataField: "total",
        text: "Total",
        sort: true,
        formatter: this.statsFormatter,
        filter: numberFilter()
      },
      {
        dataField: "is_successful",
        text: "Successful",
        formatter: this.successfulFormatter,
        filter: selectFilter({
          options: [{
            value: true,
            label: "Successful"
          }, {
            value: false,
            label: "Error"
          }]
        })
      },
      {
        dataField: "result_message",
        text: "Result",
        filter: textFilter()
      },
      {
        dataField: "args",
        text: "Args",
        filter: textFilter()
      },
      {
        dataField: "result_path",
        text: "Actions",
        formatter: this.resultDownloadFormatter,
        formatExtraData: this.props.removeScrapeResultCallback
      },
    ];

    const paginationOptions = {
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: 'All', value: this.props.history.length
      }]
    }

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      style: { backgroundColor: "#00BFFF" },
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.props.selectScrapeCallback(row.id, true);
        } else {
          this.props.selectScrapeCallback(row.id, false);
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        if (isSelect) {
          this.props.selectScrapeCallback(rows.map(o => o.id), true);
        } else {
          this.props.selectScrapeCallback(rows.map(o => o.id), false);
        }
      }
    };

    if (this.props.historyLoading) {
      return (
        <div className="card card-body">
          <Loading type="rings"></Loading>
        </div>
      );
    } else {
      return (
        <div className="card card-body">
          <h5>Scraper results</h5>
          <ToolkitProvider
            keyField="id"
            data={this.props.history}
            columns={columns}
            striped
            hover
            condensed
            search
          >
            {(props) => (
              <div>
                <div className="row ">
                  <div className="col-md-6 col-sm-6  my-1">
                    <SearchBar {...props.searchProps} srText="" />
                  </div>
                  <div className="col-md-6 col-sm-6 my-1 d-flex justify-content-sm-end">
                  <button
                    className="btn btn-info m-1"
                    onClick={this.props.downloadAllCallback}
                  >
                    Export
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={this.props.deleteAllCallback}
                  >
                    Delete
                  </button>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  selectRow={selectRow}
                  filter={ filterFactory() }
                  pagination={paginationFactory(paginationOptions)}
                  wrapperClasses="table-responsive"
                  noDataIndication="It's empty here"
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      );
    }
  }
}

export default ScraperHistory;
