import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { feedback } from '../../services/user.service';
import { CAPTCHA_AUTH_KEY } from '../../services/const';

export default class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: 0, // 0 init, 1 no, 2 yes
      feedback: '',
    };
  }

  toggleForm = () => {
    if (this.state.showForm === 1 || this.state.showForm === 0) {
      this.setState({ showForm: 2 });
    } else {
      this.setState({ showForm: 1 });
    }

  };

  handleChange = (e) => {
    this.setState({ feedback: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.props.user) {
      toast.error("Please log in");
      return;
    }

    feedback(this.props.user.email, this.props.user.username, '', this.state.feedback, CAPTCHA_AUTH_KEY).then((res) => {
      toast.success("Thank you for your feedback, we will get back to you shortly 🙏");
    }).catch((err) => {
      console.error("Failed to send feedback", err);
    });
    this.setState({ feedback: '', showForm: 1 });
  };

  render() {
    const { showForm, feedback } = this.state;

    return (
      <div className="position-fixed bottom-0 end-0 p-3 mx-0 w-25 d-flex justify-content-end">
        <button
          className="btn btn-primary rounded-circle emoji-div"
          onClick={this.toggleForm}
        >
          ⭐
        </button>
        {showForm !== 0 && (
          <div className={"card position-absolute bottom-100 end-0 m-2 p-3 " + (showForm === 2 ? 'animate__animated animate__bounceInRight' : (showForm === 1 ? 'animate__animated animate__bounceOutRight' : ''))}>
            <form onSubmit={this.handleSubmit} className="w-100">
              <div className="mb-3">
                <label htmlFor="feedback" className="form-label">
                  Your Feedback<br/>
                  <small>Encountered a problem? Have a feature idea? Please let us know</small>
                </label>
                <textarea
                  className="form-control"
                  id="feedback"
                  rows="5"
                  cols="50"
                  value={feedback}
                  onChange={this.handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }
}