import React, { Component } from 'react'
import { toast } from 'react-toastify';
import { resend } from '../../services/auth.service';
import Loading from '../../common/Loading';

export default class RegistrationReSend extends Component {

  state = {
    email: '',
    isLoading: false
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    resend(this.state.email).then((res) => {
      toast.success("Registration email sent successfully");
      window.location.replace("/login");
    }).catch((err) => {
      this.setState({ isLoading: false });
      console.log("Resend registration email failed", err);
    })
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { email } = this.state;

    if (this.state.isLoading) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center my-5">
          <Loading type="rings"></Loading>
        </div>
      );
    }
    else {
      return (
        <div className="col-md-4 m-auto content my-5">
          <section className='box-section'>
            <div className="card card-body auth-div box">
              <h2 className="text-center">Resend activation link</h2>
              <form onSubmit={this.onSubmit}>
                <div className="form-group my-2">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    onChange={this.onChange}
                    value={email}
                  />
                </div>

                <div className="form-group mt-2">
                  <button type="submit" className="btn btn-primary">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      );
    }
  }
}
