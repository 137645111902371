import React, { Component } from 'react';

class ConfirmModal extends Component {
    render() {
        const {
            title,
            description,
            okCallback,
            cancelCallback,
        } = this.props;

        return (
            <div>
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5></div>
                    <div className="modal-body"><p>{description}</p></div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={okCallback}>OK</button>
                        <button type="button" className="btn btn-warning" onClick={cancelCallback}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmModal;
