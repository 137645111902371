import React, { Component } from "react";
import { Gif } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import Loading from "../../common/Loading";

class GifContainer extends Component {
  state = {
    gif: null,
  };

  componentDidMount() {
    const gf = new GiphyFetch("acUuNytwKXmLmo8juAh8L6AmU23GQGL7");
    if (this.props.gifType === "error") {
      this.setGifContent(gf, "Error");
    } else if (this.props.gifType === "success") {
      this.setGifContent(gf, "Happy");
    } else if (this.props.gifType === "delete") {
      this.setGifContent(gf, "bye");
    } else {
      this.setGifContent(gf, this.props.gifType);
    }
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  setGifContent(gf, searchTerm) {
    gf.search(searchTerm, { limit: 1, offset: this.getRandomInt(0, 25) }).then(
      (fetchGif) => {
        this.setState({
          gif: fetchGif.data[0],
        });
      }
    );
  }

  getGifContent() {
    if (this.state.gif !== null) {
      return <Gif gif={this.state.gif} width={200} height={200} />;
    } else {
      return <Loading type="dots"></Loading>;
    }
  }

  render() {
    return (
      <div className="justify-content-center">
        <p>{this.props.message}</p>
        <div>{this.getGifContent()}</div>
      </div>
    );
  }
}

export default GifContainer;
