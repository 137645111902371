import React, { Component } from "react";
import ActiveScraper from "./home/ActiveScraper";
import ScraperDetails from "./home/ScraperDetails";
import ScraperHistory from "./home/ScraperHistory";
import NewScrape from "./home/NewScrape";
import { toast } from "react-toastify";

import {
  loadScraperHistory,
  fetchScrapeDetails,
  fetchScraperRunParams,
  startNewScrape,
  fetchActive,
  stopNewScrape,
  fetchSchedules,
  scheduleNewScrape,
  removeScheduleScrape,
  removeScrapeResult,
  downloadScrapeResults,
} from "../services/crawler.service";
import { timeout } from "../common/utils";
import ScheduleScrape from "./home/ScheduleScrape";
import ScheduleList from "./home/ScheduleList";
import ScraperLogs from "./home/ScraperLogs";
import { WS_ADDRESS } from "../services/const";

class Home extends Component {
  state = {
    scraper: null,
    historyData: [],
    historyLoading: true,
    selectedScrapeId: null,
    selectedScrapes: [],
    details: null,
    detailsLoading: false,
    runParams: [],
    showNewScrapeModal: false,
    showScheduleScrapeModal: false,
    schedules: [],
    schedulesLoadings: false,
    runScrapeLoading: false,
    scheduleScrapeLoading: false,
    currentLogs: [],
  };

  constructor(props) {
    super(props);
    this.updateHistory = this.updateHistory.bind(this);
    this.selectScrapeCallback = this.selectScrapeCallback.bind(this);
    this.handleNewScrapeModalOpen = this.handleNewScrapeModalOpen.bind(this);
    this.handleNewScrapeModalClose = this.handleNewScrapeModalClose.bind(this);
    this.handleScheduleScrapeModalOpen =
      this.handleScheduleScrapeModalOpen.bind(this);
    this.handleScheduleScrapeModalClose =
      this.handleScheduleScrapeModalClose.bind(this);
    this.startNewScrapeCallback = this.startNewScrapeCallback.bind(this);
    this.downloadAllCallback = this.downloadAllCallback.bind(this);
    this.deleteAllCallback = this.deleteAllCallback.bind(this);
    this.stopScrapeCallback = this.stopScrapeCallback.bind(this);
    this.scheduleScrapeCallback = this.scheduleScrapeCallback.bind(this);
    this.removeScrapeResultCallback =
      this.removeScrapeResultCallback.bind(this);
    this.removeScheduleCallback = this.removeScheduleCallback.bind(this);

    this.WS_URL = WS_ADDRESS;
    this.socket = null;
  }

  componentDidMount() {
    this.setState({
      scraper: this.props.selectedScraper,
      selectedScrapeId: null,
      selectedScrapes: [],
      details: null,
      showNewScrapeModal: false,
      showScheduleScrapeModal: false,
      historyLoading: true,
      activeScraper: null,
      activeProgress: 0,
      activeScraperLoading: true,
      schedules: [],
      schedulesLoadings: false,
      currentLogs: [],
    });
    this.updateActiveScrape(this.props.selectedScraper);
    this.updateHistory(this.props.selectedScraper);
    this.updateRunParams(this.props.selectedScraper);
    this.updateSchedules(this.props.selectedScraper);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedScraper !== this.props.selectedScraper &&
      this.props.selectedScraper !== this.state.scraper
    ) {
      this.setState({
        scraper: this.props.selectedScraper,
        selectedScrapeId: null,
        selectedScrapes: [],
        details: null,
        showNewScrapeModal: false,
        showScheduleScrapeModal: false,
        historyLoading: true,
        activeScraper: null,
        activeProgress: 0,
        activeScraperLoading: true,
        schedules: [],
        schedulesLoadings: false,
        currentLogs: [],
      });
      this.updateActiveScrape(this.props.selectedScraper);
      this.updateHistory(this.props.selectedScraper);
      this.updateRunParams(this.props.selectedScraper);
      this.updateSchedules(this.props.selectedScraper);
    }
  }

  componentWillUnmount(){
    if(this.socket){
      this.socket.close();
    }
  }

  updateHistory(scraper) {
    loadScraperHistory(scraper)
      .then((res) => {
        this.setState({
          historyData: res.data,
          historyLoading: false,
          details: null,
        });
      })
      .catch((err) => {
        console.error("Failed to update history: ", err);
        this.setState({
          historyData: [],
          historyLoading: false,
          details: null,
        });
      });
  }

  removeScrapeResultCallback(scrapeId) {
    this.setState({ historyLoading: true });
    removeScrapeResult(scrapeId)
      .then((res) => {
        if (res.data["response"] === "ok") {
          toast.success("Scrape result successfuly deleted");
          this.updateHistory(this.state.scraper);
        } else {
          toast.error(
            "Failed to delete scrape result: " + res.data["response"]
          );
          this.setState({ historyLoading: false });
        }
      })
      .catch((err) => {
        console.error("Failed to delete scrape result: " + err);
        this.setState({ historyLoading: false });
      });
  }

  updateSchedules(scraper) {
    fetchSchedules(scraper)
      .then((res) => {
        this.setState({
          schedules: res.data,
          schedulesLoadings: false,
        });
      })
      .catch((err) => {
        console.error("Failed to update schedules " + err);
        this.setState({
          schedules: [],
          schedulesLoadings: false,
        });
      });
  }

  updateActiveScrape(scraper) {
    if (this.socket) {
      this.socket.close();
      console.log("Connection closed");
      this.socket = null;
    }

    fetchActive(scraper)
      .then((res) => {
        const data = res.data[0];
        this.setState({
          currentLogs: [],
          activeScraper: data,
          activeProgress: 0,
          activeScraperLoading: false,
        });
        this.setupSocket(data["id"]);
      })
      .catch((err) => {
        console.error("Failed to load active scraper: " + err);
        this.setState({
          activeScraper: null,
          activeProgress: 0,
          activeScraperLoading: false,
        });
      });
  }

  setupSocket(scrapeId) {
    this.socket = new WebSocket(this.WS_URL + scrapeId + "/");
    console.log("Socket initialized: " + this.WS_URL + scrapeId + "/");

    this.socket.onopen = () => {
      console.log("WebSocket connected");
    };

    // Listen for the 'message' event
    this.socket.onmessage = (event) => {
      console.log("Message received:", event.data);
      const eventData = JSON.parse(event.data);

      switch (eventData["type"]) {
        case "scrape_started":
          toast.success("New scrape successfully started");
          break;
        case "scrape_progress":
          this.setState({
            activeProgress: Math.floor(
              ((eventData["args"]["success"] + eventData["args"]["error"]) /
                eventData["args"]["total"]) *
                100
            ),
          });
          break;
        case "scrape_finished":
          toast.success("Scrape successfuly finished");
          this.updateActiveScrape(this.state.scraper);
          this.updateHistory(this.state.scraper);
          break;
        case "log":
          this.setState({
            currentLogs: [...this.state.currentLogs, eventData["args"]],
          });
          break;
        default:
          console.log("Action ignored for socket: " + event.data);
      }
    };

    // Listen for the 'close' event
    this.socket.onclose = () => {
      console.log("WebSocket disconnected");
    };
  }

  startNewScrapeCallback(params) {
    this.setState({ runScrapeLoading: true });
    startNewScrape(this.state.scraper, params)
      .then((res) => {
        if (res.data["response"] === "ok") {
          toast.success("Starting new scrape...");
          this.setState({
            showNewScrapeModal: false,
            historyLoading: true,
            runScrapeLoading: false,
          });
          this.updateHistory(this.state.scraper);
          this.updateActiveScrape(this.state.scraper);
        } else {
          toast.error("Failed to start new scrape: " + res.data["response"]);
          this.setState({ runScrapeLoading: false });
        }
      })
      .catch((err) => {
        toast.error("Failed to start new scrape: " + err);
        console.error("Failed to start new scrape: ", err);
        this.setState({ runScrapeLoading: false });
      });
  }

  stopScrapeCallback() {
    toast.success("Stopping scrape, this may take up to 10 seconds");
    if (this.state.activeScraper) {
      stopNewScrape(this.state.activeScraper.id)
        .then((res) => {
          this.setState({ activeScraperLoading: true });
          timeout(10000).then((_) => {
            if (this.state.activeScraper != null) {
              toast.warning(
                "Stopping scrape took longer than expected, forcing stop"
              );
              stopNewScrape(this.state.activeScraper.id, true)
                .then((res2) => {
                  toast.success("Scrape successfuly force stopped");
                  this.updateActiveScrape(this.state.scraper);
                  this.updateHistory(this.state.scraper);
                })
                .catch((err2) => {
                  console.error("Failed to force stop scrape: " + err2);
                });
            }
          });
        })
        .catch((err) => {
          console.error("Failed to stop scrape: " + err);
        });
    } else {
      toast.error("No scrape running!");
      this.updateActiveScrape(this.props.selectedScraper);
      this.updateHistory(this.props.selectedScraper);
    }
  }

  updateRunParams(scraper) {
    fetchScraperRunParams(scraper)
      .then((resp) => {
        this.setState({
          runParams: resp.data,
        });
      })
      .catch((err) => {
        console.error("Failed to fetch run params: " + err);
      });
  }

  updateDetails(scrapeId) {
    if (scrapeId == null) {
      this.setState({
        details: null,
        detailsLoading: false,
      });
    } else {
      fetchScrapeDetails(scrapeId)
        .then((res) => {
          this.setState({
            details: res.data,
            detailsLoading: false,
          });
        })
        .catch((err) => {
          console.error("Failed to get scrape details: " + err);
          this.setState({
            detailsLoading: false,
          });
        });
    }
  }

  selectScrapeCallback(scrapeId, select) {
    const scrapes = Array.isArray(scrapeId) ? scrapeId : [scrapeId];
    if (select) {
      this.setState({
        selectedScrapeId: scrapeId instanceof Array ? null : scrapeId,
        detailsLoading: scrapeId instanceof Array ? false : true,
        selectedScrapes: [
          ...new Set([...this.state.selectedScrapes, ...scrapes]),
        ],
      });
      if (!(scrapeId instanceof Array)) {
        this.updateDetails(scrapeId);
      }
    } else {
      this.setState({
        selectedScrapeId: null,
        selectedScrapes: this.state.selectedScrapes.filter(
          (item) => !scrapes.includes(item)
        ),
      });
      this.updateDetails(null);
    }
  }

  scheduleScrapeCallback(params) {
    const body = {
      args: params,
      interval: params["interval"],
      interval_value: params["interval_value"],
      start_time: params["start_time"],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    this.setState({ scheduleScrapeLoading: true });
    scheduleNewScrape(this.state.scraper, body)
      .then((res) => {
        if (res.data["response"] === "ok") {
          toast.success("New schedule successfully added");
          this.setState({
            scheduleScrapeLoading: false,
            schedulesLoadings: true,
            showScheduleScrapeModal: false,
          });
          this.updateSchedules(this.state.scraper);
        } else {
          toast.error("Failed to add new schedule: " + res.data["response"]);
          this.setState({ scheduleScrapeLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ scheduleScrapeLoading: false });
        console.error("Failed to schedule scrape: " + err);
      });
  }

  removeScheduleCallback(runId) {
    console.log("Removing schedule: " + runId);
    this.setState({ scheduleScrapeLoading: true });
    removeScheduleScrape(this.state.scraper, runId)
      .then((res) => {
        if (res.data["response"] === "ok") {
          toast.success("Schedule successfully removed");
          this.setState({
            scheduleScrapeLoading: false,
            schedulesLoadings: true,
          });
          this.updateSchedules(this.state.scraper);
        } else {
          toast.error("Failed to remove schedule: " + res.data["response"]);
          this.setState({ scheduleScrapeLoading: false });
        }
      })
      .catch((err) => {
        console.error("Failed to remove schedule: " + err);
        this.setState({ scheduleScrapeLoading: false });
      });
  }

  downloadAllCallback() {
    console.log("Downloading: " + this.state.selectedScrapes);
    downloadScrapeResults(this.state.scraper, {
      ids: this.state.selectedScrapes.toString(),
    }).then((response) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        "download",
        this.state.scraper + "-export-" + new Date() + ".xlsx"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log("File downloaded");
    });
  }

  deleteAllCallback() {
    this.setState({ historyLoading: true });
    console.log("Deleting: " + this.state.selectedScrapes);
    const deletePromises = this.state.selectedScrapes.map((scrapeId) =>
      removeScrapeResult(scrapeId)
        .then((res) => {
          if (res.data["response"] !== "ok") {
            toast.error(
              "Failed to delete scrape result: " + res.data["response"]
            );
          }
        })
        .catch((err) => {
          console.error("Failed to delete scrape result: " + err);
        })
    );

    Promise.all(deletePromises)
      .then(() => {
        toast.success("Scrape results successfully deleted");
        this.updateHistory(this.state.scraper);
      })
      .catch((error) => {
        console.error("Failed to delete scrape results:", error);
      });
  }

  handleNewScrapeModalOpen = () => {
    this.setState({ showNewScrapeModal: true });
  };

  handleNewScrapeModalClose = () => {
    this.setState({ showNewScrapeModal: false });
  };

  handleScheduleScrapeModalOpen = () => {
    this.setState({ showScheduleScrapeModal: true });
  };

  handleScheduleScrapeModalClose = () => {
    this.setState({ showScheduleScrapeModal: false });
  };

  render() {
    return (
      <div className="mx-3">
        <div className="row my-3">
          <div className="col-lg-12">
            <ActiveScraper
              activeScraper={this.state.activeScraper}
              progress={this.state.activeProgress}
              isLoading={this.state.activeScraperLoading}
              handleStopScrapeCallback={this.stopScrapeCallback}
              handleNewScrapeModalOpenCallback={this.handleNewScrapeModalOpen}
              handleScheduleScrapeModalOpenCallback={
                this.handleScheduleScrapeModalOpen
              }
            />
          </div>
        </div>
        <div className="row my-3">
          <div className="col-xxl-6 col-12 my-3">
            <ScheduleList
              schedules={this.state.schedules}
              isLoading={this.state.schedulesLoadings}
              removeScheduleCallback={this.removeScheduleCallback}
            />
          </div>
          <div className="col-xxl-6 col-12 my-3">
            <ScraperLogs logs={this.state.currentLogs} />
          </div>
        </div>
        <div className="row my-3">
          <div className="col-xxl-6 col-12 my-3">
            <ScraperHistory
              scraper={this.state.scraper}
              history={this.state.historyData}
              historyLoading={this.state.historyLoading}
              selectScrapeCallback={this.selectScrapeCallback}
              downloadAllCallback={this.downloadAllCallback}
              deleteAllCallback={this.deleteAllCallback}
              removeScrapeResultCallback={this.removeScrapeResultCallback}
            />
          </div>
          <div className="col-xxl-6 col-12 my-3">
            <ScraperDetails
              selectedScrapeId={this.state.selectedScrapeId}
              scrapeData={this.state.historyData.filter(
                (item) => item.id === this.state.selectedScrapeId
              )}
              details={this.state.details}
              isLoading={this.state.detailsLoading}
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
        {this.state.showNewScrapeModal && (
          <div
            className="modal show container"
            id="NewScrapeContainer"
            role="dialog"
            tabIndex="-1"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <NewScrape
                  params={this.state.runParams}
                  isLoading={this.state.runScrapeLoading}
                  startNewScrapeCallback={this.startNewScrapeCallback}
                  handleNewScrapeModalCloseCallback={
                    this.handleNewScrapeModalClose
                  }
                />
              </div>
            </div>
          </div>
        )}
        </div>
        {this.state.showScheduleScrapeModal && (
          <div
            className="modal show"
            id="NewScrapeContainer"
            role="dialog"
            tabIndex="-1"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <ScheduleScrape
                  scraper={this.state.scraper}
                  params={this.state.runParams}
                  isLoading={this.state.scheduleScrapeLoading}
                  scheduleScrapeCallback={this.scheduleScrapeCallback}
                  handleScheduleScrapeModalCloseCallback={
                    this.handleScheduleScrapeModalClose
                  } />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Home;
