import React, { Component } from "react";
import { BsInstagram, BsTiktok, BsYoutube  } from "react-icons/bs";

export default class Footer extends Component {
  render() {
    return (
      <footer
        id="sticky-footer"
        className="flex-shrink-0 py-4 bg-dark text-white-50 footer mt-5"
      >
        <div className="container text-center">
          <div>Need help? Contact us: <a href="mailto:info@datafox.app">info@datafox.app</a></div>
          <div className="social-icons my-2">
            <a
              href="https://www.tiktok.com/@datafox_dev"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mx-3"
            >
              <BsTiktok />
            </a>
            <a
              href="https://www.instagram.com/datafox_app/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mx-3"
            >
              <BsInstagram />
            </a>
            <a
              href="https://www.youtube.com/@DataFoxCorp"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mx-3"
            >
              <BsYoutube />
            </a>
          </div>
          <div>
            <p>Helpful links<br/>
              <a href="https://datafox.app">Landing</a> | <a href="https://datafox.app/dashboard">Dashboard</a> | <a href="https://datafox.app/user">User</a> | <a href="https://datafox.app/privacy">Privacy</a>
            </p>
          </div>
          <small>Copyright &copy; DataFox</small>
          <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=JR4hFXgOOOCFDL3gHArrplLzsUPZMSkU5Hreg0JnezShR98s1HRmnr3bzP4M"></script></span>
        </div>
      </footer>
    );
  }
}
