import {
    loadScraperHistory
} from "../services/crawler.service";
import {
    addNewStatReport,
    fetchReports,
    deleteStatReport
} from "../services/stats.service";
import React, { Component } from 'react';
import { toast } from "react-toastify";
import ScraperResults from "./stats/ScraperResults";
import StatReports from "./stats/StatReports";
import TutorialHelper from "./utils/TutorialHelper";

class Statistics extends Component {

    state = {
        scraper: null,
        historyData: [],
        historyLoading: true,
        statReportData: [],
        statReportLoading: true
    }

    constructor(props) {
        super(props);
        this.updateHistory = this.updateHistory.bind(this);
        this.createStatReportCallback = this.createStatReportCallback.bind(this);
        this.deleteStatReportCallback = this.deleteStatReportCallback.bind(this);
    }

    componentDidMount() {
        this.setState({
            scraper: this.props.selectedScraper,
            historyLoading: true,
            statReportLoading: true
        });
        this.updateHistory(this.props.selectedScraper);
        this.updateStatReports(this.props.selectedScraper);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.selectedScraper !== this.props.selectedScraper &&
            this.props.selectedScraper !== this.state.scraper
        ) {
            this.setState({
                scraper: this.props.selectedScraper,
                historyLoading: true,
                statReportLoading: true
            });
            this.updateHistory(this.props.selectedScraper);
            this.updateStatReports(this.props.selectedScraper);
        }
    }

    updateHistory(scraper) {
        loadScraperHistory(scraper)
            .then((res) => {
                this.setState({
                    historyData: res.data,
                    historyLoading: false
                });
            })
            .catch((err) => {
                console.error("Failed to update history: " + err);
                this.setState({
                    historyData: [],
                    historyLoading: false
                });
            });
    }

    updateStatReports(scraper) {
        fetchReports(scraper).then((res) => {
            this.setState({
                statReportData: res.data,
                statReportLoading: false
            });
            console.log(res.data);
        }).catch((err) => {
            console.error("Failed to update stat reports: " + err);
            this.setState({
                statReportData: [],
                statReportLoading: false
            });
        })
    }

    createStatReportCallback(scrape_id) {
        addNewStatReport(this.state.scraper, scrape_id).then((res) => {
            toast.success("New stat report published for " + scrape_id);
            this.setState({
                statReportLoading: true
            });
            this.updateStatReports(this.state.scraper);
        }).catch((err) => {
            console.error("Failed to add new stat report " + err);
        })
    }

    deleteStatReportCallback(report_id){
        deleteStatReport(report_id).then((res)=> {
            toast.success("Stat report deleted " + report_id);
            this.setState({
                statReportLoading: true
            });
            this.updateStatReports(this.state.scraper);
        }).catch((err) => {
            console.error("Failed to remove stat report " + err);
        })
        
    }

    render() {
        return (
            <div className="mx-3">
                <div className="row my-3">
                    <div className="col-lg-12 my-3">
                        <StatReports 
                            user={this.props.user}
                            scraper={this.state.scraper}
                            reports={this.state.statReportData}
                            statReportLoading={this.state.statReportLoading}
                            deleteStatReportCallback={this.deleteStatReportCallback}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-lg-12 my-3">
                        <ScraperResults
                            scraper={this.state.scraper}
                            history={this.state.historyData}
                            historyLoading={this.state.historyLoading}
                            createStatReportCallback={this.createStatReportCallback} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Statistics;