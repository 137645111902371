import React, { Component } from "react";
import Loading from "../../common/Loading";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  BsFillXCircleFill
} from "react-icons/bs";

class ScheduleList extends Component {

  dateTimeFormatter(cell, row) {
    const datetime = new Date(cell);
    return <span>{datetime.toLocaleString()}</span>;
  }

  triggerFormatter(cell, row) {
    return <span>{cell.match(/\[(.*?)\]/)?.[1]}</span>;
  }

  actionsFormatter(cell, row, rowIndex, extraData) {
      return (
        <span className="d-flex justify-content-center">
          <button className="btn btn-danger btn-sm mx-1"  onClick={() => extraData(row['id'])} >
            <BsFillXCircleFill/>
          </button>
        </span>
      );
  }

  argsFormetter(cell, row){
    return <span>{cell[2]}</span>
  }

  render() {
    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
      },
      {
        dataField: "job.trigger",
        text: "Trigger",
        sort: true,
        formatter: this.triggerFormatter
      },
      {
        dataField: "django_job.next_run_time",
        text: "Next run time",
        sort: true,
        formatter: this.dateTimeFormatter,
      },
      {
        dataField: "job.args",
        text: "Args",
        sort: true,
        formatter: this.argsFormetter,
      },
      {
        dataField: "django_job.id",
        text: "Actions",
        formatter: this.actionsFormatter,
        formatExtraData: this.props.removeScheduleCallback
      },
    ];

    if (this.props.isLoading) {
      return (
        <div className="card card-body">
          <Loading type="rings"></Loading>
        </div>
      );
    } else {
      return (
        <div className="card card-body">
          <h5>Scraper schedule</h5>
          <BootstrapTable
            keyField="id"
            data={this.props.schedules}
            columns={columns}
            pagination={paginationFactory()}
            noDataIndication="No jobs scheduled"
            wrapperClasses="table-responsive"
            striped
            hover
            condensed
          />
        </div>
      );
    }
  }
}

export default ScheduleList;
