import React, { Component } from "react";
import Home from "./Home";
import Settings from "./Settings";
import Extras from "./Extras";
import Statistics from "./Statistics";
import Alert from "./Alert";
import { is_admin } from "../services/auth.service";
import Data from "./Data";
import Feedback from "./utils/Feedback";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "services",
    };
  }

  handleTabClick(tabId) {
    this.setState({ selectedTab: tabId });
  }

  render() {
    const { selectedTab } = this.state;

    let content;
    if (selectedTab === "home") {
      content = <Home selectedScraper={this.props.selectedScraper} />;
    } else if (selectedTab === "services") {
      content = <Extras user={this.props.user} selectedScraper={this.props.selectedScraper} updateUserCallback={this.props.updateUserCallback} />;
    } else if (selectedTab === "statistics") {
      content = <Statistics user={this.props.user} selectedScraper={this.props.selectedScraper} />;
    } else if (selectedTab === "settings") {
      content = <Settings selectedScraper={this.props.selectedScraper} />;
    } else if (selectedTab === "alert") {
      content = <Alert selectedScraper={this.props.selectedScraper} />;
    } else if (selectedTab === "data") {
      content = <Data user={this.props.user} selectedScraper={this.props.selectedScraper} />;
    }

    return (
      <div className="m-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={
                "nav-link " + (this.state.selectedTab == "services" ? "active" : "")
              }
              onClick={() => this.handleTabClick('services')}
            >
              Services
            </a>
          </li>
          <li className="nav-item">
            <button
              className={"nav-link " + (this.state.selectedTab == "data" ? "active" : "")}
              onClick={() => this.handleTabClick('data')}>
              Your data
            </button>
          </li>
          <li className="nav-item">
            <button
              className={
                "nav-link " +
                (this.state.selectedTab == "statistics" ? "active" : "")
              }
              onClick={() => this.handleTabClick('statistics')}
            >
              Share
            </button>
          </li>
          {is_admin(this.props.user) && <li className="nav-item">
            <button
              className={
                "nav-link " +
                (this.state.selectedTab == "alert" ? "active" : "")
              }
              onClick={() => this.handleTabClick('alert')}
            >
              Alert
            </button>
          </li>}
          {is_admin(this.props.user) && <li className="nav-item">
            <button
              className={"nav-link " + (this.state.selectedTab == "home" ? "active" : "")}
              onClick={() => this.handleTabClick('home')}>
              Scraper
            </button>
          </li>}
          {is_admin(this.props.user) && <li className="nav-item">
            <button
              className={
                "nav-link " + (this.state.selectedTab == "settings" ? "active" : "")
              }
              onClick={() => this.handleTabClick('settings')}
            >
              Settings
            </button>
          </li>}
        </ul>
        <div className="tab-content my-3">{content}</div>
        <Feedback user={this.props.user}/>
      </div>
    );
  }
}

export default Index;
