import {
  BsFillCheckCircleFill,
  BsFillDashCircleFill,
  BsFillPlayCircleFill,
  BsFillCloudArrowUpFill,
  BsFillXCircleFill,
  BsFillCloudDownloadFill
} from "react-icons/bs";
import { BASE_ADDRESS } from "../../services/const";

export function dateTimeFormatter(cell, row) {
  const datetime = new Date(cell);
  return <span>{datetime.toLocaleString()}</span>;
}

export function dateTimeMSFormatter(cell, row) {
  const datetime = new Date(cell * 1000);
  return <span>{datetime.toLocaleString()}</span>;
}

export function imgLinkFormatter(cell, row, rowIndex, formatExtraData) {
  try {
    const linkHref = formatExtraData["linkHref"].replace(/{(.*?)}/g, (match, key) => {
      return row[key] || match;
    });
    return <span><a target="_blank" href={linkHref}><img className="img-fluid" src={getImageCellValue(row[formatExtraData["imgCell"]])} /></a></span>
  } catch(error){
    return <span><img className="img-fluid" src={getImageCellValue(row[formatExtraData["imgCell"]])} /></span>
  }
}

export function successfulFormatter(cell, row) {
  if (row["is_active"]) {
    return <div className="emoji-div">😁</div>;
  } else if (cell) {
    return <div className="emoji-div">😍</div>;
  } else {
    return <div className="emoji-div">🤔</div>;
  }
}

export function boolFormatter(cell, row) {
  if(cell instanceof Boolean){
    return cell? (<span>✔️</span>) : (<span>❌</span>)
  } else if(cell instanceof Number){
    return cell > 0? (<span>✔️</span>) : (<span>❌</span>)
  } else {
    return cell? (<span>✔️</span>) : (<span>❌</span>)
  }
}

export function statsFormatter(cell, row) {
  return (
    <span>
      {row["total_success"]} / {row["total"]}
    </span>
  );
}

export function argsFormatter(cell, row) {
  let cellValue;
  try {
    cellValue = JSON.parse(cell.replace(/'/g, '"').replace("True", "true").replace("False", "false"));
  } catch (error) {
    console.log(cell.replace(/'/g, '"'))

    cellValue = [];
  }

  return (
    <span>
      <ul style={{paddingLeft: "5px"}}>
          {cellValue
          .filter((key, value) => (value !== '' && value !== undefined && value !== null && value !== "False"))
          .filter((key, value) => (key !== "group" && key !== "freeTier"))
          .map((value, key) => (
            <li key={key}>
              <span>{value}</span>
            </li>
          ))}
        </ul>
    </span>
  );
}

export function kwargsFormatter(cell, row) {
  let cellValue;
  try {
    cellValue = JSON.parse(cell.replace(/'/g, '"').replaceAll("True", 'true').replaceAll("False", 'false'));
  } catch (error) {
    cellValue = [];
  }

  const keyValueArray = Object.entries(cellValue);

  return (
    <span>
      <ul style={{paddingLeft: "5px"}}>
          {keyValueArray
          .filter(([key, value]) => (value !== '' && value !== undefined && value !== null && value !== "False"))
          .filter(([key, value]) => (key !== "group" && key !== "freeTier"))
          .map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {value}
            </li>
          ))}
        </ul>
    </span>
  );
}

export function costsFormatter(cell, row) {
  if(row["total_reserved_cost"] === 0){
    return <span>Free</span>
  }
  return (
    <span>
      {row["total_cost"]} ( {row["total_reserved_cost"]} )
    </span>
  );
}

export function runTimeFormatter(cell, row) {
  return <span>{(cell / 60).toFixed(1)} min</span>;
}

export function convertToUtf8(obj) {
  const parser = new DOMParser();
  if (typeof obj === "string") {
    try {
      // Try to decode the string and then encode it as UTF-8
      const converted = decodeURIComponent(escape(obj));
      return converted;
    } catch (error) {
      // Handle decoding error (string may already be UTF-8 encoded)
      return obj;
    }
  } else if (Array.isArray(obj)) {
    // If it's an array, recursively call the function on each element
    return obj.map(convertToUtf8);
  } else if (typeof obj === "object" && obj !== null) {
    // If it's an object, recursively call the function on each property value
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = convertToUtf8(obj[key]);
      }
    }
    return newObj;
  } else {
    // If it's neither a string nor an object, return it as is
    return obj;
  }
}

function isLinkCell(cell) {
  return (
    typeof cell === "string" &&
    (cell.startsWith("/shared/data/") ||
      cell.startsWith("../data/") ||
      cell.startsWith("https://storage.googleapis.com/download/"))
  );
}

function isImageCell(cell) {
  return (
    typeof cell === "string" &&
    (cell.endsWith(".jpg") || cell.endsWith(".jpeg") || cell.endsWith(".png"))
  );
}

function getLinkCellValue(cell) {
  if(typeof cell !== "string"){
    return cell;
  } else if (cell.startsWith("/shared/data/")) {
    return BASE_ADDRESS + cell.replace("/shared/data/", "media/");
  } else if(cell.startsWith("../data/")){
    return BASE_ADDRESS + cell.replace("../data", "static");
  } else {
    return cell;
  }
}

export function getImageCellValue(cell) {
  if(typeof cell !== "string"){
    return cell;
  } else if (cell.startsWith("/shared/data/")) {
    return BASE_ADDRESS + cell.replace("/shared/data/", "media/");
  } else if(cell.startsWith("../data/")){
    return BASE_ADDRESS + cell.replace("../data", "static");
  } else if(cell.includes("D:\\Projects\\DataFox\\data")){
    return BASE_ADDRESS + cell.replace(/D:\\Projects\\DataFox\\data/g, "static");
  } else {
    return cell;
  }
}

export function baseColumnFilter(columns) {
  return columns.filter(name => (!name.includes('Unnamed') && name != "secUid" && name != "id"));
}

export function baseColumnFormatter(cell, row) {
  if (isImageCell(cell)) {
    return <span><img className="img-fluid" src={getImageCellValue(cell)} /></span>;
  } else if (isLinkCell(cell)) {
    return (
      <div>
        <a className="btn btn-outline-primary" style={{"marginLeft": "-7px"}} href={getLinkCellValue(cell)}>
         <small style={{"fontSize": "0.6rem"}}>Download</small>
        </a>
      </div>
    );
  } else if (!isNaN(cell)) {
    return <span>{cell.toLocaleString("en-US")}</span>;
  } else {
    try {
      return <span>{cell.substring(0, 50)}</span>;
    } catch (e) {
      return <span>{cell}</span>;
    }
  }
}
