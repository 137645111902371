import axios from "axios";
import Cookies from "js-cookie";
import { errorMiddleware } from "./middleware";
import { BACKEND_ADDRESS } from "./const";

const axiosInstance = axios.create({
  baseURL: BACKEND_ADDRESS,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  errorMiddleware
)

async function tokenConfig() {
  if (!Cookies.get("csrftoken")) {
    await fetchCSRF();
  } 
  return {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      'Accept': 'application/json; charset=utf-8',
      "X-CSRFToken": Cookies.get("csrftoken"),
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
}

async function fetchCSRF() {
  let resp = await axios.get(BACKEND_ADDRESS + "user/csrf_cookie", {
    withCredentials: true,
  });
  return;
}

export async function fetchReports(scraper) {
    let config = await tokenConfig();
    return axiosInstance.get("stats/reports/" + scraper, config);
  }

export async function addNewStatReport(scraper, scrapeResult) {
    let config = await tokenConfig();
    return axiosInstance.post("stats/report-add/" + scraper + "/" + scrapeResult, {}, config);
}

export async function deleteStatReport(reportId) {
    let config = await tokenConfig();
    return axiosInstance.post("stats/report-delete/" + reportId, config);
  }


export async function fetchReportDataByScrapeId(scrapeId) {
  return axiosInstance.get("stats/report-by-scrape-id/" + scrapeId);
}

export async function fetchReportDataByStatId(id) {
  return axiosInstance.get("stats/report-by-stat-id/" + id);
}

export async function fetchReportTabData(reportName, serviceResultId, tabName, currentResultIndex) {
  return axiosInstance.get("stats/report-tab-data/" + reportName + "/" + serviceResultId + "/" + tabName + "/" + currentResultIndex);
}