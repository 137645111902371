import { toast } from 'react-toastify';

export function errorMiddleware(err) {
  console.log(err);
  if(err && err.response){
    if(err.response.data && err.response.data.detail){
      toast.error("Error: " + err.response.data.detail);
    } else if(err.response.status == 403) {
      toast.error("No permission");
    } else if(err.response.status == 500) {
      toast.error("Unexpected server error, please try again later");
    } else {
      toast.error("Unexpected error, please try again later")
    }
  }
  throw err;
}