import React, { Component } from 'react'
import Loading from '../../common/Loading'
import { toast } from 'react-toastify';
import { activate } from '../../services/auth.service';

export default class Activate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            isLoading: true
        };
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        // Get the value of a specific query parameter
        const paramName = 'token';
        const paramValue = urlParams.get(paramName);

        this.setState({isLoading: true});
        activate(paramValue).then((res) => {
            if(res.data==='success'){
                toast.success("Activation successful, you can log in now");
                window.location.replace("/login");
            } else {
                this.setState({
                    errors: res.data,
                    isLoading: false
                });
            }
        }).catch((err) => {
            console.error("User activation failed", err);
            this.setState({
                errors: err,
                isLoading: false
            });
        })
    }

    render() {
        if (this.state.errors.length !== 0) {
            return (
                <div className="d-flex flex-column align-items-center justify-content-center my-5">
                    <div className="alert alert-danger">{this.state.errors}</div>
                </div>
            );
        } else {
            return (
                <div className="d-flex flex-column align-items-center justify-content-center my-5">
                    <Loading type="rings"></Loading>
                </div>
            );
        }

    }
}
