import axios from "axios";
import Cookies from "js-cookie";
import { errorMiddleware } from "./middleware";
import { BACKEND_ADDRESS } from "./const";

const axiosInstance = axios.create({
  baseURL: BACKEND_ADDRESS,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  errorMiddleware
)

async function tokenConfig() {
  if (!Cookies.get("csrftoken")) {
    await fetchCSRF();
  }
  return {
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
}

async function fetchCSRF() {
  let resp = await axios.get(BACKEND_ADDRESS + "user/csrf_cookie", {
    withCredentials: true,
  });
  return;
}

export async function fetchAlerts(scraper) {
  let config = await tokenConfig();
  return axiosInstance.get("alert/email-alerts/" + scraper, config);
}

export async function removeAlert(scraper, alertId) {
    let config = await tokenConfig();
    return axiosInstance.delete("alert/email-alerts/" + scraper + "/" + alertId, config);
  }

  export async function createAlert(scraper, params) {
    let config = await tokenConfig();
    return axiosInstance.post("alert/email-alerts/" + scraper, params, config);
  }