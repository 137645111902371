import React, { Component } from 'react';
import { toast } from "react-toastify";
import ServiceSelector from './services/ServiceSelector';
import ScrapeSelector from './services/ScrapeSelector';
import ServiceConfig from './services/ServiceConfig';
import ActiveService from './services/ActiveService';
import ServiceHistory from './services/ServiceHistory';
import ConfirmModal from './utils/ConfirmModal';
import GifContainer from './layouts/GifContainer';
import TutorialHelper from './utils/TutorialHelper';

import { WS_ADDRESS, SERVICE_WS_ADDRESS } from '../services/const';
import { timeout } from "../common/utils";
import Loading from '../common/Loading';

import { fetchServices, fetchServiceParams, startNewService, fetchActiveServices, fetchServiceResults, deleteService, deleteServiceResult } from '../services/services.service';
import { loadScraperHistory, fetchActive, stopNewScrape } from '../services/crawler.service';

class Extras extends Component {

    constructor() {
        super();
        this.state = {
            scraper: null,
            selectedService: null,
            selectedScrapes: [],
            servicesData: [],
            scrapesData: [],
            serviceParamsData: [],

            activeServices: [],
            activeServiceProgresses: {},
            activeScrapes: [],
            activeScrapesProcesses: {},

            serviceResults: [],

            servicesLoading: true,
            scrapesLoading: true,
            serviceParamsLoading: true,
            resultsLoading: true,
            runServiceLoading: false,
            activeLoading: true,

            showModalName: '',
            currentLogs: [],
        };

        this.handleServiceSelectCallback = this.handleServiceSelectCallback.bind(this);
        this.handleScrapesSelectCallback = this.handleScrapesSelectCallback.bind(this);
        this.handleServiceStart = this.handleServiceStart.bind(this);
        this.handleServiceStop = this.handleServiceStop.bind(this);
        this.handleStopScrape = this.handleStopScrape.bind(this);
        this.handleServiceDelete = this.handleServiceDelete.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        this.SCRAPE_WS_URL = WS_ADDRESS;
        this.SERICE_WS_URL = SERVICE_WS_ADDRESS;
        this.scrape_sockets = {};
        this.service_sockets = {};
    }

    componentDidMount() {
        this.setState({
            scraper: this.props.selectedScraper,
            currentLogs: [],
        });
        this.loadActiveServices(this.props.selectedScraper);
        this.loadActiveScrapes(this.props.selectedScraper);
        this.loadServices(this.props.selectedScraper);
        this.loadScrapes(this.props.selectedScraper);
        this.loadServiceResults(this.props.selectedScraper);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.selectedScraper !== this.props.selectedScraper &&
            this.props.selectedScraper !== this.state.scraper
        ) {
            this.setState({
                scraper: this.props.selectedScraper,
                currentLogs: [],
            });
            this.loadActiveServices(this.props.selectedScraper);
            this.loadActiveScrapes(this.props.selectedScraper);
            this.loadServices(this.props.selectedScraper);
            this.loadScrapes(this.props.selectedScraper);
            this.loadServiceResults(this.props.selectedScraper);
        }
    }

    componentWillUnmount() {
        for (const socket of Object.values(this.scrape_sockets)) {
            socket.close()
        }
        for (const socket of Object.values(this.service_sockets)) {
            socket.close()
        }
    }

    loadServices(scraper) {
        this.setState({ servicesLoading: true });
        fetchServices(scraper)
            .then((data) => {
                this.setState({ servicesData: data.data, servicesLoading: false });
            })
            .catch((error) => {
                toast.error('Error fetching service data:', error);
                console.error('Error fetching service data:', error);
                this.setState({ servicesLoading: false });
            });
    }

    loadScrapes(scraper) {
        this.setState({ scrapesLoading: true });
        loadScraperHistory(scraper)
            .then((res) => {
                this.setState({
                    scrapesData: res.data,
                    scrapesLoading: false
                });
            })
            .catch((err) => {
                console.error("Failed to load scrapes: ", err);
                this.setState({
                    scrapesData: [],
                    scrapesLoading: false
                });
            });
    }

    loadServiceParams(scraper) {
        this.setState({ serviceParamsLoading: true })
        fetchServiceParams(scraper)
            .then((data) => {
                this.setState({ serviceParamsData: data.data, isLoading: false, serviceParamsLoading: false });
            })
            .catch((error) => {
                toast.error('Error fetching service params data:', error);
                console.error('Error fetching service params data:', error);
                this.setState({ serviceParamsLoading: false });
            });
    }

    loadServiceResults(scraper) {
        this.setState({ resultsLoading: true });
        fetchServiceResults(scraper)
            .then((data) => {
                this.setState({ serviceResults: data.data, resultsLoading: false });
            })
            .catch((error) => {
                toast.error('Error fetching service results data:', error);
                console.error('Error fetching service results data:', error);
                this.setState({ resultsLoading: false });
            });
    }


    handleServiceSelectCallback = (selectedService) => {
        if (selectedService != null) {
            this.loadServiceParams(selectedService.id);
        }
        this.setState({ selectedService });
    }

    handleScrapesSelectCallback = (scrapeids) => {
        if (scrapeids != null && scrapeids.length == 0) {
            toast.error("Please select at least 1 scrape");
            return;
        }
        if (scrapeids == null) {
            scrapeids = [];
            if (this.state.selectedScrapes.length == 0) {
                this.setState({
                    selectedService: null
                })
            }
        }
        this.setState({
            selectedScrapes: scrapeids
        })
    }

    handleServiceDelete(serviceId) {
        this.setState({ resultsLoading: true });
        deleteServiceResult(serviceId)
            .then((res) => {
                if (res.data["response"] === "ok") {
                    toast.success(<GifContainer message="Service result successfuly deleted" gifType="delete"/>)
                    this.loadServiceResults(this.state.scraper);
                    this.handleCancel();
                } else {
                    toast.error(
                        "Failed to delete service result: " + res.data["response"]
                    );
                    console.error("Failed to delete service result: " + res.data["response"]);
                    this.setState({ resultsLoading: false });
                }
            })
            .catch((err) => {
                console.error("Failed to delete scrape result: " + err);
                this.setState({ resultsLoading: false });
            });
    }

    handleShowModal(modalName, extraKey = undefined, extraValue = undefined) {
        this.setState({ showModalName: modalName });
        if (extraKey) {
            this.setState({ [extraKey]: extraValue });
        }
    };

    handleCancel() {
        this.setState({ showModalName: '' });
    };


    handleServiceStart = (serviceParams) => {
        if (!this.state.selectedService) {
            toast.error("Selected service is missing");
            return;
        }
        if (!this.state.selectedScrapes) {
            toast.error("Selected scrapes are missing");
            return;
        }

        this.setState({ runServiceLoading: true, activeLoading: true, selectedService: null, selectedScrapes: [] });
        startNewService(this.state.selectedService.id, this.state.selectedScrapes, serviceParams).then((res) => {
            this.setState({
                activeServiceIds: res.data.service_result_ids,
                runServiceLoading: false
            });
            if (res.data.service_result_ids.length == 0) {
                this.loadServiceResults(this.state.scraper);
                toast.error("Service not started for ids: " + res.data.error_ids);
            }
            else if (res.data.error_ids.length > 0) {
                this.loadActiveServices(this.state.scraper);
                this.loadActiveScrapes(this.state.scraper);
                this.loadServiceResults(this.state.scraper);
                this.props.updateUserCallback();
                toast.warning("Service started for " + res.data.service_result_ids + ", but failed for " + res.data.error_ids);
            }
            else {
                this.loadActiveScrapes(this.state.scraper);
                this.loadActiveServices(this.state.scraper);
                this.loadServiceResults(this.state.scraper);
                this.props.updateUserCallback();
                toast.success("Service successfully started for " + res.data.service_result_ids);
            }

        })
            .catch((err) => {
                console.error("Failed to start service: ", err);
                this.setState({
                    activeServiceIds: [],
                    runServiceLoading: false
                });
            });
    }

    handleServiceStop = (serviceResultId) => {
        deleteService(serviceResultId).then((data) => {
            this.loadActiveServices(this.state.scraper);
            this.loadServiceResults(this.state.scraper);
        })
            .catch((error) => {
                toast.error('Error stoping service:', error);
                console.error('Error stoping service:', error);
            });
    }

    handleStopScrape = (scrapeResultId) => {
        toast.success("Stopping scrape, this may take up to 10 seconds");
        stopNewScrape(scrapeResultId)
            .then((res) => {
                this.setState({ activeLoading: true });
                timeout(10000).then((_) => {
                    if (scrapeResultId in this.scrape_sockets) {
                        toast.warning(
                            "Stopping scrape took longer than expected, forcing stop"
                        );
                        stopNewScrape(scrapeResultId, true)
                            .then((res2) => {
                                toast.success("Scrape successfuly force stopped");
                                this.loadActiveScrapes(this.state.scraper);
                                this.loadServiceResults(this.state.scraper);
                            })
                            .catch((err2) => {
                                console.error("Failed to force stop scrape: ", err2);
                            });
                    }
                });
            })
            .catch((err) => {
                console.error("Failed to stop scrape", err);
            });
    }

    loadActiveServices(scraperName) {
        this.setState({ activeLoading: true });
        fetchActiveServices(scraperName)
            .then((res) => {
                this.setState(prevState => ({
                    activeServices: res.data,
                    activeLoading: false,
                    currentLogs: {
                        ...prevState.currentLost,
                        ...res.data.reduce((acc, obj) => (acc[obj.id] = [], acc), {})
                    }
                }));
                res.data.forEach((service) => {
                    if (service.id in this.service_sockets) {
                        let tempSocket = this.service_sockets[service.id];
                        tempSocket.close()
                        delete this.service_sockets[service.id];
                    }

                    this.setState({ activeServiceProgresses: { ...this.state.activeServiceProgresses, [service.id]: 0 } });
                    this.setupServiceSocket(service.id);
                });
            })
            .catch((err) => {
                console.error("Failed to load active services", err);
                this.setState({
                    activeServices: [],
                    activeLoading: false
                });
            });
    }

    loadActiveScrapes(scraper) {
        this.setState({ activeLoading: true });
        fetchActive(scraper)
            .then((res) => {
                this.setState(prevState => ({
                    activeScrapes: res.data,
                    activeLoading: false,
                    currentLogs: {
                        ...prevState.currentLost,
                        ...res.data.reduce((acc, obj) => (acc[obj.id] = [], acc), {})
                    }
                }));

                res.data.forEach((scrape) => {
                    if (scrape.id in this.scrape_sockets) {
                        let tempSocket = this.scrape_sockets[scrape.id];
                        tempSocket.close()
                        delete this.scrape_sockets[scrape.id];
                        console.log("Connection closed");
                    }

                    this.setState({ activeScrapesProcesses: { ...this.state.activeScrapesProcesses, [scrape.id]: 0 } });
                    this.setupScrapeSocket(scrape.id);
                });

            })
            .catch((err) => {
                console.error("Failed to load active scraper", err);
                this.setState({
                    activeScraper: null,
                    activeProgress: 0,
                    activeScraperLoading: false,
                    activeLoading: false
                });
            });
    }


    setupServiceSocket(serviceResultId) {
        this.service_sockets[serviceResultId] = new WebSocket(this.SERICE_WS_URL + serviceResultId + "/");
        console.log("Socket initialized: " + this.SERICE_WS_URL + serviceResultId + "/");

        this.service_sockets[serviceResultId].onopen = () => {
            console.log("WebSocket connected");
        };

        // Listen for the 'message' event
        this.service_sockets[serviceResultId].onmessage = (event) => {
            console.log("Message received:", event.data);
            const eventData = JSON.parse(event.data);

            switch (eventData["type"]) {
                case "service_started":
                    toast.success("Service successfully started");
                    break;
                case "service_progress":
                    const currentValue = Math.floor(
                        ((eventData["args"]["success"] + eventData["args"]["error"]) /
                            eventData["args"]["total"]) *
                        100);
                    console.log("Service " + serviceResultId + " progress: " + currentValue);
                    this.setState({ activeServiceProgresses: { ...this.state.activeServiceProgresses, [serviceResultId]: currentValue } });
                    break;
                case "service_finished":
                    toast.success(<GifContainer message="Service successfuly finished" gifType="success"/>);
                    this.loadServiceResults(this.state.scraper);
                    this.loadActiveServices(this.state.scraper);
                    this.props.updateUserCallback();
                    break;
                case "log":
                    console.log(eventData["args"]);
                    this.setState(prevState => ({
                        currentLogs: {
                            ...prevState.currentLogs,
                            [serviceResultId]: [...(prevState.currentLogs[serviceResultId] || []), eventData["args"]]
                        }
                    }));
                    console.log(this.state.currentLogs)
                    break;
                default:
                    console.log("Action ignored for socket: " + event.data);
            }
        };

        // Listen for the 'close' event
        this.service_sockets[serviceResultId].onclose = () => {
            console.log("WebSocket disconnected");
        };
    }

    setupScrapeSocket(scrapeResultId) {
        this.scrape_sockets[scrapeResultId] = new WebSocket(this.SCRAPE_WS_URL + scrapeResultId + "/");
        console.log("Socket initialized: " + this.SCRAPE_WS_URL + scrapeResultId + "/");

        this.scrape_sockets[scrapeResultId].onopen = () => {
            console.log("WebSocket connected");
        };

        // Listen for the 'message' event
        this.scrape_sockets[scrapeResultId].onmessage = (event) => {
            console.log("Message received:", event.data);
            const eventData = JSON.parse(event.data);

            switch (eventData["type"]) {
                case "scrape_started":
                    toast.success("Scrape successfully started");
                    break;
                case "scrape_progress":
                    const currentValue = Math.floor(
                        ((eventData["args"]["success"] + eventData["args"]["error"]) /
                            eventData["args"]["total"]) *
                        100);
                    console.log("Scrape " + scrapeResultId + " progress: " + currentValue);
                    this.setState({ activeScrapesProcesses: { ...this.state.activeScrapesProcesses, [scrapeResultId]: currentValue } });
                    break;
                case "scrape_finished":
                    toast.success(<GifContainer message="Scrape successfuly finished" gifType="success"/>);
                    this.loadActiveScrapes(this.state.scraper);
                    this.loadServiceResults(this.state.scraper);
                    this.loadActiveServices(this.state.scraper);
                    break;
                case "log":
                    console.log(eventData["args"]);
                    this.setState(prevState => ({
                        currentLogs: {
                            ...prevState.currentLogs,
                            [scrapeResultId]: [...(prevState.currentLogs[scrapeResultId] || []), eventData["args"]]
                        }
                    }));
                    console.log(this.state.currentLogs)
                    break;
                default:
                    console.log("Action ignored for socket: " + event.data);
            }
        };

        // Listen for the 'close' event
        this.scrape_sockets[scrapeResultId].onclose = () => {
            console.log("WebSocket disconnected");
        };
    }



    getProgressView() {
        if (this.state.activeLoading) {
            return this.getLoadingView();
        }
        else if (this.state.activeServices.length === 0) {
            return (<div><div className='position-absolute top-0 end-0 tutorial-help-div'>
                <TutorialHelper tutorialId={"serviceProgressNotRunning"} user={this.props.user} />
            </div><p>No service is running</p></div>);
        }
        else {
            return (
                <div>
                    <div className='position-absolute top-0 end-0 tutorial-help-div'>
                        <TutorialHelper tutorialId={"serviceProgressRunning"} user={this.props.user} />
                    </div>
                    <ul className='mb-3'>
                        {this.state.activeScrapes.map((scrape) => <ActiveService user={this.props.user} key={"active-" + scrape.id} id={scrape.id} scrapeResult={scrape} progress={this.state.activeScrapesProcesses[scrape.id]} logs={this.state.currentLogs[scrape.id]} handleStop={this.handleStopScrape} />)}
                    </ul>
                    <ul>
                        {this.state.activeServices.map((service) => <ActiveService user={this.props.user} key={"active-" + service.id} id={service.id} serviceResult={service} progress={this.state.activeServiceProgresses[service.id]} logs={this.state.currentLogs[service.id]} handleStop={this.handleServiceStop} />)}
                    </ul>
                </div>

            );
        }
    }

    getLoadingView() {
        return (<div className='d-flex justify-content-center'>
            <Loading type="rings" />
        </div>)
    }

    getServiceView() {
        if (this.runServiceLoading) {
            return this.getLoadingView();
        }
        if (this.state.selectedService == null) {
            if (this.state.servicesLoading) {
                return this.getLoadingView();
            } else {
                return <ServiceSelector servicesData={this.state.servicesData} selectService={this.handleServiceSelectCallback} user={this.props.user} />
            }
        } else if (this.state.selectedScrapes.length == 0 && !this.state.selectedService.need_scrape) {
            if (this.state.scrapesLoading) {
                return this.getLoadingView();
            } else {
                return (
                    <div>
                        <ScrapeSelector selectedService={this.state.selectedService} scrapesData={this.state.scrapesData} handleBack={this.handleServiceSelectCallback} handleNext={this.handleScrapesSelectCallback} />
                    </div>
                )
            }
        } else {
            if (this.state.serviceParamsLoading) {
                return this.getLoadingView();
            } else {
                return (
                    <div>
                        <ServiceConfig handleBack={this.handleScrapesSelectCallback} selectedService={this.state.selectedService} scrapes={this.state.selectedScrapes} params={this.state.serviceParamsData} handleNext={this.handleServiceStart} user={this.props.user} />
                    </div>
                )
            }
        }
    }

    getResultsView() {
        if (this.state.resultsLoading) {
            return this.getLoadingView();
        } else {
            return (
            <div>
                <ServiceHistory serviceResults={this.state.serviceResults} handleServiceDelete={(serviceId) => this.handleShowModal('DeleteItem', 'selectForDelete', serviceId)} user={this.props.user} />
            </div>);
        }
    }



    render() {
        return (<div>
            <div className='card p-4 my-2'>
                {this.getServiceView()}
            </div>
            <div className='card p-4 my-2'>
                <div>
                    {this.getProgressView()}
                </div>
            </div>
            <div>
                {this.getResultsView()}
            </div>

            {this.state.showModalName === 'DeleteItem' && (
                <div
                    className="modal show"
                    id="DeleteItemModal"
                    role="dialog"
                    tabIndex="-1"
                    style={{ display: "block" }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <ConfirmModal
                                title="Are you sure to delete this row?"
                                description="1 service data will be deleted permanently with all the files"
                                okCallback={() => this.handleServiceDelete(this.state.selectForDelete)}
                                cancelCallback={this.handleCancel} /></div>
                    </div>
                </div>
            )}
        </div>
        );
    }
}

export default Extras;