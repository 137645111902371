import React, { Component } from "react";
import PasswordChange from "./user/PasswordChange";
import ProfileDelete from "./user/ProfileDelete";
import Payments from "./user/Payments";
import UserInfo from "./user/UserInfo";

export default class User extends Component {
  render() {
    return (
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="row my-1">
            <UserInfo
              user={this.props.user}
            />
          </div>
          <div className="row my-1">
            <Payments
              user={this.props.user}
              updateUserCallback={this.props.updateUserCallback}
            />
          </div>
          <div className="row my-1">
            <PasswordChange user={this.props.user} />
          </div>
          <div className="row my-1">
            <ProfileDelete user={this.props.user} />
          </div>
        </div>

        <div className="row d-flex justify-content-center"></div>
      </div>
    );
  }
}
